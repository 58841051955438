import React from "react";
import { Row, message } from "antd";
import FilterSelect from "../../components/FilterSelect";
import RangeDateFilter from "../../components/RangeDateFilter";
import Ddo from "./Ddo";
import moment from "moment";
import {
  REGIONS,
  GetInQueue,
  GetDirections,
  GetEnrolled,
  GetPlaceTotal,
  GetEgov,
  GetWebPortal,
} from "modules/Social/DataKinderGarden";
const timeFormat = "DD.MM.YYYY";

class KindergardenQueue extends React.Component {
  state = {
    startDate: moment()
      .subtract(1, "months")
      .startOf("month")
      .format(timeFormat),
    endDate: moment().startOf("month").format(timeFormat),
    loading: true,
    filter: {},
    selected: {},
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { startDate, endDate } = this.state;
    Promise.all([
      GetInQueue(startDate, endDate),
      GetDirections(startDate, endDate),
      GetEnrolled(startDate, endDate),
      GetPlaceTotal(startDate, endDate),
      GetEgov(startDate, endDate),
      GetWebPortal(startDate, endDate),
    ])
      .then((values) => {
        this.setState(
          {
            inQueue: values[0],
            directions: values[1],
            enrolled: values[2],
            placeTotal: values[3],
            egov: values[4],
            webPortal: values[5],
          },
          () => this.applyFilter()
        );
      })
      .catch((err) => {
        message.error("Произошла ошибка сети. Пожалуйста, попробуйте позже.");
      });
  };

  setDate = ({ dateType, value }) => {
    this.setState(
      {
        [dateType]: value,
      },
      () => this.getData()
    );
  };

  applyFilter = () => {
    const { filter } = this.state;
    let selected = {};
    if (filter.region) {
      selected.inQueue = this.state.inQueue.find(
        (el) => el.data.region === filter.region
      ).data.total;
      selected.direction = this.state.directions.find(
        (el) => el.data.region === filter.region
      ).data.total;
      selected.enrolled = this.state.enrolled.find(
        (el) => el.data.region === filter.region
      ).data.total;
      selected.placeTotal = this.state.placeTotal.find(
        (el) => el.data.region === filter.region
      ).data.total;
      selected.horizontalChart = [
        {
          category: REGIONS.find((el) => el.value === filter.region).label,
          value: selected.placeTotal,
        },
      ];
      selected.verticalChart = [
        {
          category: REGIONS.find((el) => el.value === filter.region).label,
          value: this.state.webPortal.find(
            (el) => el.data.region === filter.region
          ).data.total,
          value1: this.state.egov.find((el) => el.data.region === filter.region)
            .data.total,
        },
      ];
    } else {
      selected.inQueue = this.state.inQueue.reduce(
        (a, b) => a + b.data.total,
        0
      );
      selected.direction = this.state.directions.reduce(
        (a, b) => a + b.data.total,
        0
      );
      selected.enrolled = this.state.enrolled.reduce(
        (a, b) => a + b.data.total,
        0
      );
      selected.placeTotal = this.state.placeTotal.reduce(
        (a, b) => a + b.data.total,
        0
      );
      selected.horizontalChart = this.state.placeTotal.map((el) => ({
        category: REGIONS.find((region) => el.data.region === region.value)
          .label,
        value: el.data.total,
      }));
      selected.verticalChart = this.state.webPortal.map((el, index) => ({
        category: REGIONS.find((region) => el.data.region === region.value)
          .label,
        value: el.data.total,
        value1: this.state.egov[index].data.total,
      }));
      selected.horizontalChart = selected.horizontalChart.sort(
        (a, b) => b.value - a.value
      );
      selected.verticalChart = selected.verticalChart.sort(
        (a, b) => b.value - a.value
      );
    }
    selected.pieChart = [
      {
        category: "В очереди",
        value: selected.inQueue,
      },
      {
        category: "Выдано направлений",
        value: selected.direction,
      },
      {
        category: "Зачислено",
        value: selected.enrolled,
      },
    ];

    setTimeout(() => {
      this.setState({
        selected,
        filter,
        loading: false,
      });
    }, 500);
  };

  handleClick = (filter) => {
    this.setState({ filter, loading: true }, () => this.applyFilter());
  };

  handleClear = (type) => {
    const { filter } = this.state;
    let filterCopy = JSON.stringify(filter);
    filterCopy = JSON.parse(filterCopy);
    delete filterCopy[type];
    this.setState(
      {
        filter: filterCopy,
        loading: true,
      },
      () => this.applyFilter()
    );
  };
  showFilters = () => {
    const state = !this.state.filtersVisible;
    this.setState({
      filtersVisible: state,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Row
          style={{ display: this.state.filtersVisible ? "block" : "none" }}
          className="filter_3"
        >
          <FilterSelect
            options={REGIONS}
            filterField="region"
            handleChange={this.handleClick}
            handleClear={this.handleClear}
            placeholder="Район"
            allowClear
          />
          <RangeDateFilter
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            setDate={this.setDate}
            dateFormat={timeFormat}
          />
        </Row>
        <div className="p-10">
          <Ddo kinderProps={this} />
        </div>
      </React.Fragment>
    );
  }
}
export default KindergardenQueue;
