import React from "react";
import styled from "styled-components";
import { RowStatLabel, OrangeBox } from "components/common/Elements";

export default function ({ label = "Не определено", count = 0, ...props }) {
  return (
    <Container>
      <RowStatLabel>{label}:</RowStatLabel>
      <OrangeBox>{count}</OrangeBox>
    </Container>
  );
}

let Container = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 480px) {
    justify-content: space-between;
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
