import React from "react";
import LineGraph from "./LineGraph";

const Indicator = ({ indicator, id }) => {
  const uniqLabel = Array.from(
    new Set(
      [...indicator.fact.data, ...indicator.plan.data].map((e) => e.label)
    )
  );
  const data = uniqLabel.map((label) => {
    const plan = indicator.plan.data.find((e) => e.label === label);
    const fact = indicator.fact.data.find((e) => e.label === label);
    return {
      year: label + "",
      plan: plan ? plan.value : null,
      fact: fact ? fact.value : null,
    };
  });
  return (
    <div className="indicator_item">
      <h4 align="center">{indicator.title}</h4>
      <LineGraph data={data} id={id} unit={indicator.unitType} responsive />
    </div>
  );
};

export default Indicator;
