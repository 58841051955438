import { login, logout } from "../services/api";
import { resetAuthority, setAuthority } from "../utils/authority";
import { saveTargetPath } from "../utils/login";

export const LOGIN = "scvko/auth/LOGIN";

export const LOGOUT = "scvko/auth/LOGOUT";

export const loginUser = (values) => {
  return async (dispatch) => {
    const user = await login(values);
    setAuthority("AUTHED");
    localStorage.setItem("token", user.token);
    dispatch({ type: LOGIN, payload: user });
    return user;
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    saveTargetPath();
    await logout();
    setAuthority("GUEST");
    resetAuthority();
    dispatch({ type: LOGOUT });
  };
};
