const Ru = {
  translation: {
    component: {
      selectDate: "Выберите дату",
      export: "Экспорт",
    },
    month: {
      январь: "январь",
      февраль: "февраль",
      март: "март",
      апрель: "апрель",
      май: "май",
      июнь: "июнь",
      июль: "июль",
      август: "август",
      сентябрь: "сентябрь",
      октябрь: "октябрь",
      ноябрь: "ноябрь",
      декабрь: "декабрь",
    },
    caseNames: {
      school: "Школа",
      dtp: "ДТП",
      publicSafety: "Общественная безопасность",
      criminalSituation: "Криминогенная обстановка",
      economy: "Экономика",
      businessCard: "Карта бизнеса",
      roadTrafficAccidents: "Дорожно-транспортные происшествия",
      income: "Доходы",
      costs: "Расходы",
      healthCare: "Здравоохранение",
      morbidityData: "Данные заболеваемости (DamuMed)",
      infrastructure: "Инфраструктура",
      repairAndConstruction:
        "План ремонта и строительства инфраструктурных сетей",
      education: "Образование",
      distributionOfChildrenInKindergartens:
        "Распределение детей по детским садам",
      distributionOfPupilsBySchool: "Распределение учеников по школам",
      homePage: "Главная страница",
      error: "Ошибка доступа",
      infobus: "Общественный транспорт",
      monitoringSmi: "Мониторинг СМИ",
      transport: "Транспорт",
      routeSchedule: "Расписание маршрутов",
    },
    error: {
      doNotHaveAccessToViewThisModule:
        "У вас нет доступа к просмотру данного модуля",
    },
    mainPage: {
      homePage: "Главная страница",
      information: "Информационно-аналитическая система Акимата Акмолинской",
      profile: "Ваш профиль",
      exit: "Выход",
      main: "Главная",
      statisticalPanel: "Статистическая панель",
      trafficAccidentsByDistrict: "ДТП по районам",
      crimeCategory: "Категория преступления",
      schoolPopulationStructure: "Структура наполненности школ",
      numberOfVisitsToTheEmergencyRoom: "Количество обращений в приемный покой",
      fullnessStructure: "Структура наполненности",
      numberOfSchools: "Количество школ",
      total: "Общее количество",
      projectCapacity: "Проектная мощность",
      surplus: "Профицит",
      norm: "Норма",
      deficit: "Дефицит",
      export: "Экспорт",
    },
    sideMenu: {
      menu: "Меню",
      publicSafety: "Общественная безопасность",
      dtp: "ДТП (Дорожно-транспортные происшествия)",
      criminalSituatuin: "Криминогенная обстановка",
      economy: "Экономика",
      businessCard: "Карта бизнеса",
      income: "Доходы",
      costs: "Расходы",
      healthCare: "Здравоохранение",
      damumed: "Данные заболеваемости (DamuMed)",
      infastructure: "Инфраструктура",
      repairAndConstruction:
        "План ремонта и строительства инфраструктурных сетей",
      education: "Образование",
      distributionOfChildrenInKindergartens:
        "Распределение детей по детским садам",
      distributionOfPupilsBySchool: "Распределение учеников по школам",
    },
    publicSafety: {
      dtp: {
        homePage: "Главная страница",
        involvingPublicTransport: "С участием общественного транспорта",
        withTheParticipationOfPedestrian: "С участием пешехода",
        intoxicated: "С алкогольным опьянением",
        accidentCard: "Карточка ДТП",
        violation: "Нарушение",
        town: "Город",
        date: "Дата",
        lighting: "Освещение",
        driverCondition: "Состояние водителя",
        culprit: "Виновник",
        timesOfDay: "Время суток",
        violationCategory: "Категория нарушения",
        areas: "Районы",
        comparitiveAnalysisOfRoadAccidentsByMonth:
          "Сравнительный анализ ДТП по месяцам",
        numberOfAccidents: "Количество ДТП за ",
        weather: "Погодные условия",
        dusk: "Сумерки",
        day: "День",
        night: "Ночь",
        total: "Общее количество",
      },
      crime: {
        area: "Район",
        category: "Категория",
        numberOfCrimes: "Количество преступлений",
        crimeCard: "Карточка преступления",
        severity: "Тяжесть",
        article: "Статья",
        dateOfUD: "Дата совершения УД (уголовное дело)",
        dateOfInitiationOfUD: "Дата возбуждения УД (уголовное дело)",
        KUI: "№ КУИ (книга учета информации)",
        authority: "Орган (Отдел полиции, Управление полиции)",
        street: "Улица",
        house: "Дом",
        regions: "Регионы",
        registrationAuthority: "Орган регистрации",
        crimeCategory: "Категория преступления",
      },
    },
    economy: {
      businessCard: {
        status: "Статус",
        dateOfUpdate: "Дата актуализации",
        startDate: "Начальная дата",
        finalDate: "Конечная дата",
        registrationDate: "Дата регистрации",
        BIN: "БИН (бизнес-идентификационный номер)",
        activity: "Активность",
        typeOfLegalUnit: "Тип правовой единицы",
        area: "Район",
        OKED: "ОКЭД (общий классификатор экономической деятельности)",
        taxDebtors: "Налоговые должники",
        taxCodeViolators: "Нарушители налогового кодекса",
        inactiveEnterprises: "Неактивные предприятия",
        incorrectlyRegistered: "Неверно зарегистрированные",
        wrongAddress: "Неверный адрес",
        bankrupts: "Банкроты",
        pseudoEnterprise: "Псевдо предприятия",
        totalEnterprises: "Всего предприятий",
        typeOfLegalUnits: "Тип правовых единиц",
        totalDistricts: "Всего районов",
        totalOKED: "Всего ОКЭД",
        totalRegisteredOrganizations: "Всего зарегистрированных организаций",
        totalOperatingOrganizations: "Всего действующих организации",
        numberOfOrganizationsByType: "Количество организации по типу",
        numberOfOKEDByTypeOfOrganization: "Количество ОКЭД по типу организации",
        numberOfOrganizationsByDistrict: "Количество организации по районам",
        numberOfOrganizationsByOKED: "Количество организации по ОКЭД",
        numberOfOrganizationsByDimension:
          "Количество организации по размерности",
      },

      income: {
        period: "Период",
        paymentPlanForTheYear: "План по платежам на год",
        yearlyPlanExecution: "Исполнение плана на год",
        annualIncomePlan: "План поступлений на год",
        executionOfThePlanForThePeriod: "Исполнение плана на период",
        budgetExecutionSchedule: "График исполнения бюджета",
        executionPlan: "План исполнений",
        execution: "Исполнение",
        categoryName: "Наименование категории",
        nonPerformance: "Неисполнение",
      },
      costs: {
        paymentPlanForTheYear: "План по платежам на период",
        ABP: "АБП (Администратор бюджетных программ)",
      },
    },
    healthCare: {
      damuMed: {
        numberOfPeopleWithARVI: "Количество обратившихся с ОРВИ",
        numberOfTraumaVisits: "Количество обращений с травмами",
        numberOfVisitsToTheEmergencyRoom:
          "Количество обращений в приемный покой",
        total: "Общее количество",
        numberOfCasesWithARVI: "Количество обращений с ОРВИ",
        numberOfQueuesInClinics: "Количество очередей в поликлиниках",
        numberOfFatalitiesFromInjuries: "Количество летальности от травматизма",
      },
    },
    infrastructure: {
      repairAndConstruction: {
        responsible: "Ответственный",
        category: "Категория",
        contractor: "Подрядчик",
        selectDate: "Выберите дату",
        showIntersections: "Показать пересечения",
        redPlannedRenovation: "Красным - запланированный ремонт",
        yellowInProgress: "Желтым - в работе",
        greenDone: "Зеленым - выполнено",
        categoryOfWork: "Категория работ",
        street: "Улица",
        plot: "Участок",
        contractorContacts: "Контакты подрядчика",
        warrantyPeriod: "Гарантийный период",
        start: "Начало",
        ending: "Окончание",
        openingOfTheRoadway: "Вскрытие дорожного полотна",
        overlappingTheStreet: "Перекрытие улицы",
        comments: "Комментарии",
        cost: "Стоимость ",
        amountFor2021: "Сумма на 2021",
        length: "Протяженность",
        whoAcceptedTheWork: "Кто принял работы",
      },
    },
    education: {
      childrenKindergartens: {
        openFilter: "Открыть фильтр",
        numberOfPreschoolOrganizations: "Количество дошкольных организаций",
        actualFullness: "Фактическая наполненность",
        deviation: "Отклонение",
        queue: "В очереди",
        issuedDirections: "Выдано направлений",
        enrolled: "Зачислено",
        fullnessOfDDO: "Наполненность ДДО (детских дошкольных организации)",
        DDOType: "Вид ДДО",
        preschoolMiniCenter: "Дошкольный мини-центр",
        kindergarten: "Детский сад",
        nurseryGarden: "Ясли-сад",
        sanatoriumNurseryGarden: "Санаторный ясли-сад",
        languageOfInstruction: "Язык обучения",
        mixed: "Смешанный",
        kazakh: "Казахский",
        russian: "Русский",
        durationOfStay: "Продолжительность нахождения",
        fullDayOfStay: "С полным днем пребывания",
        partTime: "С неполным днем пребывания",
        withTwentyFourStay: "С круглосуточным пребыванием",
        queueStatus: "Статус очереди",
        fullnessStructure: "Структура наполненности",
        surplus: "Профицит",
        norm: "Норма",
        deficit: "Дефицит",
        freePlaces: "Свободные места",
        pivotTable: "Сводная таблица",
        name: "Название",
        address: "Адрес",
        formOfInstitutions: "Форма учреждений ",
        fullness: "Заполненность",
        area: "Район",
        projectCapacity: "Проектная мощность",

        district: "Район",
        formOfInstitution: "Форма учреждения",
        designCapacity: "Проектная мощность",
        DDOtype: "Вид ДДО",
      },
      pupilsBySchool: {
        generalShortageOfPlaces: "Общий дефицит мест",
        totalArea: "Общая площадь",
        fullness: "Наполненность",
        schoolType: "Тип школ",
        secondarySchools: "Общеобразовательные школы",
        specializedSchoolsForGiftedChildren:
          "Специализированные школы для одаренных детей",
        lyceum: "Лицей",
        gymnasiums: "Гимназии",
        structureOfSchoolsByShifts: "Структура школ по сменам",
        firstShift: "Первая смена",
        secondShift: "Вторая смена",
        studentsByShifts: "Учащиеся по сменам",
        schoolsWithOneShift: "Школы с одной сменой",
        schoolsWithTwoShifts: "Школы с двумя сменами",
        terrain: "Местность",
        locality: "Населенный пункт",
        view: "Вид",
        typeOfOwnership: "Форма собственности ",
        yearBuilt: "Год постройки",
        magnitudeOfTheDeficit: "Величина дефицита",
        numberOfSchools: "Количество школ",
        projectCapacity: "Проектная мощность",
        schoolPopulationStructure: "Структура наполненности школ",
        surplus: "Профицит",
        norm: "Норма",
        deficit: "Дефицит",
        openFilter: "Открыть фильтр",
        pivotTable: "Сводная таблица",
        name: "Название",
        area: "Район",
        address: "Адрес",
        languageOfInstruction: "Язык обучения",
        projectCapacityAll: "Проектная мощность*",
        actualFullness: "Фактическая наполненность",
      },
      infobus: {},
      monitoringSmi: {},
    },
  },
};

export default Ru;
