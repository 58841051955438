import React, { Fragment } from "react";
import { Feature, Map, View } from "ol";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import {
  Heatmap as HeatmapLayer,
  Tile as TileLayer,
  Vector as VectorLayer,
} from "ol/layer";
import { Cluster, OSM, Vector as VectorSource } from "ol/source";
import { fromLonLat } from "ol/proj";
import Overlay from "ol/Overlay";
import { LineString, Point, Polygon } from "ol/geom";
import "ol/ol.css";
import * as PropTypes from "prop-types";
import { MapCenter } from "../../data/polygons_vko";
import { BaseAddress } from "../../config";
import { propChanged } from "../../utils/helpers";

const Projection = "EPSG:3857";

function mapPointObjects(objects) {
  return objects
    .map((object) => ({
      ...object,
      lat: object.x || object.latitude || object.lat,
      lon: object.y || object.longitude || object.lon || object.lng,
    }))
    .filter(({ lat, lon }) => lat || lon)
    .map((object) => {
      return new Feature({
        object,
        geometry: new Point([object.lon, object.lat]).transform(
          object.projection || "EPSG:4326",
          Projection
        ),
      });
    });
}

export default class MapWrap extends React.Component {
  static propTypes = {
    center: PropTypes.arrayOf(PropTypes.number.isRequired),
    zoom: PropTypes.number,
    size: PropTypes.number,
    heatBlur: PropTypes.number,
    heatRadius: PropTypes.number,
    distance: PropTypes.number,
    objects: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        size: PropTypes.number,
        projection: PropTypes.string,
        color: PropTypes.string,
        key: PropTypes.string,
      }).isRequired
    ),
    polygons: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        coords: PropTypes.arrayOf(PropTypes.array).isRequired,
        projection: PropTypes.string,
        strokeColor: PropTypes.string,
        fillColor: PropTypes.string,
      }).isRequired
    ),
    lineStrings: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        coords: PropTypes.arrayOf(PropTypes.array).isRequired,
        projection: PropTypes.string,
        strokeColor: PropTypes.string,
      }).isRequired
    ),
    heatObjects: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        size: PropTypes.number,
        projection: PropTypes.string,
        color: PropTypes.string,
      }).isRequired
    ),
    height: PropTypes.string,
    disableClustering: PropTypes.bool,
    handleSelect: PropTypes.func,
    handleMultipleSelect: PropTypes.func,
    handleMove: PropTypes.func,
  };

  mapRef = React.createRef();
  popupRef = React.createRef();
  popupCloserRef = React.createRef();
  popupContentRef = React.createRef();

  /** @type ol.PluggableMap */
  map = null;

  sources = {
    markers: null,
    polygons: null,
    lineStrings: null,
    heatObjects: null,
  };

  get mapCenter() {
    return this.props.center || MapCenter.Kokshe;
  }

  componentDidMount() {
    this.map = new Map({
      view: new View({
        center: fromLonLat(this.mapCenter),
        // center: [53.283, 69.4],
        zoom: this.props.zoom || 6,
        Projection,
      }),
      layers: [
        new TileLayer({
          source: new OSM({
            //url: BaseAddress.Osm + "/{z}/{x}/{y}.png",
            //crossOrigin: null,
          }),
        }),
      ],
      target: this.mapRef.current,
      //target:'map',
    });
    this.updateMap({
      updateMarkers: !!this.props.objects,
      updatePolygons: !!this.props.polygons,
      updateLineStrings: !!this.props.lineStrings,
      updateHeatObjects: !!this.props.heatObjects,
      updateCenter: !!this.props.updateCenter,
    });
    this.bindEventHandlers();
    this.onMapMoved();
    this.initPopup();
    this.updateSizeInterval = setInterval(() => {
      if (this.map && this.updateSizeInterval) {
        this.map.updateSize();
      }
    }, 1000);
  }

  initPopup = () => {
    this.overlay = new Overlay({
      element: this.popupRef.current,
      stopEvent: true,
    });
    this.map.addOverlay(this.overlay);
    let closer = this.popupCloserRef.current;
    closer.onclick = () => {
      this.overlay.setPosition(undefined);
      closer.blur();
      return false;
    };
  };

  componentWillUnmount() {
    clearInterval(this.updateSizeInterval);
    this.updateSizeInterval = null;
    this.map = null;
    this.sources.markers = null;
    this.sources.polygons = null;
    this.sources.lineStrings = null;
    this.sources.heatObjects = null;
  }

  getMarkerText = (objects) => {
    if (!objects || !objects.length) {
      return "";
    }
    const object = objects[0].get("object");
    return object.label || "";
  };

  initLayerMarkers = () => {
    const layerOptions = { name: "scvko:markers" };
    const source = new VectorSource();

    if (this.props.disableClustering) {
      layerOptions.source = source;
      layerOptions.style = (feature) =>
        new Style({
          image: new CircleStyle({
            radius: feature.size || this.props.size || 12,
            stroke: new Stroke({ color: "#222" }),
            fill: new Fill({ color: feature.get("object").color || "#3399CC" }),
          }),
        });
    } else {
      layerOptions.source = new Cluster({
        source,
        distance: this.props.distance || 48,
      });
      layerOptions.style = (feature) => {
        const objects = feature.get("features");
        return new Style({
          image: new CircleStyle({
            radius: feature.size || this.props.size || 14,
            stroke: new Stroke({ color: "#222" }),
            fill: new Fill({
              color: this.returnColor(objects),
            }),
          }),
          text: new Text({
            text:
              feature.get("features").length > 1
                ? feature.get("features").length + ""
                : this.getMarkerText(objects),
            fill: new Fill({ color: "#fff" }),
          }),
        });
      };
    }
    this.map.addLayer(new VectorLayer(layerOptions));
    return source;
  };

  initLayerHeatMap = () => {
    const source = new VectorSource();
    const vector = new HeatmapLayer({
      name: "scvko:heatObjects",
      source,
      blur: this.props.heatBlur || 15,
      radius: this.props.heatRadius || 5,
      weight: (feature) => feature.weight || 1,
    });
    this.map.addLayer(vector);
    return source;
  };

  returnColor = (objects) => {
    const object = objects[0].get("object");
    return object.color || "#3399CC";
  };

  initLayerPolygons = () => {
    const source = new VectorSource();
    this.map.addLayer(
      new VectorLayer({
        name: "scvko:polygons",
        source,
        style: (feature) => {
          const object = feature.get("object");
          return new Style({
            stroke: new Stroke({
              color: object.strokeColor || "red",
              width: 2,
            }),
            text: object.text ? new Text(object.text) : undefined,
            fill: new Fill({
              color: object.fillColor || "rgba(255, 0, 0, 0.2)",
            }),
          });
        },
      })
    );
    return source;
  };

  bindEventHandlers = () => {
    const {
      map,
      props: { handleSelect, handleMultipleSelect, handleMove },
    } = this;

    if (handleSelect || handleMultipleSelect) {
      map.on("click", (evt) => this.onMapClicked(evt));
    }
    if (handleMove) {
      map.on("moveend", () => this.onMapMoved());
    }
  };

  onMapClicked = (evt) => {
    const {
      map,
      props: { handleSelect, handleMultipleSelect },
    } = this;

    const feature = map.forEachFeatureAtPixel(evt.pixel, (feature) => feature);
    if (!feature) {
      return;
    }

    const features = feature.get("features");

    let objects;
    if (features) {
      objects = features.map((ft) => ft.get("object"));
    } else {
      objects = [feature.values_.object];
    }

    if (objects.length > 1) {
      if (handleMultipleSelect) {
        handleMultipleSelect(objects);
      } else {
        for (const object of objects) {
          handleSelect(object);
        }
      }
    } else if (feature && handleSelect) {
      if (objects[0].balloon) {
        this.popupContentRef.current.innerHTML = objects[0].balloon;
        this.overlay.setPosition(evt.coordinate);
      } else {
        handleSelect(objects[0]);
      }
    }
  };

  onMapMoved = () => {
    const {
      map,
      props: { handleMove },
    } = this;
    if (handleMove) {
      const [xMin, yMin, xMax, yMax] = map
        .getView()
        .calculateExtent(map.getSize());
      handleMove({
        xMin,
        yMin,
        xMax,
        yMax,
        zoom: map.getView().getZoom(),
      });
    }
  };

  initLayerLineStrings = () => {
    const source = new VectorSource();
    this.map.addLayer(
      new VectorLayer({
        name: "scvko:lineStrings",
        source,
        style: (feature) => {
          const object = feature.get("object");
          return new Style({
            stroke: new Stroke({
              color: object.strokeColor || "#000",
              width: 2,
            }),
          });
        },
      })
    );
    return source;
  };

  updateMap = ({
    updateMarkers = true,
    updatePolygons = true,
    updateLineStrings = true,
    updateHeatObjects = true,
    updateCenter = true,
  }) => {
    const {
      sources,
      props: { polygons, objects, lineStrings, heatObjects },
    } = this;

    if (updatePolygons) {
      if (sources.polygons) {
        sources.polygons.clear();
      } else {
        sources.polygons = this.initLayerPolygons();
      }
      if (polygons && polygons.length) {
        this.createPolygons(polygons);
      }
    }
    if (updateMarkers) {
      if (sources.markers) {
        sources.markers.clear();
      } else {
        sources.markers = this.initLayerMarkers();
      }
      if (objects && objects.length) {
        this.createMarkers(objects);
      }
    }
    if (updateLineStrings) {
      if (sources.lineStrings) {
        sources.lineStrings.clear();
      } else {
        sources.lineStrings = this.initLayerLineStrings();
      }
      if (lineStrings && lineStrings.length) {
        this.createLineStrings(lineStrings);
      }
    }
    if (updateHeatObjects) {
      if (sources.heatObjects) {
        sources.heatObjects.clear();
      } else {
        sources.heatObjects = this.initLayerHeatMap();
      }
      if (heatObjects && heatObjects.length) {
        this.createHeatObjects(heatObjects);
      }
    }
    if (updateCenter) {
      this.map.getView().setCenter(this.mapCenter);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateMap({
      updateMarkers: propChanged(prevProps, this.props, "objects"),
      updatePolygons: propChanged(prevProps, this.props, "polygons"),
      updateLineStrings: propChanged(prevProps, this.props, "lineStrings"),
      updateHeatObjects: propChanged(prevProps, this.props, "heatObjects"),
      updateCenter: propChanged(prevProps, this.props, "center"),
    });
  }

  createPolygons = (polygons) => {
    const features = polygons.map(
      (po) =>
        new Feature({
          object: po,
          geometry: new Polygon(po.coords).transform(
            po.projection || "EPSG:4326",
            Projection
          ),
        })
    );
    this.sources.polygons.addFeatures(features);
  };

  createLineStrings = (lineStrings) => {
    const features = lineStrings.map(
      (po) =>
        new Feature({
          object: po,
          geometry: new LineString(po.coords).transform(
            po.projection || "EPSG:4326",
            Projection
          ),
        })
    );
    this.sources.lineStrings.addFeatures(features);
  };

  createMarkers = (objects) => {
    this.sources.markers.addFeatures(mapPointObjects(objects));
  };

  createHeatObjects = (objects) => {
    this.sources.heatObjects.addFeatures(mapPointObjects(objects));
  };

  render() {
    return (
      <Fragment>
        <div
          ref={this.mapRef}
          style={{ height: this.props.height || "50vh", width: "100%" }}
        />
        <div ref={this.popupRef} className="ol-popup">
          <a href="#" ref={this.popupCloserRef} className="ol-popup-closer" />
          <div ref={this.popupContentRef} className="popup-content" />
        </div>
      </Fragment>
    );
  }
}
