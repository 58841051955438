import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ checkRole, component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page

    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          checkRole ? (
            <Component {...props} />
          ) : (
            <Redirect to="/error" />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
