import React from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { CHART_LOCALE } from "../common/Constants";
import * as PropTypes from "prop-types";
import CustomPropTypes from "../../utils/propTypes";

export default class HorizontalBarChart extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    valueLabelFormat: PropTypes.string,
    name: PropTypes.string,
    name1: PropTypes.string,
    colorsArr: PropTypes.arrayOf(PropTypes.string),
    sort: PropTypes.bool,
    sortReverse: PropTypes.bool,
    multiSort: PropTypes.bool,
    panel: PropTypes.bool,
    fontSize: PropTypes.number,
    scrollMinItems: PropTypes.number,
    noCellSize: PropTypes.bool,
    twoSeries: PropTypes.bool,
    handleSecondBarClick: PropTypes.func,
    color: PropTypes.string,
    handleClick: PropTypes.func,
    showLegend: PropTypes.bool,
    legendPosition: PropTypes.oneOf(["top", "bottom", "left", "right"]),
    legendOptions: PropTypes.string,
    hideValueLabels: PropTypes.bool,
    advancedScroll: PropTypes.bool,
    responsive: PropTypes.bool,

    advancedScrollItems: PropTypes.number,
    advancedScrollStart: PropTypes.number,
    advancedScrollEnd: PropTypes.number,
    totals: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.number,
        color: PropTypes.string,
      })
    ),
    height: CustomPropTypes.Height,
    label: PropTypes.oneOf(["truncate", "wrap"]),
  };

  shouldComponentUpdate(nextProps) {
    if (JSON.stringify(this.props) === JSON.stringify(nextProps)) {
      return false;
    } else {
      return true;
    }
  }

  componentDidMount() {
    let {
      data,
      colorsArr,
      //  cellSize = 100,
      handleClick,
    } = this.props;
    if (this.props.sort && data !== undefined) {
      data.sort((x, y) => Number(x.value) - Number(y.value));
    }
    if (this.props.sortReverse) {
      data.sort((x, y) => Number(y.value) - Number(x.value));
    }
    function orderByProperty(prop) {
      var args = Array.prototype.slice.call(arguments, 1);
      return function (a, b) {
        var equality = a[prop] - b[prop];
        if (equality === 0 && arguments.length > 1) {
          return orderByProperty.apply(null, args)(a, b);
        }
        return equality;
      };
    }
    if (this.props.multiSort) {
      data = data.sort(orderByProperty("value", "value1"));
    }

    const W_WIDTH = window.innerWidth;
    if (data) {
      data.forEach((i) => {
        if (i.category == null) i.category = "Не определён";
      });
    }
    let chart = am4core.create(this.props.id, am4charts.XYChart);
    chart.language.locale = CHART_LOCALE;
    chart.data = data;
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 1;
    categoryAxis.renderer.minGridDistance = 10;
    let label = categoryAxis.renderer.labels.template;
    label.tooltipText = "{category}";
    if (handleClick) {
      label.events.on("down", (ev) => {
        const category = ev.target.dataItem.dataContext.category;
        const object = data.find((e) => e.category === category);
        handleClick(category, object);
      });
    }
    switch (this.props.label) {
      case "truncate":
        label.truncate = true;
        break;

      case "wrap":
        label.wrap = true;
        break;

      default:
        label.wrap = true;
        label.truncate = true;
    }
    label.maxWidth = 300;
    if (W_WIDTH > 1900) {
      label.fontSize = 13;
      // cellSize = cellSize;
    } else {
      label.fontSize = 10;
      label.maxWidth = 250;
    }
    if (this.props.panel) label.fontSize = 12;
    if (this.props.fontSize) {
      label.fontSize = this.props.fontSize;
    }
    label.align = "left";
    const { noCellSize } = this.props;

    chart.paddingRight = 40;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    if (
      data &&
      this.props.scrollMinItems &&
      data.length > this.props.scrollMinItems
    ) {
      chart.scrollbarY = new am4core.Scrollbar();
    }
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = "value";
    series.dataFields.categoryY = "category";
    let columnTemplate = series.columns.template;
    columnTemplate.tooltipText = `{categoryY}: [bold]{valueX}[/]`;
    columnTemplate.strokeOpacity = 0;
    series.tooltip.label.maxWidth = 200;
    series.tooltip.label.wrap = true;
    series.simplifiedProcessing = true;
    if (this.props.twoSeries) {
      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.simplifiedProcessing = true;
      series1.dataFields.valueX = "value1";
      series1.dataFields.categoryY = "category";
      let columnTemplate1 = series1.columns.template;
      columnTemplate1.tooltipText = `{categoryY}: [bold]{valueX}[/]`;
      columnTemplate1.strokeOpacity = 0;
      series1.tooltip.label.maxWidth = 200;
      series1.tooltip.label.wrap = true;

      let labelBullet = series1.bullets.push(new am4charts.LabelBullet());
      let labelBullet2 = series.bullets.push(new am4charts.LabelBullet());

      labelBullet2.label.text = this.props.valueLabelFormat || "{valueX}";
      // labelBullet2.label.text = "Test";
      labelBullet.label.text = this.props.valueLabelFormat || "{valueX}";

      labelBullet.label.fill = am4core.color("#fff");
      labelBullet.fontSize = this.props.fontSize ? this.props.fontSize : 12;
      labelBullet.label.truncate = false;
      labelBullet.label.hideOversized = false;
      if (colorsArr && colorsArr[0]) {
        series.fill = am4core.color(colorsArr[0]);
        series.stroke = am4core.color(colorsArr[0]);
        series1.fill = am4core.color(colorsArr[1]);
        series1.stroke = am4core.color(colorsArr[1]);
      }
      series.name = this.props.name;
      series1.name = this.props.name1;

      if (this.props.handleSecondBarClick) {
        series1.columns.template.events.on("down", (ev) => {
          const category = ev.target.dataItem.dataContext.category;
          this.props.handleSecondBarClick(category);
        });
      }
    } else {
      if (this.props.color) {
        series.fill = am4core.color(this.props.color);
        series.stroke = am4core.color(this.props.color);
      } else {
        const colorSet = new am4core.ColorSet();
        const { colorsArr = ["#2776BD", "#00A1D0"] } = this.props;
        colorSet.list = colorsArr.map((c) => am4core.color(c));
        colorSet.reuse = true;
        series.columns.template.events.once("inited", function (event) {
          event.target.fill = colorSet.next();
        });
      }
    }

    if (this.props.handleClick) {
      series.columns.template.events.on("down", (ev) => {
        const category = ev.target.dataItem.dataContext.category;
        const object = data.find((e) => e.category === category);
        this.props.handleClick(category, object);
      });
    }

    if (this.props.showLegend) {
      chart.legend = new am4charts.Legend();
      chart.legend.position = this.props.legendPosition || "top";
    }
    if (this.props.legendOptions) {
      eval(this.props.legendOptions);
    }

    if (this.props.responsive) {
      chart.responsive.enabled = true;
    }
    if (!this.props.hideValueLabels && !this.props.twoSeries) {
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = this.props.valueLabelFormat || "{valueX}";
      // labelBullet.label.text = "Kek";
      labelBullet.dx = 20;
      labelBullet.label.fill = am4core.color("#fff");
      labelBullet.fontSize = this.props.fontSize ? this.props.fontSize : 12;
      labelBullet.label.truncate = false;
      labelBullet.label.hideOversized = false;
    } else {
      chart.numberFormatter.numberFormat = "#.#a";
      chart.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: " тыс" },
        { number: 1e6, suffix: " млн" },
        { number: 1e9, suffix: " млрд" },
        { number: 1e12, suffix: " трлн" },
      ];
    }
    let props = this.props.advancedScroll;
    let advancedScrollStart = this.props.advancedScrollStart;
    let advancedScrollEnd = this.props.advancedScrollEnd;
    if (this.props.advancedScrollItems) {
      advancedScrollEnd =
        1 - this.props.advancedScrollItems / (this.props.data.length || 1);
    }
    chart.events.on("ready", function (ev) {
      if (props) {
        chart.scrollbarY = new am4core.Scrollbar();
        chart.scrollbarY.start = advancedScrollStart ? advancedScrollStart : 1;
        chart.scrollbarY.end = advancedScrollEnd ? advancedScrollEnd : 0.7;
        chart.scrollbarY.parent = chart.rightAxesContainer;
      }
      valueAxis.extraMax = 0.05;
    });

    chart.data = data;
    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps !== this.props) {
      let data = this.props.data;
      let chart = this.chart;
      if (this.props.advancedScroll) {
        chart.scrollbarY = new am4core.Scrollbar();
        chart.scrollbarY.start = this.props.advancedScrollStart
          ? this.props.advancedScrollStart
          : 1;
        chart.scrollbarY.end = this.props.advancedScrollEnd
          ? this.props.advancedScrollEnd
          : 0.7;
        chart.scrollbarY.parent = chart.rightAxesContainer;
      }
      if (this.props.totals) {
        this.props.totals.forEach(({ title, value, color }, index) => {
          const key = "total-" + index;
          for (const point of data) {
            point[key] = value;
          }
          const line = new am4charts.StepLineSeries();
          line.name = title;
          line.dataFields.valueX = key;
          line.dataFields.categoryY = "category";
          if (color) {
            line.stroke = am4core.color(color);
            line.strokeWidth = 2;
          }
          line.tooltipText = "{name}:[bold]{valueX}";
          chart.series.push(line);
        });
      }
      if (this.props.sort) {
        data.sort((x, y) => Number(x.value) - Number(y.value));
      }
      if (this.props.sortReverse) {
        data.sort((x, y) => Number(y.value) - Number(x.value));
      }
      this.chart.data = this.props.data;
    }
  }

  render() {
    const { height = "50vh" } = this.props;
    return (
      <div className="horizontal_chart_wrap">
        <div id={this.props.id} style={{ height: height }} />
      </div>
    );
  }
}
