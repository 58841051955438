export const menuList = [
  {
    name: "caseNames.publicSafety", // "Общественная безопасность",
    img: "safety.svg",
    children: [
      {
        name: "caseNames.dtp", // "ДТП"
        url: "/dtp",
      },
      {
        name: "caseNames.criminalSituation", // "Криминогенная обстановка"
        url: "/crime",
      },
    ],
  },
  // {
  //   name: "Аналитические индикаторы",
  //   img: "analytical.svg",
  //   children: [
  //     {
  //       name: "ПРТ",
  //       url: "/analytical-indicators",
  //     },
  //   ],
  // },
  {
    name: "caseNames.economy", //"Экономика",
    img: "economy.svg",
    children: [
      {
        name: "caseNames.businessCard", // "Карта бизнеса"
        url: "/business-map",
      },
      {
        name: "caseNames.income", // "Доходы"
        url: "/income",
      },
      {
        name: "caseNames.costs", // "Расходы"
        url: "/outcome",
      },
    ],
  },
  {
    name: "caseNames.healthCare", // "Здравоохранение"
    img: "medicine.svg",
    children: [
      {
        name: "caseNames.morbidityData", // "Данные о заболеваемости (DamuMed)"
        url: "/damu-med",
      },
      // {
      //   name: "Анализ заболеваемости",
      // },
    ],
  },
  {
    name: "caseNames.infrastructure", // "Инфраструктура"
    img: "infrostructure.svg",
    children: [
      {
        name: "caseNames.repairAndConstruction", // "План ремонта и строительство инфраструктурных сетей"
        url: "/road-construction",
      },
    ],
  },
  {
    name: "caseNames.education", // "Образование"
    img: "education.svg",
    children: [
      {
        name: "caseNames.distributionOfChildrenInKindergartens", // "Распределение детей по дет. садам"
        url: "/ddo",
      },
      {
        name: "caseNames.distributionOfPupilsBySchool", // "Распределение учеников по школам"
        url: "/school",
      },
    ],
  },
  // {
  //   name: "Карта",
  //   img: "education.svg",
  //   children: [
  //     {
  //       name: "Карта камер",
  //       url: "/cameramap",
  //     },
  //   ],
  // },
  {
    name: "caseNames.transport",
    img: "transport.svg",
    children: [
      {
        name: "caseNames.infobus",
        url: "/infobus",
      },
      {
        name: "caseNames.routeSchedule",
        url: "/route-schedule",
      },
    ],
  },
  // {
  //   name: "caseNames.monitoringSmi",
  //   img: "/img/menu/analysis.png",
  //   url: "/monitoring-smi",
  // },
];
