import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const RangeDateFilter = ({
  startDate,
  endDate,
  setDate,
  dateFormat = "YYYY-MM-DD",
  ...props
}) => {
  let defaultDates = [
    startDate ? moment(startDate, dateFormat) : null,
    endDate ? moment(endDate, dateFormat) : null,
  ];
  const { t } = useTranslation();
  return (
    <RangePicker
      defaultValue={defaultDates}
      placeholder={[t("component.selectDate"), t("component.selectDate")]}
      format={dateFormat}
      onChange={
        (val) => setDate(val)
        // setDate({
        // dateType: "startDate",
        // value: moment(val).format(dateFormat),
        // })
      }
      allowClear={false}
      showToday={false}
      {...props}
    />
  );
};
export default RangeDateFilter;
{
  /* <DatePicker
        defaultValue={startDate ? moment(startDate, dateFormat) : null}
        placeholder="Выберите дату"
        format={dateFormat}
        onChange={(val) =>
          setDate({
            dateType: "startDate",
            value: moment(val).format(dateFormat),
          })
        }
        allowClear={false}
        showToday={false}
      /> */
}
{
  /* <DatePicker
        defaultValue={endDate ? moment(endDate, dateFormat) : null}
        format={dateFormat}
        placeholder="Выберите дату"
        onChange={(val) =>
          setDate({
            dateType: "endDate",
            value: moment(val).format(dateFormat),
          })
        }
        allowClear={false}
        showToday={false}
      /> */
}
