import React from "react";
import { withTranslation } from "react-i18next";
import requestAdmin from "utils/requestAdmin";
import request from "./../../utils/request";

class MapLegend extends React.Component {
  state = {
    eventnames: [],
  };

  componentDidMount() {
    requestAdmin("/api/v1/roadway/eventname/all", {
      method: "POST",
    }).then((r) => {
      let collection = r.map((el) => ({ label: el.nameRu, value: el.nameRu }));
      this.setState({
        eventnames: collection,
      });
    });
  }

  render() {
    let { t } = this.props;
    return (
      <div className="">
        <ul style={{ listStyle: "none" }}>
          {this.state.eventnames &&
            this.state.eventnames.map((el, index) => (
              <li key={index}>{el.label}</li>
            ))}
        </ul>
        <ul>
          <li style={{ color: "#ff0000" }}>
            {t("infrastructure.repairAndConstruction.redPlannedRenovation")}
          </li>
          <li style={{ color: "#ffff00" }}>
            {t("infrastructure.repairAndConstruction.yellowInProgress")}
          </li>
          <li style={{ color: "#008000" }}>
            {t("infrastructure.repairAndConstruction.greenDone")}
          </li>
        </ul>
      </div>
    );
  }
}

export default withTranslation()(MapLegend);
