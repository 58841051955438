import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");
const CalculateSchool = (payload1) => {
  const unique = payload1
    .map((e) => e["id"])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => payload1[e])
    .map((e) => payload1[e]);

  let mapCoords = unique;
  mapCoords.forEach((element) => {
    element.x = element.coordinatesX;
    element.y = element.coordinatesY;
  });
  let pieStat = {
    surplus: 0,
    shortage: 0,
    normal: 0,
  };
  unique.forEach((item, index) => {
    let coof = 1.5;
    if (item.schoolHasTwoShifts) unique[index].shifts = 2;
    else unique[index].shifts = 1;
    if (item.year > 1991) coof = 2;
    let sum = coof * item.place;
    let x = sum - item.actualOccupancy;
    if (x < 0) {
      unique[index].status = "Дефицит";
      pieStat.shortage++;
      mapCoords[index].color = "#fd625e";
      unique[index].statusValue = x;
      unique[index].coof = sum;
    } else {
      let percent = (item.actualOccupancy / item.place) * coof * 100;
      if (percent > 75) {
        unique[index].coof = sum;
        pieStat.normal++;
        unique[index].status = "Норма";
        mapCoords[index].color = "#fbc02d";
        unique[index].statusValue = "-";
      } else {
        unique[index].coof = sum;
        unique[index].status = "Профицит";
        unique[index].statusValue = "-";
        pieStat.surplus++;
        mapCoords[index].color = "#01b8aa";
      }
    }
  });
  mapCoords = mapCoords.filter((el) => el.x && el.y);
  let pieLang = [
    {
      category: "Смешанный",
      value: 0,
    },
    {
      category: "Казахский",
      value: 0,
    },
    {
      category: "Русский",
      value: 0,
    },
  ];
  let pieShifts = [
    {
      category: "Первая смена",
    },
    {
      category: "Вторая смена",
    },
  ];
  let pieSchool = [
    {
      category: "Школы с одной сменой",
      value: 0,
    },
    {
      category: "Школы с двумя сменами",
      value: 0,
    },
  ];
  let total1 = 0,
    total2 = 0;
  unique.forEach((item) => {
    pieLang.forEach((pie) => {
      if (
        item.languageEducation ===
        pie.category.charAt(0).toLowerCase() + pie.category.slice(1)
      )
        pie.value++;
    });
    if (item.countFirstShift) total1 += parseInt(item.countFirstShift);
    if (item.countSecondShift) total2 += parseInt(item.countSecondShift);
    if (item.schoolHasTwoShifts) pieSchool[1].value++;
    else pieSchool[0].value++;
  });
  pieShifts[0].value = total1;
  pieShifts[1].value = total2;
  const header = [
    {
      label: "Название",
      key: "nameOfOrganizationOfEducation",
    },
    {
      label: "Район",
      key: "district",
    },
    {
      label: "Местность",
      key: "locality",
    },
    {
      label: "Населённый пункт",
      key: "settlement",
    },
    {
      label: "Адрес",
      key: "address",
    },
    {
      label: "Язык обучения",
      key: "languageEducation",
    },
    {
      label: "Вид",
      key: "typeOfSchool",
    },
    {
      label: "Форма собственности",
      key: "formOfOwnership",
    },
    {
      label: "Год постройки",
      key: "year",
    },
    {
      label: "Проектная мощность",
      key: "place",
    },
    {
      label: "Проектная мощность*",
      key: "coof",
    },
    {
      label: "Фактическая наполненность",
      key: "actualOccupancy",
    },
    {
      label: "Заполненность",
      key: "status",
    },
    {
      label: "Величина дефицита",
      key: "statusValue",
    },
  ];

  const dataTable = {
    header,
    dataset: unique,
  };

  const pieData = [
    {
      category: "Профицит",
      value: pieStat.surplus,
      color: "#01b8aa",
    },
    {
      category: "Норма",
      value: pieStat.normal,
      color: "#388e3c",
    },
    {
      category: "Дефицит",
      value: pieStat.shortage,
      color: "#fd625e",
    },
  ];

  const shortageArr = unique.filter((el) => el.status === "Дефицит");

  let stat = {
    totalSchool: unique.length,
    projectCapacity: unique.reduce((acc, val) => acc + val.coof, 0),
    actualCapacity: unique.reduce((a, b) => a + b.actualOccupancy, 0),
    totalArea: unique.reduce((a, b) => a + b.totalArea, 0),
    shortage: shortageArr.reduce((a, b) => a + b.statusValue, 0),
  };

  const uponcy = unique.map((el) => ({
    category: el.nameOfOrganizationOfEducation,
    value: el.place,
    value1: el.actualOccupancy,
  }));
  const typeOfSchoolArr = Array.from(
    new Set(unique.map((el) => el.typeOfSchool))
  );
  const typeOfSchool = typeOfSchoolArr.map((el) => ({
    category: el,
    value: unique
      .map((obj) => obj.typeOfSchool === el)
      .reduce((a, b) => a + b, 0),
  }));
  stat.shortageDisplay =
    (stat.shortage > 0 ? "+" : "") + Math.round(Math.abs(stat.shortage));
  pieStat.surplusPercent = ((pieStat.surplus / stat.totalSchool) * 100).toFixed(
    1
  );
  pieStat.shortagePercent = (
    (pieStat.shortage / stat.totalSchool) *
    100
  ).toFixed(1);
  pieStat.normalPercent = ((pieStat.normal / stat.totalSchool) * 100).toFixed(
    1
  );
  return {
    mapCoords,
    stat,
    pieData,
    dataTable,
    pieStat,
    pieLang,
    pieShifts,
    pieSchool,
    uponcy,
    typeOfSchool,
  };
};
export default CalculateSchool;
