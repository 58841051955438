import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { CHART_LOCALE } from "../common/Constants";
import * as PropTypes from "prop-types";
import CustomPropTypes from "../../utils/propTypes";
import _ from "lodash";

export default class LineChartWrap extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    name1: PropTypes.string,
    names: PropTypes.array,
    yMin: PropTypes.number,
    yMax: PropTypes.number,
    values: PropTypes.array,
    color: PropTypes.string,
    color1: PropTypes.string,
    tensionX: PropTypes.number,
    tensionY: PropTypes.number,
    legend: PropTypes.bool,
    panel: PropTypes.bool,
    legendPosition: PropTypes.oneOf(["top", "bottom", "left", "right"]),
    categoryType: PropTypes.bool,
    bullets: PropTypes.bool,
    fullBullets: PropTypes.bool,
    fill: PropTypes.number,
    height: CustomPropTypes.Height,
    fontSize: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
    dateInterval: CustomPropTypes.TimeUnit,
    average: PropTypes.bool,
    colors: PropTypes.arrayOf(PropTypes.string.isRequired),
    totals: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.number,
        color: PropTypes.string,
      })
    ),
  };

  componentDidMount() {
    const {
      name = "Количество",
      name1 = "Количество 2",
      tensionX = 0.8,
      tensionY = 1,
    } = this.props;
    let chart = am4core.create(this.props.id, am4charts.XYChart);
    let data = this.props.data;

    chart.language.locale = CHART_LOCALE;
    if (this.props.categoryType) {
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 1;
    } else {
      const axis = new am4charts.DateAxis();
      if (this.props.dateInterval) {
        axis.baseInterval = this.props.dateInterval;
      }
      chart.xAxes.push(axis);
    }

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    const assignXAxisField = (series) => {
      if (this.props.categoryType) {
        series.dataFields.categoryX = "category";
      } else {
        series.dataFields.dateX = "date";
      }
    };

    const addSeries = (name, value, colorValue) => {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = value;
      series.name = name;
      series.tooltipText = "{name}: [bold]{valueY}[/]";
      series.strokeWidth = 3;
      series.tensionX = tensionX;
      series.tensionY = tensionY;

      if (this.props.fill) {
        series.fillOpacity = this.props.fill || 0.5;
      }

      const addBullets = (series) => {
        const labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.dy = -7;
        labelBullet.label.fill = am4core.color("#fff");
        labelBullet.fontSize = this.props.fontSize ? this.props.fontSize : 12;
        labelBullet.label.truncate = false;
        labelBullet.label.hideOversized = false;
      };

      if (this.props.bullets) {
        addBullets(series);
      }

      if (this.props.fullBullets) {
        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 1;
        categoryAxis.renderer.grid.template.paddingTop = 20;
        categoryAxis.renderer.cellStartLocation = 0.2;
        categoryAxis.renderer.cellEndLocation = 0.9;
        categoryAxis.renderer.minGridDistance = 10;
      }
      assignXAxisField(series);

      if (this.props.average) {
        let bullet = series.bullets.push(new am4charts.Bullet());
        bullet.tooltipText = "{valueY}";

        bullet.adapter.add("fill", function (fill, target) {
          if (target.dataItem.valueY > 7) {
            return am4core.color("#FF0000");
          }
          return fill;
        });
        let range = valueAxis.createSeriesRange(series);
        range.value = 7;
        range.endValue = 1000;
        range.contents.stroke = am4core.color("#FF0000");
        range.contents.fill = range.contents.stroke;
      }

      if (this.props.totals) {
        for (const total of this.props.totals) {
          if (total.colorOverflow) {
            const range = valueAxis.createSeriesRange(series);
            range.value = total.value;
            range.endValue = Number.MAX_VALUE;
            range.contents.stroke = am4core.color(total.colorOverflow);
            range.contents.fill = range.contents.stroke;

            if (this.props.fill) {
              range.contents.fillOpacity = this.props.fill || 0.5;
            }

            break;
          }
        }
      }

      const setColor = (series, color) => {
        if (color) {
          series.fill = am4core.color(color);
          series.stroke = am4core.color(color);
          series.tooltip.background.color = am4core.color(color);
          return true;
        }
        return false;
      };

      setColor(series, colorValue);
    };

    if (this.props.names) {
      const entries = _.zip(
        this.props.names,
        this.props.values,
        this.props.colors || []
      );
      for (const [name, value, color = this.props.color] of entries) {
        addSeries(name, value || "value", color);
      }
    } else {
      addSeries(name, "value", this.props.color);
      if (this.props.name1) {
        addSeries(name1, "value1", this.props.color1);
      }
    }

    if (this.props.legend || this.props.panel) {
      chart.legend = new am4charts.Legend();
      chart.legend.position = this.props.legendPosition || "bottom";

      if (this.props.panel) {
        chart.legend.fontSize = 12;
        const markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;
      }
    }

    if (this.props.totals) {
      this.props.totals.forEach(({ title, value, color }, index) => {
        const key = "total-" + index;
        for (const point of data) {
          point[key] = value;
        }
        const line = new am4charts.StepLineSeries();
        line.name = title;
        line.dataFields.valueY = key;
        assignXAxisField(line);
        if (color) {
          line.stroke = am4core.color(color);
          line.strokeWidth = 2;
        }
        line.tooltipText = "{name}:[bold]{valueY}";
        chart.series.push(line);
      });
    }

    if (typeof this.props.yMin === "number") {
      valueAxis.min = this.props.yMin;
    }
    if (typeof this.props.yMax === "number") {
      valueAxis.max = this.props.yMax;
    }

    chart.events.on("ready", (ev) => {
      valueAxis.extraMax = 0.1;
    });

    chart.cursor = new am4charts.XYCursor();
    chart.data = data;
    this.chart = chart;
  }

  componentDidUpdate(oldProps) {
    if (JSON.stringify(oldProps.data) !== JSON.stringify(this.props.data)) {
      let data = this.props.data;
      if (this.props.sort) {
        data !== undefined
          ? data.sort(
              (x, y) => Number(y[this.props.sort]) - Number(x[this.props.sort])
            )
          : console.log(data);
      }
      this.chart.data = data;
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { height = "40vh" } = this.props;
    return <div id={this.props.id} style={{ height: height }} />;
  }
}
