import * as PropTypes from "prop-types";

const CustomPropTypes = {
  Height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  TimeUnit: PropTypes.shape({
    timeUnit: PropTypes.oneOf([
      "millisecond",
      "second",
      "minute",
      "hour",
      "day",
      "week",
      "month",
      "year",
    ]),
    count: PropTypes.number,
  }),
};

export default CustomPropTypes;
