import { createTheme } from "react-data-table-component";

export function initDataTableThemes() {
  createTheme("sc", {
    text: {
      primary: "#FFFFFF",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(0,0,0,.12)",
    },
    background: {
      default: "#19375a",
    },
    context: {
      background: "#E91E63",
      text: "#FFFFFF",
    },
    divider: {
      default: "rgba(81, 81, 81, 1)",
    },
    button: {
      default: "#FFFFFF",
      focus: "rgba(255, 255, 255, .54)",
      hover: "rgba(255, 255, 255, .12)",
      disabled: "rgba(255, 255, 255, .18)",
    },
    sortFocus: {
      default: "rgba(255, 255, 255, .54)",
    },
    selected: {
      default: "#152F4E",
      text: "#FFFFFF",
    },
    highlightOnHover: {
      default: "#152F4E",
      text: "#FFFFFF",
    },
    striped: {
      default: "#152F4E",
      text: "#FFFFFF",
    },
  });
}
