import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { TRANSACTION_TYPES, COLORS } from "./constants";
const CHART_LOCALE = "ru";

export class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    const chart = am4core.create(this.props.id, am4charts.XYChart);
    chart.language.locale = CHART_LOCALE;
    chart.data = this.props.data;
    const { tension, handleClick } = this.props;

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    function createSeries(field, name, color) {
      const c_series = chart.series.push(new am4charts.LineSeries());
      c_series.stroke = am4core.color(color);
      c_series.fill = am4core.color(color);
      c_series.dataFields.categoryX = "category";
      c_series.dataFields.valueY = field;
      c_series.name = name;
      c_series.tooltipText = "{name}: [bold]{valueY}[/]";
      c_series.tooltip.label.maxWidth = 300;
      c_series.tooltip.label.wrap = true;
      if (tension) {
        c_series.tensionX = 0.77;
      }

      // if (handleClick) {
      //   c_series.columns.template.events.on("down", (ev) => {
      //     const category =
      //       ev.target.dataItem.dataContext[this.props.category || "category"];
      //     const context = ev.target.dataItem.dataContext;
      //     handleClick(category, context);
      //   });
      // }
    }
    Object.keys(TRANSACTION_TYPES).map((k, index) => {
      createSeries(k + "Total", TRANSACTION_TYPES[k], COLORS[index]);
    });

    chart.cursor = new am4charts.XYCursor();
    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 20;
    markerTemplate.height = 15;
    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  componentDidUpdate(oldProps) {
    if (this.state.data.length !== this.props.data.length) {
      this.setState(
        {
          data: this.props.data,
        },
        () => {
          this.chart.data = this.props.data;
        }
      );
    }
  }

  render() {
    const { height = "40vh", id } = this.props;
    return <div id={id} style={{ width: "100%", height: height }} />;
  }
}
