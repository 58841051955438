import { handleActions } from "redux-actions";

import { SAVE_APPEALS } from "./actions";

export const appeals = handleActions(
  {
    [SAVE_APPEALS]: {
      next: (state, { payload }) =>
        payload.appeals.filter(el => el.executor !== 'ГУ "Аппарат акима ВКО"')
    }
  },
  []
);
