import { Col, Pagination, Row, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import HorizontalBarChart from "components/charts/HorizontalBarChart";
import PieChartWrap from "components/charts/PieChartWrap";
import { StackedBarChart } from "components/charts/StackedBarChart";
import FilterSelect from "components/FilterSelect";
import LoadingSpinner from "components/LoadingSpinner";
import RangeDateFilter from "components/RangeDateFilter";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  getPaymentTypes,
  getRoutes,
  getYears,
  getTransactionByDay,
  getTransactionByType,
  getTransactionByRoute,
  getTransactionByMonth,
} from "./api";
import { COLORS, TRANSACTION_TYPES } from "./constants";
import { LineChart } from "./LineChart";
import Publication from "./Publication";

const currentD = moment(); //.format().valueOf();
const FORMAT = "YYYY-MM-DD",
  FORMAT_NULL = "YYYY-MM-DDT00:00:00";
const TEMPLATE = [
    {
      label: "Сегодня",
      value: "day",
    },
    {
      label: "Неделя",
      value: "week",
    },
    {
      label: "Текущий месяц",
      value: "month",
    },
    {
      label: "Год",
      value: "year",
    },
  ],
  CHOOSE_TEMP = {
    day: {
      from: currentD.format(FORMAT_NULL),
      to: currentD.format(FORMAT),
    },
    week: {
      from: moment().clone().startOf("week").format(FORMAT_NULL),
      to: currentD.format(FORMAT),
    },
    month: {
      from: moment().clone().startOf("month").format(FORMAT_NULL),
      to: currentD.format(FORMAT),
    },
    year: {
      from: moment().clone().startOf("year").format(FORMAT_NULL),
      to: currentD.format(FORMAT),
    },
  },
  SENTIMENT_SMI = {
    social: "Социальные сети",
    mass: "Новостные каналы",
  };

export default function Infobus() {
  const [filter, setFilter] = useState({
    from: moment().subtract(7, "d").format(FORMAT),
    to: moment().format(FORMAT),
    year: 2021,
    sentimentId: null,
    topicId: null,
    page: 0,
    size: 10,
  });
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({});
  const [modal, setModal] = useState({ visible: false, type: "", data: [] });

  console.log("Infobus", modal);
  useEffect(() => {
    setLoading(true);
    let filterUrl = "";
    Object.keys(filter).forEach((k) => {
      if (filter[k] !== null) filterUrl = filterUrl + `${k}=${filter[k]}&`;
    });

    console.log("filterUrl", filterUrl);

    Promise.all([
      getRoutes(filterUrl),
      getPaymentTypes(filterUrl),
      getYears(filterUrl),
      getTransactionByDay(filterUrl),
      getTransactionByType(filterUrl),
      getTransactionByRoute(filterUrl),
      getTransactionByMonth(filterUrl),
    ]).then(
      ([
        routes,
        paymentTypes,
        years,
        transactionByDay,
        transactionByType,
        transactionByRoute,
        transactionByMonth,
      ]) => {
        setStats({
          routes: routes?.map((i) => ({
            label: i,
            value: i,
          })),
          paymentTypes: paymentTypes?.map((i) => ({
            label: i,
            value: i,
          })),
          years: years?.map((i) => ({
            label: i,
            value: i,
          })),
          transactionByDay: transactionByDay?.map((i) => ({
            category: i.date,
            ...i,
          })),
          transactionByType: Object.keys(TRANSACTION_TYPES).map((k) => ({
            category: TRANSACTION_TYPES[k],
            value: transactionByType[k + "Total"],
          })),
          transactionByRoute: transactionByRoute?.map((i) => ({
            category: i.routeName,
            [TRANSACTION_TYPES.card]: i.cardsTotal,
            [TRANSACTION_TYPES.cash]: i.cashTotal,
            [TRANSACTION_TYPES.exempt]: i.exemptTotal,
          })),
          transactionByMonth: transactionByMonth?.map((i) => ({
            category: i.month,
            [TRANSACTION_TYPES.card]: i.cardTotal,
            [TRANSACTION_TYPES.cash]: i.cashTotal,
            [TRANSACTION_TYPES.exempt]: i.exemptTotal,
          })),
        });
        setLoading(false);
      }
    );
  }, [filter]);

  const handleChangeFilter = (filterData) => {
    setFilter({ ...filter, ...filterData });
  };

  const handleChangeDate = ([from, to]) => {
    setFilter({
      ...filter,
      from: from.format(FORMAT),
      to: to.format(FORMAT),
    });
  };

  const handleClear = (type) => {
    setFilter({
      from: moment().subtract(7, "d").format(FORMAT),
      to: moment().format(FORMAT),
      year: 2021,
      sentimentId: null,
      topicId: null,
      page: 0,
      size: 10,
    });

    console.log("setDateCrime", type);
  };

  const onChangePagination = (p) => {
    setFilter({ ...filter, page: p });
  };

  const ModalTableHeaders = {
    transactionByType: [
      {
        title: "Тип оплаты",
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Количество транзакции",
        dataIndex: "value",
        key: "value",
      },

      // {
      //   title: "Тональность",
      //   dataIndex: "sentimentId",
      //   key: "id",
      //   render: (id) => <>{TRANSACTION_TYPES[id]}</>,
      // },
    ],
    transactionByRoute: [
      {
        title: "Номер маршрута",
        dataIndex: "category",
        key: "category",
      },
      {
        title: TRANSACTION_TYPES.card,
        dataIndex: TRANSACTION_TYPES.card,
        key: TRANSACTION_TYPES.card,
      },
      {
        title: TRANSACTION_TYPES.exempt,
        dataIndex: TRANSACTION_TYPES.exempt,
        key: TRANSACTION_TYPES.exempt,
      },
      {
        title: TRANSACTION_TYPES.cash,
        dataIndex: TRANSACTION_TYPES.cash,
        key: TRANSACTION_TYPES.cash,
      },
    ],
    transactionByMonth: [
      {
        title: "Месяц",
        dataIndex: "category",
        key: "category",
      },
      {
        title: TRANSACTION_TYPES.card,
        dataIndex: TRANSACTION_TYPES.card,
        key: TRANSACTION_TYPES.card,
      },
      {
        title: TRANSACTION_TYPES.exempt,
        dataIndex: TRANSACTION_TYPES.exempt,
        key: TRANSACTION_TYPES.exempt,
      },
      {
        title: TRANSACTION_TYPES.cash,
        dataIndex: TRANSACTION_TYPES.cash,
        key: TRANSACTION_TYPES.cash,
      },
    ],
  };

  const getTranTotals = () => {
    console.log("getTranTotals", stats.transactionByType);
    let tranTotal = 0,
      cardTotal = 0,
      cashTotal = 0,
      exemptTotal = 0;
    if (stats.transactionByDay)
      stats.transactionByDay.forEach((i) => {
        tranTotal =
          tranTotal +
          parseInt(i.cardTotal) +
          parseInt(i.cashTotal) +
          parseInt(i.exemptTotal);
        cardTotal = cardTotal + i.cardTotal;
        cashTotal = cashTotal + i.cashTotal;
        exemptTotal = exemptTotal + i.exemptTotal;
      });
    return {
      tranTotal,
      cardTotal,
      cashTotal,
      exemptTotal,
    };
  };

  return (
    <>
      <Row className="mb20" gutter={16}>
        <Col span={6}>
          <RangeDateFilter
            startDate={filter.from}
            endDate={filter.to}
            value={[
              moment(filter.from, "YYYY-MM-DD"),
              moment(filter.to, "YYYY-MM-DD"),
            ]}
            setRange={handleChangeDate}
            width={"100"}
          />
        </Col>
        <Col span={6}>
          <FilterSelect
            options={stats.routes || []}
            filterField="route"
            handleChange={handleChangeFilter}
            handleClear={handleClear}
            placeholder={"Маршрут"}
            allowClear
            maxWidth="100%"
          />
        </Col>
        <Col span={6}>
          <FilterSelect
            options={stats.paymentTypes || []}
            filterField="type"
            handleChange={handleChangeFilter}
            handleClear={handleClear}
            placeholder={"Тип оплаты"}
            allowClear
            maxWidth="100%"
          />
        </Col>
      </Row>
      <Row className="mb20" gutter={16}>
        <Col span={6}>
          <p style={{ marginTop: 50 }}>
            Общее количество транзакции: {getTranTotals().tranTotal}
          </p>
          <p>- по транспортным картам: {getTranTotals().cardTotal}</p>
          <p>- в том числе по льготным: {getTranTotals().exemptTotal}</p>
          <p>- по наличному расчету: {getTranTotals().cashTotal}</p>
          <p>Количество маршрутов: {stats.routes?.length || 0} </p>
        </Col>
        <Col span={17}>
          <h3 className="t_center">Транзакции по дням</h3>
          <LoadingSpinner loading={loading}>
            <LineChart
              tension
              data={stats.transactionByDay}
              id="transactionByDayid"
              handleClick={(d, c) => console.log("transactionByDay", d, c)}
            />
          </LoadingSpinner>
        </Col>
      </Row>

      <Row className="mb20" gutter={16}>
        <Col span={12}>
          <h3 className="t_center">Транзакции по типам</h3>
          <LoadingSpinner loading={loading}>
            <PieChartWrap
              id="levels"
              data={stats.transactionByType}
              height="50vh"
              hideLabels
              handleClick={(d, c) =>
                setModal({
                  visible: true,
                  type: "transactionByType",
                  data: [c],
                })
              }
              colorsArr={COLORS}
            />
          </LoadingSpinner>
        </Col>
        <Col span={12}>
          <h3 className="t_center">Транзакции в разрезе маршрутов</h3>
          <LoadingSpinner loading={loading}>
            <StackedBarChart
              id="transactionByRouteId"
              sort
              data={stats.transactionByRoute}
              height="60vh"
              stack={Object.keys(TRANSACTION_TYPES).map((k) => ({
                field: TRANSACTION_TYPES[k],
                name: TRANSACTION_TYPES[k],
              }))}
              handleClick={(d, c) =>
                setModal({
                  visible: true,
                  type: "transactionByRoute",
                  data: [c],
                })
              }
              colorsArr={COLORS}
            />
          </LoadingSpinner>
        </Col>
      </Row>
      <div>
        <h3 className="t_center">Количество транзакции по месяцам</h3>
        <FilterSelect
          options={stats.years || []}
          value={filter.year}
          filterField="year"
          handleChange={handleChangeFilter}
          handleClear={handleClear}
          placeholder={"Год"}
          allowClear
        />
        <LoadingSpinner loading={loading}>
          <StackedBarChart
            id="transactionByMonthId"
            data={stats.transactionByMonth}
            height="60vh"
            stack={Object.keys(TRANSACTION_TYPES).map((k) => ({
              field: TRANSACTION_TYPES[k],
              name: TRANSACTION_TYPES[k],
            }))}
            handleClick={(d, c) =>
              setModal({
                visible: true,
                type: "transactionByMonth",
                data: [c],
              })
            }
            colorsArr={COLORS}
          />
        </LoadingSpinner>
      </div>
      <Modal
        title="Обращения"
        className="appeals_modal"
        visible={modal.visible}
        onOk={() => setModal({ visible: false, data: [] })}
        onCancel={() => setModal({ visible: false, data: [] })}
        footer={null}
        width={1200}
        bodyStyle={{
          height: "60vh",
        }}
      >
        <div className="table_height">
          {modal.data.length > 0 && (
            <Table
              columns={ModalTableHeaders[modal.type]}
              dataSource={modal.data}
              height="75vh"
            />
          )}
        </div>
      </Modal>
    </>
  );
}
