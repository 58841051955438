import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";

const COLORS = [
  "#F6772A",
  "#FAAD14",
  "#2F3243",
  "#7CB305",
  "#4B9A25",
  "#E6F7FF",
  "#1890FF",
  "#0050B3",
  "#A05ACC",
  "#F759AB",
  "#FF99CC",
  "#A61E25",
];

export default class HorizontalBarChart extends Component {
  state = { visible: false, items: [] };

  componentDidMount() {
    am4core.options.commercialLicense = true;
    const { loading, id } = this.props;

    // Create chart instance
    const chart = am4core.create(id, am4charts.XYChart);
    chart.language.locale = am4lang_ru_RU;

    this.chart = chart;
    if (loading) this.showIndicator();
    this.initChart();
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading } = this.props;
    if (loading && !prevProps.loading) {
      this.showIndicator();
    } else if (!loading && prevProps.loading) {
      this.initChart();
    }
  }

  initChart = () => {
    const {
      data,
      category,
      categoryText,
      labelFontSize,
      values,
      valueText,
      dateAxis,
      inputDateFormat,
      dateFormat,
      stacked,
      full,
      wrapLabels,
      labelMaxWidth,
      showValues,
      query,
      scrollStart,
      scrollEnd,
      colorsArr,
    } = this.props;
    if (!data) return;

    // Add data
    this.chart.data = data.length ? data : [];

    // legend
    if (values.length > 1) {
      this.chart.legend = new am4charts.Legend();
      this.chart.legend.useDefaultMarker = true;
      this.chart.legend.fontSize = 11;
      this.chart.legend.position = "bottom";
      this.chart.legend.labels.template.text = "{name}[/]";
      this.chart.legend.itemContainers.template.paddingTop = 1;
      this.chart.legend.itemContainers.template.paddingBottom = 1;

      let markerTemplate = this.chart.legend.markers.template;
      markerTemplate.width = 11;
      markerTemplate.height = 11;
      let marker = this.chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
    }

    // colors
    const colors = values.length
      ? values[0].color
        ? values.map((value) => value.color)
        : colorsArr
      : [];
    this.chart.colors.list = colors.map((color) => am4core.color(color));

    // Create axes
    this.chart.yAxes.clear();
    if (!dateAxis) {
      const categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = category;
      if (categoryText) categoryAxis.title.text = categoryText;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.grid.template.disabled = true;

      categoryAxis.renderer.labels.template.adapter.add(
        "text",
        function (labelText, target) {
          const { category } = target.dataItem;
          return !!category && category !== "" ? labelText : "Не определено";
        }
      );

      // label
      let label = categoryAxis.renderer.labels.template;
      if (wrapLabels) label.wrap = true;
      else label.truncate = true;
      label.fontSize = labelFontSize;
      label.dom.style.lineHeight = "6px";
      label.lineHeight = 1;
      label.maxWidth = labelMaxWidth;
      label.align = "left";
      label.hideOversized = true;

      if (values.length > 1 && !stacked) {
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;
      } else {
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;
      }
    } else {
      const dateAxis = this.chart.yAxes.push(new am4charts.DateAxis());
      dateAxis.dataFields.category = category;
      if (categoryText) dateAxis.title.text = categoryText;
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 10;
      dateAxis.renderer.labels.template.fontSize = 12;

      // dateAxis.parseDates = false;

      // Set input format for the dates
      this.chart.dateFormatter.inputDateFormat = inputDateFormat;
      // chart.dataDateFormat = "YYYY-MM-DDTJJ:NN:SS";
      // chart.dateFormatter.dateFormat = "dd";
      // dateAxis.dateFormatter = new am4core.DateFormatter();
      // dateAxis.dateFormatter.dateFormat = "dd";
      dateAxis.dateFormats.setKey("day", dateFormat);
      dateAxis.periodChangeDateFormats.setKey("day", dateFormat);

      if (values.length > 1 && !stacked) {
        dateAxis.renderer.cellStartLocation = 0.1;
        dateAxis.renderer.cellEndLocation = 0.9;
      }
    }

    this.chart.xAxes.clear();
    let valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
    if (valueText) valueAxis.title.text = valueText;
    valueAxis.renderer.labels.template.fontSize = 12;
    if (stacked && full) {
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;
      valueAxis.calculateTotals = true;
      valueAxis.renderer.minWidth = 50;
    }

    // Create series
    this.chart.series.clear();
    values.forEach((value, i) => {
      const { key, title } = value;
      let series = this.chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = key;
      series.dataFields[dateAxis ? "dateY" : "categoryY"] = category;
      if (stacked && full) series.dataFields.valueXShow = "totalPercent";

      series.name = this.props.customTitle ? this.props.customTitle : title;

      series.tooltipText = "{name}: [bold]{valueX}[/]";
      if (values.length > 1 && !stacked) {
        series.columns.template.width = am4core.percent(100);
      } else {
        series.columns.template.width = am4core.percent(100);
      }
      series.stacked = stacked;
      series.columns.template.column.cornerRadiusTopRight = 7;
      series.columns.template.column.cornerRadiusBottomRight = 7;

      // click
      series.columns.template.events.on("hit", (ev) => {
        const category = ev.target.dataItem.categoryY;
        if (Object.keys(query).length) this.loadDetails(category);
      });

      // colors
      // series.columns.template.stroke = am4core.color(
      //   value.color ? value.color : COLORS[i]
      // );
      // series.columns.template.fill = am4core.color(
      //   value.color ? value.color : COLORS[i]
      // );

      // bullets
      if (showValues) {
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueX}";
        labelBullet.dx = 20;
        labelBullet.label.fill = am4core.color("#fff");
        labelBullet.fontSize = 10;
        labelBullet.label.truncate = false;
        labelBullet.label.hideOversized = false;
      }
    });

    // Add cursor
    this.chart.cursor = new am4charts.XYCursor();
    this.chart.scrollbarY = new am4core.Scrollbar();
    this.chart.scrollbarY.start = scrollStart;
    this.chart.scrollbarY.end = scrollEnd;

    this.chart.events.on("ready", (ev) => {
      this.hideIndicator();
    });
    this.chart.events.dispatch("ready");
  };

  showIndicator = () => {
    this.indicator = this.chart.tooltipContainer.createChild(am4core.Container);
    this.indicator.background.fill = am4core.color("#323c48");
    this.indicator.background.fillOpacity = 0.8;
    this.indicator.width = am4core.percent(100);
    this.indicator.height = am4core.percent(100);

    const indicatorLabel = this.indicator.createChild(am4core.Label);
    indicatorLabel.text = "Loading data...";
    indicatorLabel.align = "center";
    indicatorLabel.valign = "middle";
    indicatorLabel.fontSize = 16;
    indicatorLabel.color = am4core.color("#9a9a9a");
    indicatorLabel.dy = 15;

    const spinner = this.indicator.createChild(am4core.Image);
    spinner.href = "/images/spinner.gif";
    spinner.align = "center";
    spinner.valign = "middle";
    spinner.horizontalCenter = "middle";
    spinner.verticalCenter = "middle";
    spinner.width = 24;
    spinner.height = 24;
    spinner.dy = -15;
  };

  hideIndicator = () => {
    this.indicator.hide();
  };

  loadDetails = async (categoryY) => {
    const { category, cubejsApi, query } = this.props;
    const resultSet = await cubejsApi.load({
      ...query,
      filters: [
        {
          dimension: category,
          operator: "equals",
          values: [categoryY],
        },
      ],
    });
    const items = resultSet.rawData();
    this.setState({
      visible: true,
      items,
    });
  };

  hideDetails = (e) => {
    this.setState({
      visible: false,
    });
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { visible, items } = this.state;
    const { id, height, renderDetail } = this.props;
    return (
      <div>
        <div id={id} style={{ width: "100%", height }} />
        <Modal
          title="Детализация"
          visible={visible}
          onCancel={this.hideDetails}
          width="96%"
          footer={null}
          bodyStyle={{
            height: "88vh",
            overflowY: "auto",
          }}
          centered
        >
          {renderDetail(items)}
        </Modal>
      </div>
    );
  }
}

HorizontalBarChart.defaultProps = {
  loading: true,
  id: "horizontal-bar-chart",
  category: "category",
  labelFontSize: 12,
  dateAxis: false,
  inputDateFormat: "yyyy-MM-ddT:HH:mm:ss.000",
  dateFormat: "dd MMM",
  stacked: false,
  full: false,
  wrapLabels: false,
  labelMaxWidth: 200,
  showValues: false,
  height: "300px",
  scrollStart: 0,
  scrollEnd: 1,
  cubejsApi: null,
  query: {},
  colorsArr: COLORS,
  renderDetail: () => {},
};

HorizontalBarChart.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  category: PropTypes.string,
  categoryText: PropTypes.string,
  labelFontSize: PropTypes.number.isRequired,
  values: PropTypes.array.isRequired,
  valueText: PropTypes.string,
  dateAxis: PropTypes.bool,
  inputDateFormat: PropTypes.string,
  dateFormat: PropTypes.string,
  stacked: PropTypes.bool,
  full: PropTypes.bool,
  showValues: PropTypes.bool,
  wrapLabels: PropTypes.bool,
  labelMaxWidth: PropTypes.number,
  height: PropTypes.string,
  scrollStart: PropTypes.number.isRequired,
  scrollEnd: PropTypes.number.isRequired,
  cubejsApi: PropTypes.object,
  query: PropTypes.object,
  colorsArr: PropTypes.array,
  renderDetail: PropTypes.func,
};
