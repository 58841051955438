import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

const RangeDateFilter = ({
  startDate,
  endDate,
  setDate,
  setRange,
  width,
  dateFormat = "YYYY-MM-DD",
  value,
  ...rest
}) => (
  <div className="range_date_wrapper" {...rest}>
    <DatePicker.RangePicker
      style={{ width: width ? `${width}%` : "" }}
      defaultValue={[
        moment(startDate, dateFormat),
        moment(endDate, dateFormat),
      ]}
      onChange={([dateFrom, dateTo]) => {
        if (setRange) {
          setRange([moment(dateFrom), moment(dateTo)]);
        } else if (setDate) {
          setDate({
            dateType: "startDate",
            value: moment(dateFrom).format(dateFormat),
          });
          setDate({
            dateType: "endDate",
            value: moment(dateTo).format(dateFormat),
          });
        } else {
          console.error("setRange and setDate props are empty");
        }
      }}
      allowClear={false}
      showToday={false}
      format={dateFormat}
      value={value}
    />
  </div>
);

export default RangeDateFilter;
