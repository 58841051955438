import React, { Component } from "react";
import { Radio, Row, Col, Select, Input, Result } from "antd";
import request from "../../utils/request";
import LoadingSpinner from "../../components/LoadingSpinner";
import Indicator from "./Indicator";
import TableView from "./TableView";
import { ContainerOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Search } = Input;
class AnalyticalIndicators extends Component {
  state = {
    data: [],
    currentSphere: {
      branches: [],
    },
    selectedBranch: null,
    selectedRegion: null,
    indicators: [],
    filteredIndicators: [],
    loading: true,
    view: "graph",
    exportData: [],
    regions: [],
    documents: [],
    selectedDocument: null,
  };

  componentDidMount() {
    request("/scala/all/").then((r) => {
      this.setState(
        {
          data: r.spheres,
          currentSphere: r.spheres[0],
          selectedBranch: r.spheres[0].branches[0],
          regions: r.regions,
          selectedRegion: "2",
          documents: r.documents.filter((e) => e),
        },
        () => this.getData()
      );
    });
  }

  getData = () => {
    const {
      selectedBranch,
      currentSphere,
      selectedRegion,
      selectedDocument,
    } = this.state;
    let query = `?sphere=${currentSphere.sphere}&branch=${selectedBranch}&region=${selectedRegion}`;
    if (selectedDocument) query += "&doc=" + selectedDocument;
    request("/scala/all" + query).then((r) => {
      this.setState(
        {
          indicators: r[0].branches[0].indicators,
          loading: false,
          filteredIndicators: r[0].branches[0].indicators,
        },
        () => this.prepareExport()
      );
    });
  };

  prepareExport = () => {
    const { indicators } = this.state;
    const exportData = indicators.map((el) => {
      return {
        name: el.indicator,
        data: this.parseData(el),
        columns: [
          { label: "Год", value: "year" },
          { label: "План", value: "plan" },
          { label: "Факт", value: "fact" },
        ],
      };
    });
    this.setState({ exportData });
  };

  parseData = (indicator) => {
    const uniqLabel = Array.from(
      new Set(
        [...indicator.fact.data, ...indicator.plan.data].map((e) => e.label)
      )
    );
    const data = uniqLabel.map((label) => {
      const plan = indicator.plan.data.find((e) => e.label === label);
      const fact = indicator.fact.data.find((e) => e.label === label);
      return {
        year: label + "",
        plan: plan ? plan.value : null,
        fact: fact ? fact.value : null,
      };
    });
  };

  sphereChanged = (e) => {
    const { data } = this.state;
    let obj = data.find((el) => el.sphere === e.target.value);
    this.setState(
      {
        currentSphere: obj,
        selectedBranch: obj.branches[0],
        loading: true,
      },
      () => this.getData()
    );
  };

  handleRegion = (e) => {
    this.setState(
      {
        selectedRegion: e,
        loading: true,
      },
      () => this.getData()
    );
  };

  handleBranch = (e) => {
    this.setState(
      {
        selectedBranch: e,
        loading: true,
      },
      () => this.getData()
    );
  };

  searchIndicators = (val) => {
    if (val) {
      let filteredIndicators = this.state.indicators.filter((el) => {
        return el.title.toLowerCase().includes(val);
      });
      this.setState({ filteredIndicators });
    } else {
      this.setState({
        filteredIndicators: this.state.indicators,
      });
    }
  };

  changeView = (e) => {
    this.setState({
      view: e,
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  changedDocument = (e) => {
    this.setState(
      {
        selectedDocument: e,
        loading: true,
      },
      () => this.getData()
    );
  };

  render() {
    const { data, currentSphere, filteredIndicators, loading } = this.state;

    let { isMobile } = this.props;
    return (
      <div className="p-10">
        <Radio.Group
          value={currentSphere.sphere}
          buttonStyle="solid"
          onChange={this.sphereChanged}
        >
          {data.map((el, index) => (
            <Radio.Button value={el.sphere} key={index}>
              {el.sphere}
            </Radio.Button>
          ))}
        </Radio.Group>
        <Row className="mt20">
          <Col span={24}>
            <Row>
              <Col span={isMobile ? 24 : 6}>
                <Select
                  style={{ width: "98%" }}
                  value={this.state.selectedBranch}
                  onChange={this.handleBranch}
                >
                  {currentSphere.branches.map((el, index) => (
                    <Option value={el} key={index}>
                      {el}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                {/* <Select
                  style={{ width: "98%" }}
                  value={this.state.selectedRegion}
                  onChange={this.handleRegion}
                >
                  {Object.keys(regions).map((key, index) => (
                    <Option value={key} key={index}>
                      {regions[key]}
                    </Option>
                  ))}
                </Select> */}
              </Col>

              <Col span={isMobile ? 24 : 3}>
                <Select
                  style={{ width: "98%" }}
                  defaultValue={this.state.view}
                  onChange={this.changeView}
                >
                  <Option value="graph">Диаграмма</Option>
                  <Option value="table">Таблица</Option>
                </Select>
                {/* <ExcelExport
                  style={{ width: "100%" }}
                  filename={
                    currentSphere.sphere + ": " + this.state.selectedBranch
                  }
                  lists={this.state.exportData}
                /> */}
              </Col>
              <Col span={isMobile ? 24 : 6}>
                <div style={{ marginLeft: "5px" }}>
                  <Search
                    placeholder="Поиск по индикатору"
                    onSearch={(value) => this.searchIndicators(value)}
                    style={{ width: "100%", margin: "0" }}
                    allowClear
                  />
                </div>
              </Col>
            </Row>
          </Col>
          {/* <Col span={6}>
            <div style={{ marginLeft: "5px" }}>
              <Search
                placeholder="Поиск по индикатору"
                onSearch={(value) => this.searchIndicators(value)}
                style={{ width: "100%", margin: "0" }}
                allowClear
              />
            </div>
            <div className="documents-list"> */}
          {/* {documents.map((e) => (
                <div
                  key={e}
                  onClick={() => this.changedDocument(e)}
                  className={this.state.selectedDocument === e ? "active" : ""}
                >
                  {e}
                </div>
              ))}
              {this.state.selectedDocument && (
                <Button
                  className="mt10"
                  type="primary"
                  block
                  onClick={() => this.changedDocument(null)}
                >
                  Сбросить
                </Button>
              )} */}
          {/* </div>
          </Col> */}
        </Row>
        <Row>
          <div className="indicators">
            <LoadingSpinner
              className="flex-full flex layout-centered"
              loading={loading}
              size={46}
            >
              {this.state.view === "graph" &&
                filteredIndicators.map((indicator, key) => (
                  <Indicator
                    key={key}
                    id={`indicator-${key}`}
                    indicator={indicator}
                  />
                ))}

              {this.state.view === "table" &&
                filteredIndicators.map((indicator, key) => (
                  <TableView key={key} indicator={indicator} />
                ))}

              {filteredIndicators.length === 0 && (
                <Result
                  icon={<ContainerOutlined />}
                  title="Индикаторы не найдены"
                />
              )}
            </LoadingSpinner>
          </div>
        </Row>
      </div>
    );
  }
}

export default AnalyticalIndicators;
