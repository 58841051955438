import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";

export const UP_COLOR = "#32cd66";
export const DOWN_COLOR = "#ff6600";
export const BG_COLOR = "rgba(50, 205, 102, 0.2)";
export const CHART_HEIGHT = "45vh";
export const CHART_HEIGHT_MIDDLE = "43vh";
export const CHART_HEIGHT_MIN = "40vh";

export const CHART_LOCALE = am4lang_ru_RU;

export const nav_tabs = [
  {
    name: "Карты",
    icon: "/vertical_icons/7-white.png",
    children: [
      {
        name: "Интерактивная карта",
        icon: "/vertical_icons/7-white.png",
        link: "/map",
      },
      {
        name: "Инфраструктурная карта",
        icon: "/vertical_icons/7-white.png",
        link: "/temp-map",
      },
      {
        name: "Нарушения в аптеках",
        icon: "/vertical_icons/7-white.png",
        link: "/pharmacy",
      },
      {
        name: "Карта COVID-19",
        icon: "/vertical_icons/7-white.png",
        link: "/another-map",
      },
      {
        name: "Термомониторинг",
        icon: "/vertical_icons/7-white.png",
        link: "/temperature-map",
      },
      {
        name: "Блок посты",
        icon: "/vertical_icons/7-white.png",
        link: "/block-posts",
      },
      {
        name: "Блок посты",
        icon: "/vertical_icons/7-white.png",
        link: "/block-posts",
      },
    ],
  },
];

export const COLORS = [
  "#0975da",
  "#e7941a",
  "#828282",
  "#edd10b",
  "#32b212",
  "#71058f",
  "#4dc9f6",
  "#f67019",
  "#f53794",
  "#537bc4",
  "#acc236",
  "#166a8f",
  "#00a950",
  "#58595b",
  "#8549ba",
  "#ff0000",
  "#f53794",
  "#537bc4",
  "#acc236",
  "#166a8f",
  "#00a950",
  "#58595b",
  "#8549ba",
];

export const menGroups = [
  {
    percentage: 0.17,
    multiplier: 0.95,
  },
  {
    percentage: 0.13,
    multiplier: 0.14,
  },
  {
    percentage: 0.16,
    multiplier: 0.37,
  },
  {
    percentage: 0.16,
    multiplier: 1.9,
  },
  {
    percentage: 0.13,
    multiplier: 1.42,
  },
  {
    percentage: 0.13,
    multiplier: 2.84,
  },
  {
    percentage: 0.08,
    multiplier: 5.42,
  },
  {
    percentage: 0.03,
    multiplier: 10.63,
  },
  {
    percentage: 0.01,
    multiplier: 26.64,
  },
];

export const womenGroups = [
  {
    percentage: 0.15,
    multiplier: 1.04,
  },
  {
    percentage: 0.11,
    multiplier: 0.07,
  },
  {
    percentage: 0.14,
    multiplier: 0.13,
  },
  {
    percentage: 0.14,
    multiplier: 0.4,
  },
  {
    percentage: 0.13,
    multiplier: 0.72,
  },
  {
    percentage: 0.14,
    multiplier: 1.32,
  },
  {
    percentage: 0.11,
    multiplier: 2.22,
  },
  {
    percentage: 0.06,
    multiplier: 5.22,
  },
  {
    percentage: 0.03,
    multiplier: 29.3,
  },
];

export const highContrastColors = [
  "#f0a3ff",
  "#0075dc",
  "#993f00",
  "#94ffb5",
  "#4c005c",
  "#191919",
  "#c20088",
  "#005c31",
  "#8f7c00",
  "#ffcc99",
  "#9dcc00",
  "#003380",
  "#426600",
  "#ff0010",
  "#5ef1f2",
  "#990000",
  "#e0ff66",
  "#808080",
  "#2bce48",
  "#740aff",
  "#ff5005",
  "#00998f",
  "#ffff80",
  "#ffa8bb",
];

export const colorArray = [
  "#2da57e",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

const colorsNature = [
  "#aebd38",
  "#505160",
  "#68829e",
  "#ffbb00",
  "#5bc8ac",
  "#598234",
  "#98dbc6",
  "#236756",
  "#ceeee6",
  "#8080ff",
  "#a8c4d7",
];

export const Palette = {
  Fish: colorsNature,
  Animals: colorsNature,
  Forest: colorsNature,
  Pastel: [
    "#ffb4bb",
    "#f3bbae",
    "#ffe0ba",
    "#fcdccf",
    "#fffebb",
    "#baffc9",
    "#bae1ff",
  ],
  Education: ["#fd625e", "#fbc02d", "#00b3e6", "#828282"],
  GovServices: [
    "#FFD5C2",
    "#ad94aa",
    "#01B8AA",
    "#808080",
    "#8AD4EB",
    "#CCCCCC",
    "#FFF44F",
  ],
  Humanitarian: [
    "#00937b",
    "#acca43",
    "#89deaf",
    "#acca43",
    "#89a07f",
    "#a8d1ba",
    "#d8d9d8",
    "#01818d",
  ],
  InvesProject: [
    "#6899d6",
    "#6e57b7",
    "#b8b4ea",
    "#91e2c0",
    "#05858c",
    "#ac3f92",
    "#0057b7",
  ],
  Default: colorArray,
};

function* colorGenerator(colors) {
  let index = 0;
  while (true) {
    const color = colors[index];
    if (++index >= colors.length) {
      index = 0;
    }
    yield color;
  }
}

export function makeColorGenerator(palette = Palette.Default) {
  const generator = colorGenerator(palette);
  return () => generator.next().value;
}
