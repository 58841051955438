import React from "react";

import { Pagination, Row } from "antd";
import styled from "styled-components";

class DtpTable extends React.Component {
  state = {
    selectedObject: {},
    objects: [],
    current: 1,
  };

  componentDidUpdate(oldProps) {
    if (JSON.stringify(oldProps.object) !== JSON.stringify(this.props.object)) {
      if (!this.props.object.length) {
        this.setState({
          objects: [this.props.object],
          selectedObject: this.props.object,
          current: 1,
        });
      } else
        this.setState({
          objects: this.props.object,
          selectedObject: this.props.object[0],
          current: 1,
        });
    }
  }

  onChange = (page) => {
    this.setState((state) => {
      return {
        current: page,
        selectedObject: state.objects.length ? state.objects[page - 1] : {},
      };
    });
  };

  formatValue = (value) => {
    const { selectedObject } = this.state;
    return typeof value === "function"
      ? value(selectedObject)
      : selectedObject[value];
  };

  render() {
    const { objects } = this.state;

    return (
      <Container className="table_wrap withScroll" {...this.props}>
        <Row type="flex" justify="space-between">
          <Title>{this.props.title}</Title>
          <div className="dark_table dark-pagination-appeals">
            <Pagination
              pageSize={1}
              size="small"
              current={this.state.current}
              onChange={this.onChange}
              total={objects.length}
            />
          </div>
        </Row>
        {this.props.tableRows.map((row) => (
          <div className="t_row dtpTable" key={row.name}>
            <div className="column">
              <p>{row.name}:</p>
            </div>
            <div className="column">
              <p>{this.formatValue(row.key)}</p>
            </div>
          </div>
        ))}
      </Container>
    );
  }
  static defaultProps = {
    title: "Данные:",
  };
}
export default DtpTable;

export let Container = styled.div`
  padding: 10px;
  min-height: 400px;
`;

export let Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
`;
