import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { CHART_LOCALE } from "../../components/common/Constants";
class LineGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    am4core.options.queue = true;
    let chart = am4core.create(this.props.id, am4charts.XYChart);
    chart.language.locale = CHART_LOCALE;
    chart.data = this.props.data;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = this.props.unit;
    valueAxis.title.fontSize = 10;
    let plan = chart.series.push(new am4charts.LineSeries());
    plan.stroke = am4core.color("#0288d1");
    plan.fill = am4core.color("#0288d1");
    plan.dataFields.categoryX = "year";
    plan.dataFields.valueY = "plan";
    plan.tooltipText = "{name}: [bold]{valueY}[/]";
    plan.name = "План";
    plan.strokeWidth = 1;
    plan.tensionX = 0.8;
    plan.tensionY = 1;
    plan.fillOpacity = 0.2;

    let fact = chart.series.push(new am4charts.LineSeries());
    fact.stroke = am4core.color("#fbc02d");
    fact.fill = am4core.color("#fbc02d");
    fact.dataFields.categoryX = "year";
    fact.dataFields.valueY = "fact";
    fact.tooltipText = "{name}: [bold]{valueY}[/]";
    fact.name = "Факт";
    fact.strokeWidth = 2;
    fact.tensionX = 0.8;
    fact.tensionY = 1;

    chart.cursor = new am4charts.XYCursor();

    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 15;
    if (this.props.responsive) {
      chart.responsive.enabled = true;
    }
    this.chart = chart;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data.length !== this.props.data.length) {
      this.setState(
        {
          data: this.props.data,
        },
        () => (this.chart.data = this.props.data)
      );
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id={this.props.id} style={{ height: "45vh" }}></div>;
  }
}

export default LineGraph;
