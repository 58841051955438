import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Input, notification } from "antd";
import styled from "styled-components";
import { loginUser } from "redux/auth";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import requestAuth from "./../../utils/requestAuth";
import bcrypt from "bcryptjs";
class Login extends Component {
  state = {
    sent: false,
    crypted: [],
  };

  handleSubmit = (e) => {
    // e.preventDefault();

    this.props
      .loginUser(e)
      .catch(() => {
        this.setState({ sent: false });
        notification.error({
          key: "requestFailedNotification",
          message: "Войти не удалось",
          description: "Вы ввели неправильный логин или пароль.",
        });
      })
      .then((response) => {
        if (response) {
          requestAuth("/users/current").then((r) => {
            requestAuth("/get/casesroles/" + r.roleEntity.id).then((data) => {
              let crypted = [];
              const casesRoles = data.map((e) => "/" + e.casesEntity.code);
              casesRoles.push("/");
              casesRoles.forEach((caseItem) => {
                const hash = bcrypt.hashSync(caseItem, 2);
                crypted.push(hash);
              });
              localStorage.setItem("modules", JSON.stringify(crypted));
            });
          });
        }
      });
    this.setState({ sent: true });
  };

  render() {
    return (
      <Wrapper>
        <Container>
          <Img src="/img/logo.png" />
          <Headline>
            Информационно-аналитическая система акимата Акмолинской области
          </Headline>
          <FormContainer>
            <FormTitle>Авторизация</FormTitle>
            <Form onFinish={this.handleSubmit} className="login-form">
              <Form.Item
                label="Логин:"
                name="login"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, укажите имя пользователя.",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Введите ваш логин"
                />
                {/* {getFieldDecorator("username", {
                  rules: [
                    {
                      required: true,
                      message: "Пожалуйста, укажите имя пользователя.",
                    },
                  ],
                })(
                  <Input
                    prefix={
                      <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Введите ваш логин"
                  />
                )} */}
              </Form.Item>
              <Form.Item
                label="Пароль:"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, укажите пароль.",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Не менее 8 символов"
                />
                {/* {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Пожалуйста, укажите пароль." },
                  ],
                })(
                  <Input
                    prefix={
                      <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Не менее 8 символов"
                  />
                )} */}
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                autoFocus
                loading={this.state.sent}
              >
                Вход
              </Button>
            </Form>
          </FormContainer>
        </Container>
      </Wrapper>
    );
  }
}

export default connect(null, { loginUser })(Login);

let Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

let Container = styled.div`
  max-width: 540px;
  // padding-bottom: 100px;
  padding: 100px 0 200px;
`;

let Headline = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  margin: 30px 0;
`;

let Img = styled.img``;

let FormContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 20px 30px;
  text-align: left;
`;

let FormTitle = styled.div`
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #24262f;
  margin-bottom: 20px;
`;
