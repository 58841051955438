import React, { Component } from "react";
import { Row, Col } from "antd";
import PieChartWrap from "components/charts/PieChartWrap";
import HorizontalBarChart from "../../components/charts/HorizontalBarChart";
import Title from "components/common/Title";
import { CHART_HEIGHT } from "../../../src/components/common/Constants";
import moment from "moment";
import request from "../../utils/request";
import CalculateDtp from "modules/dtp/CalculateDtp";
import LoadingSpinner from "../../components/LoadingSpinner";
import CalculateSchool from "../../../src/modules/Social/CalculateSchool";
import RowStat from "components/RowStat";
import { Container } from "components/common/Elements";
import { formatNumber } from "../../../src/utils/helpers";
import { DdoItem } from "../../../src/pages/PreSchoolEducation/Ddo";
import { OrangeBox } from "../../../src/components/common/Elements";
import CalculatePreSchool from "../../../src/modules/Social/CalculatePreSchool";
import cubejs from "../../cube";
import {
  clearCrimeFilter,
  fetchCrimeAll,
  selectCrime,
  setCrimeFilter,
  setDateCrime,
  setFeaturedCategory,
  setSelectedCategory,
} from "../../modules/operational/actions";
import {
  getAllCrimeByCategoryValues,
  getAllCrime,
  getCrimeEndDate,
  getCrimeStartDate,
} from "../../modules/operational/selectors";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  crimeByCategoryValues: getAllCrimeByCategoryValues(state),
  crime: getAllCrime(state),
  startDate: getCrimeStartDate(state),
  endDate: getCrimeEndDate(state),
});
const mapDispatchToProps = {
  setDateCrime,
  fetchCrimeAll,
  setCrimeFilter,
  clearCrimeFilter,
  selectCrime,
  setFeaturedCategory,
  setSelectedCategory,
};

const cubejsApi = cubejs({ appId: 1 });

class MainPage extends Component {
  constructor() {
    super();
    this.state = {
      startDate1: moment().subtract(2, "week").format("YYYY-MM-DD"),
      endDate1: moment().format("YYYY-MM-DD"),
      startDate2: moment().subtract(2, "week").format("YYYY-MM-DD"),
      endDate2: moment().format("YYYY-MM-DD"),
      startDateD: moment().startOf("day"),
      endDateD: moment().endOf("day"),
      payload: {},
      filteredData: {
        mapCoords: [],
        stat: {},
        pieData: [],
        pieStat: {},
      },
      isDataLoaded: false,
      chart1: [],
      count1: 0,
      data: {
        selectFilters: {},
        totalArea: 0,
        totalSeats: 0,
        totalContingent: 0,
        pieStat: {},
        data: [],
        tableData: [],
      },
      loading: true,
      flatData: [],
      modalVisible: false,
      selected: {},
      records: [],
      selectedSlice: [],
      maxDate: null,
    };
    this.setDate = this.setDate.bind(this);
  }
  dateChange = (val, key) => {
    this.setState(
      {
        [key]: moment(val),
        pieData1: [],
      },
      () => this.getData()
    );
  };

  componentDidMount() {
    this.getData();
    this.getData1();
    this.getData3();
    this.props.fetchCrimeAll(
      moment().subtract(2, "week").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );
    this.fetchSchools();
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate } = this.props;
    const { startDate: pStartDate, endDate: pEndDate } = prevProps;
    if (startDate !== pStartDate || endDate !== pEndDate) {
      this.props.fetchCrimeAll(startDate, endDate);
    }
  }

  getData3 = () => {
    this.getDashData();
    this.getChartData();
  };

  getDashData = () => {
    const { startDateD, endDateD } = this.state;
    const range = [startDateD, endDateD];
    let req = [
      {
        measures: ["HospitalQueueCharts.currentValue"],
        filters: [
          {
            member: "HospitalQueueCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
      },
    ];
    if (!startDateD || !endDateD) {
      req.forEach((it) => {
        delete it.filters;
      });
    }
    Promise.all(req.map((e) => cubejsApi.load(e))).then((res) => {
      let data = res.map((e) => e.rawData());
      this.setState({
        count1: data[0][0]["HospitalQueueCharts.currentValue"],
      });
    });
  };

  getChartData = async () => {
    this.setState({ isDataLoaded: false });
    const { startDateD, endDateD } = this.state;
    const range = [startDateD, endDateD];
    const queries = [
      {
        measures: ["HospitalQueueCharts.currentValue"],
        filters: [
          {
            member: "HospitalQueueCharts.createdDate",
            operator: "inDateRange",
            values: range,
          },
        ],
        dimensions: ["HospitalQueueCharts.orgHealthCareNameRu"],
      },
    ];
    if (!startDateD || !endDateD) {
      queries.forEach((it) => {
        delete it.filters;
      });
    }
    Promise.all(queries.map((e) => cubejsApi.load(e))).then((res) => {
      let data = res.map((e) => e.rawData());
      this.setState({
        chart1: data[0].map((el) => ({
          category: el["HospitalQueueCharts.orgHealthCareNameRu"],
          value: el["HospitalQueueCharts.currentValue"],
        })),
        isDataLoaded: true,
      });
    });
  };
  getData1 = () => {
    request("/datastub/ddo").then((r) => {
      const data = CalculatePreSchool(r);

      let selectFilters = {
        status: [...new Set(r.map((el) => el.status))],
        studyLang: [...new Set(r.map((el) => el.studyLang))],
        district: [...new Set(r.map((el) => el.district))],
        name: [...new Set(r.map((el) => el.name))],
        form: [...new Set(r.map((el) => el.form))],
        type: [...new Set(r.map((el) => el.type))],
        time: [...new Set(r.map((el) => el.time))],
        localityType: [...new Set(r.map((el) => el.localityType))],
      };
      selectFilters.time = selectFilters.time.filter((t) => t);
      Object.keys(selectFilters).forEach((key) => {
        selectFilters[key] = selectFilters[key].map((el) => {
          return {
            label: el,
            value: el.toLowerCase(),
          };
        });
      });
      setTimeout(() => {
        this.setState({
          constantData: r,
          data,
          selectFilters,
          loading: false,
        });
      }, 500);
    });
  };
  filterSelectOptions = (r) => {
    let selectFilters = {
      district: [...new Set(r.map((el) => el.district))],
      settlement: [...new Set(r.map((el) => el.settlement))],
      languageEducation: ["русский", "казахский", "смешанные"],
      formOfOwnership: [...new Set(r.map((el) => el.formOfOwnership))],
      responsible: [...new Set(r.map((el) => el.responsible))],
      microdistrict: [...new Set(r.map((el) => el.microdistrict))],
      locality: [...new Set(r.map((el) => el.locality))],
      typeOfSchool: [...new Set(r.map((el) => el.typeOfSchool))],
      status: [...new Set(r.map((el) => el.status))],
      shifts: [...new Set(r.map((el) => el.shifts))],
      nameOfOrganizationOfEducation: [
        ...new Set(r.map((el) => el.nameOfOrganizationOfEducation)),
      ],
    };
    selectFilters.microdistrict = selectFilters.microdistrict.filter(
      (el) => el
    );
    Object.keys(selectFilters).forEach((key) => {
      selectFilters[key] = selectFilters[key].sort().map((el) => {
        return {
          label: el,
          value: el,
        };
      });
    });
    this.setState({ selectFilters });
  };

  fetchSchools = () => {
    request(`/school/list`)
      .then((r) => {
        let payload1 = CalculateSchool(r);
        this.filterSelectOptions(r);
        this.setState({
          constantData: r,
          filteredData: payload1,
          loading: false,
        });
      })
      .catch((err) => {});
  };

  applyFilters = () => {
    let { filters, constantData } = this.state;
    let arr = [];
    constantData.forEach((element) => {
      let count = 0;
      Object.keys(filters).forEach((key) => {
        if (filters[key] === element[key]) count++;
      });
      if (count === Object.keys(filters).length) arr.push(element);
    });
    const filteredData = CalculateSchool(arr);
    this.filterSelectOptions(arr);
    setTimeout(() => {
      this.setState({
        filteredData,
        loading: false,
      });
    }, 1000);
  };

  handleMultipleSelect = (obj) => {
    let { filteredData } = this.state;

    obj = this.dataSetModifier(obj);

    filteredData.dataTable.dataset = obj;
    this.setState({ filteredData });
  };

  getData = async () => {
    const { startDate1, endDate1 } = this.state;
    const dtp = request(`/dtp/dtpoper?dateb=${startDate1}&datee=${endDate1}`);
    let dateFrom = moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    let dateTo = moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    let today = moment().format("YYYY-MM-DD");

    const dtpByMonthPrev = request(
      `/dtp/dtpoper?dateb=${dateFrom}&datee=${dateTo}`
    );
    const dtpByMonth = request(
      `/dtp/dtpoper?dateb=${moment()
        .startOf("month")
        .format("YYYY-MM-DD")}&datee=${today}`
    );

    dtpByMonthPrev.then((res) => {
      let mapObjects = res.districts.map((item) => {
        return [...item.data];
      });
      mapObjects = mapObjects.flat();
      mapObjects = mapObjects.filter((i) => i.date);
      let temp = [];

      mapObjects.forEach((obj) => {
        let index = temp.findIndex((i) => i.category === obj.narushenie);
        let tempoObj = temp[index];
        index !== -1
          ? (temp[index] = { ...tempoObj, value: tempoObj.value + 1 })
          : temp.push({
              category: obj.narushenie,
              value: 1,
            });
      });

      this.setState((prev) => ({
        ...prev,
        payload: {
          ...prev.payload,
          catPieChart: temp,
        },
      }));
    });

    dtpByMonth.then((res) => {
      let mapObjects = res.districts.map((item) => {
        return [...item.data];
      });
      mapObjects = mapObjects.flat();
      mapObjects = mapObjects.filter((i) => i.date);
      let temp = [];

      mapObjects.forEach((obj) => {
        let index = temp.findIndex((i) => i.category === obj.narushenie);
        let tempoObj = temp[index];
        index !== -1
          ? (temp[index] = { ...tempoObj, value: tempoObj.value + 1 })
          : temp.push({
              category: obj.narushenie,
              value: 1,
            });
      });

      this.setState((prev) => ({
        ...prev,
        payload: {
          ...prev.payload,
          catPieChart2: temp,
        },
      }));
    });

    dtp
      .then((r) => {
        let payload = CalculateDtp(r);
        let flatData = [];
        r.districts.forEach((d) => {
          d.data.forEach((item) => flatData.push(item));
        });
        let maxDate = moment(
          Math.max.apply(
            null,
            flatData.map((e) => new Date(e.date))
          )
        );
        this.setState((prev) => ({
          ...prev,
          payload: {
            ...prev.payload,
            ...payload,
          },
          flatData,
          loading: false,
          constantData: r,
          maxDate,
        }));
      })
      .catch((err) => {});
  };

  setDate(payload) {
    this.setState(
      {
        [payload.dateType]: payload.value,
        selectedSlice: [],
      },
      () => this.getData()
    );
  }

  handleSelect(obj) {
    this.setState({ selected: obj });
  }

  showIncision = (category) => {
    const { constantData } = this.state;
    let selectedSlice;
    constantData.districts.forEach((element) => {
      if (element.name === category) selectedSlice = element.data;
    });
    let uniqIncision = [...new Set(selectedSlice.map((el) => el.incision))];
    uniqIncision = uniqIncision.map((el) => {
      return {
        category: el,
        value: 0,
      };
    });
    uniqIncision.forEach((el, index) => {
      selectedSlice.forEach((slice) => {
        if (el.category === slice.incision) uniqIncision[index].value++;
      });
    });
    this.setState({ selectedSlice: uniqIncision });
  };

  showModal = (category, key) => {
    const { flatData } = this.state;
    let records = flatData.filter((el) => el[key] === category);
    if (!records[0]) records = flatData.filter((el) => !el[key]);
    this.setState({
      records,
      modalVisible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      modalVisible: false,
      records: [],
    });
  };

  showMonthData = (category) => {
    const { flatData } = this.state;
    let records = flatData.filter((el) => {
      let date = moment(el.date).format("MMMM");
      if (date === category) return true;
      else return false;
    });
    this.setState({
      records,
      modalVisible: true,
    });
  };
  handleOk = (e) => {
    this.setState({
      filtersVisible: false,
    });
  };

  handleCancelModal = (e) => {
    this.setState({
      filtersVisible: false,
    });
  };

  applySelectFilter() {
    const { constantData, filterValues } = this.state;
    let arr = [];
    constantData.forEach((element) => {
      let count = 0;
      Object.keys(filterValues).forEach((key) => {
        if (filterValues[key] === element[key].toLowerCase()) count++;
      });
      if (count === Object.keys(filterValues).length) arr.push(element);
    });
    const data = CalculatePreSchool(arr);

    setTimeout(() => {
      this.setState({
        data,
        loading: false,
      });
    }, 500);
  }
  showFilters = () => {
    const state = !this.state.filtersVisible;
    this.setState({
      filtersVisible: state,
    });
  };
  dataSetModifier = (payload1) => {
    let mapCoords = JSON.stringify(payload1);
    mapCoords = JSON.parse(mapCoords);
    mapCoords.forEach((element) => {
      element.x = element.coordinatesX;
      element.y = element.coordinatesY;
    });
    // payload1.forEach((item, index) => {
    //   let coof = 1.5;
    //   if (item.schoolHasTwoShifts) payload1[index].shifts = 2;
    //   else payload1[index].shifts = 1;
    //   if (item.year > 1991) coof = 2;
    //   let sum = coof * item.place;
    //   let x = sum - item.actualOccupancy;
    //   if (x < 0) {
    //     payload1[index].status = "Дефицит";
    //     pieStat.shortage++;
    //     mapCoords[index].color = "#fd625e";
    //     payload1[index].statusValue = x;
    //     payload1[index].coof = sum;
    //   } else {
    //     let percent = (item.actualOccupancy / item.place) * coof * 100;
    //     if (percent > 75) {
    //       payload1[index].coof = sum;
    //       pieStat.normal++;
    //       payload1[index].status = "Норма";
    //       mapCoords[index].color = "#fbc02d";
    //       payload1[index].statusValue = "-";
    //     } else {
    //       payload1[index].coof = sum;
    //       payload1[index].status = "Профицит";
    //       payload1[index].statusValue = "-";
    //       pieStat.surplus++;
    //       mapCoords[index].color = "#01b8aa";
    //     }
    //   }
    // });
    return payload1;
  };
  handleLangFilteredPieData(value) {
    let {
      t,
      i18n: { language },
    } = this.props;
    return value?.map((data) => {
      if (language === "ru") return data;
      else if (language === "kz") {
        let word = "";
        switch (data.category) {
          case "Профицит":
            word = t("mainPage.surplus");
            break;
          case "Норма":
            word = t("mainPage.norm");
            break;
          case "Дефицит":
            word = t("mainPage.deficit");
            break;

          default:
            break;
        }

        return {
          ...data,
          category: word,
        };
      }
    });
  }

  render() {
    let { t } = this.props;
    let stats = [{ name: t("mainPage.total"), value: this.state.count1 }];
    const {
      filteredData,
      loading,
      data,
      payload: { mapObjects, rayons },
    } = this.state;
    const { crime, crimeByCategoryValues } = this.props;

    let dtpItems = [
      {
        name: t("mainPage.total"),
        value: crime.length,
      },
    ];

    let ddoitems = [
      {
        title: "Количество дошкольных организаций",
        value: formatNumber(data.data.length),
      },
    ];
    let schoolItems = [
      {
        title: t("mainPage.numberOfSchools"),
        value: formatNumber(filteredData.stat.totalSchool) || 0,
      },
      {
        title: t("mainPage.projectCapacity"),
        value: formatNumber(filteredData.stat.projectCapacity),
      },
    ];
    let public_trans = 0,
      pedestrian = 0,
      alcohol = 0;
    mapObjects &&
      mapObjects.map((item) => {
        if (item.vidDTP === 1) pedestrian++;
        else if (item.tipTransporta === "1") public_trans++;
        else if (item.sostoyanieVoditelya === "алкогольное опьянение")
          alcohol++;
      });
    let statsDtp = [
      { name: t("mainPage.total"), value: mapObjects && mapObjects.length },
    ];

    let mappedFilteredPieData = this.handleLangFilteredPieData(
      filteredData.pieData
    );

    let mappedPieData = this.handleLangFilteredPieData(data.pieData);

    // let mapData =
    //   mapObjects &&
    //   mapObjects.map((el) => ({
    //     ...el,
    //     lat: el.x,
    //     lon: el.y,
    //   }));
    // let mapDataCrime =
    //   crime &&
    //   crime.map((el) => ({
    //     ...el,
    //     lat: el.y,
    //     lon: el.x,
    //   }));

    let { isMobile } = this.props;
    return (
      <div>
        <Container>
          <Row>
            <Col span={isMobile ? 24 : 12}>
              <Title>{t("mainPage.trafficAccidentsByDistrict")}</Title>
              <Col span={8}>
                {statsDtp.map((stat) => (
                  <RowStat
                    key={stat.name}
                    label={stat.name}
                    count={stat.value}
                  />
                ))}
              </Col>
              <LoadingSpinner
                loading={loading}
                className="flex-full flex layout-centered"
              >
                <HorizontalBarChart
                  advancedScroll
                  advancedScrollEnd={0.5}
                  id="rayonchart"
                  data={rayons}
                  height={CHART_HEIGHT}
                  noCellSize
                  responsive={true}
                  color="#34C792"
                />
              </LoadingSpinner>
            </Col>

            <Col span={isMobile ? 24 : 12}>
              <Title>{t("mainPage.crimeCategory")}</Title>
              <Col span={8}>
                {dtpItems.map((stat) => (
                  <RowStat
                    key={stat.name}
                    label={stat.name}
                    count={stat.value}
                  />
                ))}
              </Col>
              <LoadingSpinner loading={loading}>
                <HorizontalBarChart
                  data={crimeByCategoryValues}
                  noCellSize
                  id="categoryChart"
                  color="#F2C80F"
                  height="40vh"
                  //height={chartHeight}
                  // handleClick={(category) => {
                  //   this.showModal("category", category);
                  // }}
                  advancedScroll
                  advancedScrollStart={0.5}
                  advancedScrollEnd={1}
                  key={crime.length}
                />
              </LoadingSpinner>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col span={isMobile ? 24 : 12}>
              <Title>{t("mainPage.schoolPopulationStructure")}</Title>
              <Row span={12}>
                <Row span={isMobile ? 24 : 12}>
                  {schoolItems.map((item) => (
                    <DdoItem>
                      <div>{item.title}</div>
                      <OrangeBox>{item.value}</OrangeBox>
                    </DdoItem>
                  ))}
                </Row>
              </Row>
              <LoadingSpinner
                loading={loading}
                className="flex-full flex layout-centered"
              >
                <PieChartWrap
                  responsive
                  key={mappedFilteredPieData}
                  height="43vh"
                  hideLabels
                  colorsArr={["#01b8aa", "#fbc02d", "#fd625e"]}
                  showLegend
                  handleClick={(category) => {
                    this.showModal("status", category);
                  }}
                  data={mappedFilteredPieData}
                  id="schoolPie1"
                  disabledLegend
                />
              </LoadingSpinner>
            </Col>
            <Col span={isMobile ? 24 : 12}>
              <Title>{t("mainPage.numberOfVisitsToTheEmergencyRoom")}</Title>
              <Col span={12}>
                {stats.map((stat) => (
                  <RowStat
                    key={stat.name}
                    label={stat.name}
                    count={stat.value}
                  />
                ))}
              </Col>
              <HorizontalBarChart
                responsive
                loading={!this.state.isDataLoaded}
                data={this.state.chart1}
                id="chartID1"
                values={[{ title: "Количество обращений", key: "value" }]}
                height="47vh"
              />
            </Col>
          </Row>
        </Container>

        <Container>
          <Col span={isMobile ? 24 : 24}>
            <h3 align="center">{t("mainPage.fullnessStructure")}</h3>
            <LoadingSpinner
              loading={loading}
              className="flex-full flex layout-centered"
            >
              <PieChartWrap
                responsive
                key={mappedPieData}
                height="450px"
                hideLabels
                colorsArr={["#01b8aa", "#fbc02d", "#fd625e"]}
                showLegend
                handleClick={(category) => {
                  this.showModal("status", category);
                }}
                data={mappedPieData}
                id="preSchoolPie1"
              />
            </LoadingSpinner>
          </Col>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
