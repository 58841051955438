import React, { Component } from "react";
import { Row, Col, Checkbox } from "antd";
import requestAdmin from "./../../utils/requestAdmin";
import FilterSelect from "./../../components/FilterSelectForRoad";
import RangeDateFilter from "./../../components/RangeDateFilterForRoad";
import { withTranslation } from "react-i18next";
class RoadFilters extends Component {
  state = {
    eventnames: [],
    workcategories: [],
    contractors: [],
  };

  componentDidMount() {
    requestAdmin("/api/v1/roadway/eventname/all", {
      method: "POST",
    }).then((r) => {
      let collection = r.map((el) => ({ label: el.nameRu, value: el.nameRu }));
      this.setState({
        eventnames: collection,
      });
    });
    requestAdmin("/api/v1/roadway/workcategory/all", {
      method: "POST",
    }).then((r) => {
      let collection = r.map((el) => ({ label: el.nameRu, value: el.nameRu }));
      this.setState({
        workcategories: collection,
      });
    });
    requestAdmin("/api/v1/roadway/contractor/all", {
      method: "POST",
    }).then((r) => {
      let collection = r.map((el) => ({ label: el.nameRu, value: el.nameRu }));
      this.setState({
        contractors: collection,
      });
    });
  }

  handleClick = (filter) => {
    this.props.changeFilter(filter, "add");
  };

  handleClear = (filter) => {
    this.props.changeFilter(filter, "remove");
  };

  render() {
    let { isMobile, t } = this.props;
    return (
      <>
        <Row gutter={16} style={{ marginBottom: "10px" }}>
          <Col span={isMobile ? 24 : 4}>
            <FilterSelect
              options={this.state.eventnames}
              filterField="eventName"
              handleChange={this.handleClick}
              handleClear={this.handleClear}
              placeholder={t(
                "infrastructure.repairAndConstruction.responsible"
              )}
              allowClear
            />
          </Col>
          <Col span={isMobile ? 24 : 5}>
            <FilterSelect
              options={this.state.workcategories}
              filterField="workCategory"
              defaultValue={"Средний ремонт дорог"}
              handleChange={this.handleClick}
              handleClear={this.handleClear}
              placeholder={t("infrastructure.repairAndConstruction.category")}
              allowClear
            />
          </Col>
          <Col span={isMobile ? 24 : 6}>
            <FilterSelect
              options={this.state.contractors}
              filterField="builder"
              handleChange={this.handleClick}
              handleClear={this.handleClear}
              placeholder={t("infrastructure.repairAndConstruction.contractor")}
              allowClear
            />
          </Col>
          <Col span={isMobile ? 24 : 6}>
            <RangeDateFilter
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              setDate={this.props.setDate}
              dateFormat={this.props.dateFormat}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={isMobile ? 24 : 3}>
            <Checkbox
              onChange={this.props.changeShowCross}
              style={{ color: "#fff" }}
            >
              {t("infrastructure.repairAndConstruction.showIntersections")}
            </Checkbox>
          </Col>
        </Row>
      </>
    );
  }
}

export default withTranslation()(RoadFilters);
