import moment from "moment";
export const COLORS = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

export const rangeList = {
  Сегодня: moment().utc(6).startOf("day"),
  "Три дня": moment().subtract(2, "d").utc(6).startOf("day"),
  Неделя: moment().subtract(6, "d").utc(6).startOf("day"),
  "Текущий месяц": moment().startOf("month").utc(6).startOf("day"),
  Месяц: moment().subtract(1, "months").startOf("day"),
  Год: moment().subtract(1, "y").startOf("day"),
};

export const dateGroups = [
  {
    label: "День",
    granularity: "day",
  },
  {
    label: "Неделя",
    granularity: "week",
  },
  {
    label: "Месяц",
    granularity: "month",
  },
  {
    label: "Год",
    granularity: "year",
  },
];

export const DepartmentLists = [
  {
    code: "EmergencyDepartmentView",
    dimension: [
      "EmergencyDepartmentView.category",
      "EmergencyDepartmentView.comment",
      "EmergencyDepartmentView.subCategory",
      "EmergencyDepartmentView.dateTime",
    ],
    order: {
      "EmergencyDepartmentView.dateTime": "desc",
    },
    title: "112 - ДЧС",
  },
  {
    code: "PoliceView",
    dimension: [
      "PoliceView.category",
      "PoliceView.comment",
      "PoliceView.dateTime",
    ],
    order: {
      "PoliceView.dateTime": "desc",
    },
    title: "102 - Департамент полиции",
    filters: [
      {
        member: "PoliceView.dateTime",
        operator: "inDateRange",
        // values: range
      },
    ],
  },
  {
    code: "AmbulanceView",
    dimension: [
      "AmbulanceView.comment",
      "AmbulanceView.category",
      "AmbulanceView.subcategory",
      "AmbulanceView.dateTime",
    ],
    order: {
      "AmbulanceView.dateTime": "desc",
    },
    title: "103 - Скорая помощь",
    filters: [
      {
        member: "AmbulanceView.dateTime",
        operator: "inDateRange",
        // values: "range"
      },
    ],
  },
];

export const AbayPolygon = [
  [
    [71.647278, 49.244099999999996],
    [71.646275, 49.331627],
    [71.670591, 49.373793],
    [71.71659199999999, 49.506009],
    [71.590796, 49.513833999999996],
    [71.575439, 49.742276999999994],
    [71.651197, 49.735174],
    [71.676908, 49.73017],
    [71.695708, 49.71633],
    [71.729604, 49.709767],
    [71.74237099999999, 49.697078999999995],
    [71.77097599999999, 49.697012],
    [71.80075599999999, 49.683704],
    [71.809998, 49.680706],
    [71.81938, 49.681633],
    [71.832927, 49.676066999999996],
    [71.848879, 49.676176],
    [71.869536, 49.669779],
    [71.887215, 49.671997999999995],
    [71.905282, 49.669922],
    [71.919128, 49.672556],
    [71.955258, 49.668392],
    [71.990314, 49.678878],
    [72.024315, 49.703658999999995],
    [72.032594, 49.717802999999996],
    [72.029021, 49.738223],
    [72.043054, 49.738558999999995],
    [72.062861, 49.754999999999995],
    [72.139575, 49.785379],
    [72.173565, 49.815774],
    [72.232388, 49.833282999999994],
    [72.252695, 49.833625999999995],
    [72.26481799999999, 49.827555],
    [72.31819, 49.836003999999996],
    [72.332509, 49.84083],
    [72.340521, 49.819506],
    [72.339131, 49.802018999999994],
    [72.362731, 49.800816999999995],
    [72.36806, 49.759111],
    [72.474414, 49.759344],
    [72.4797, 49.748208],
    [72.481036, 49.710336],
    [72.497157, 49.706770999999996],
    [72.49896, 49.702068],
    [72.529859, 49.690709999999996],
    [72.553389, 49.668582],
    [72.65173899999999, 49.667623999999996],
    [72.660472, 49.666232],
    [72.660129, 49.660326],
    [72.66669499999999, 49.657261999999996],
    [72.668459, 49.659425999999996],
    [72.663803, 49.662351],
    [72.67045499999999, 49.662824],
    [72.676064, 49.668926],
    [72.68404699999999, 49.668758999999994],
    [72.684218, 49.671572],
    [72.688531, 49.671682999999994],
    [72.688338, 49.675847],
    [72.69385299999999, 49.677198],
    [72.692329, 49.682168],
    [72.701041, 49.685009],
    [72.71355299999999, 49.684895],
    [72.71493199999999, 49.681134],
    [72.718631, 49.681097],
    [72.72184399999999, 49.690647999999996],
    [72.729908, 49.692648],
    [72.72831599999999, 49.697151],
    [72.747886, 49.702718],
    [72.74543899999999, 49.704806],
    [72.748358, 49.705335],
    [72.705693, 49.733365],
    [72.702108, 49.749043],
    [72.704374, 49.760287999999996],
    [72.777106, 49.761806],
    [72.785088, 49.749234],
    [72.814185, 49.736498],
    [72.817251, 49.691542],
    [72.831155, 49.659459999999996],
    [72.844174, 49.651348],
    [72.834975, 49.635404],
    [72.84343, 49.633063],
    [72.869908, 49.606575],
    [72.877657, 49.609866],
    [72.902407, 49.590939999999996],
    [72.91478699999999, 49.601216],
    [72.931116, 49.602267999999995],
    [72.932199, 49.573048],
    [72.937754, 49.562692],
    [73.009654, 49.565245999999995],
    [73.013425, 49.553472],
    [73.14009899999999, 49.553793999999996],
    [73.16967799999999, 49.56066],
    [73.159953, 49.628065],
    [73.267051, 49.634978],
    [73.277236, 49.568279999999994],
    [73.32440199999999, 49.535931],
    [73.358957, 49.516698999999996],
    [73.344539, 49.502151],
    [73.38525299999999, 49.499885],
    [73.399568, 49.504577999999995],
    [73.447368, 49.55609],
    [73.463105, 49.564749],
    [73.516863, 49.571901],
    [73.560451, 49.585543],
    [73.601241, 49.591226999999996],
    [73.685025, 49.616713],
    [73.68377699999999, 49.599965],
    [73.691734, 49.571251],
    [73.70139999999999, 49.497962],
    [73.712356, 49.464771],
    [73.759976, 49.421282],
    [73.77496599999999, 49.397808],
    [73.77280499999999, 49.384011],
    [73.75077399999999, 49.402967],
    [73.66534399999999, 49.380865],
    [73.66014, 49.370810999999996],
    [73.674399, 49.352117],
    [73.629114, 49.327988999999995],
    [73.625569, 49.309853],
    [73.559524, 49.290718999999996],
    [73.367029, 49.279635999999996],
    [73.385876, 49.311205],
    [73.380606, 49.315737],
    [73.40487399999999, 49.361011999999995],
    [73.238328, 49.375229],
    [73.238218, 49.356257],
    [73.19276099999999, 49.343638999999996],
    [72.92357, 49.33972],
    [72.908873, 49.336437],
    [72.909819, 49.333493999999995],
    [72.855052, 49.312216],
    [72.806248, 49.317043],
    [72.76407, 49.300528],
    [72.518524, 49.295832999999995],
    [72.165502, 49.279500999999996],
    [72.16226999999999, 49.177381],
    [71.701891, 49.165102999999995],
    [71.66383499999999, 49.166357],
    [71.634765, 49.176404999999995],
    [71.631126, 49.181813],
  ],
];

export const AktogayPolygon = [
  [
    [73.572741, 45.993922999999995],
    [73.5837, 46.062217],
    [73.608687, 46.096736],
    [73.59423, 46.377990999999994],
    [73.603645, 46.409822],
    [73.621326, 46.422416999999996],
    [73.458142, 47.041267999999995],
    [73.416478, 47.143226999999996],
    [73.343841, 47.248979],
    [73.29832499999999, 47.293337],
    [73.42659499999999, 47.287465],
    [73.519401, 47.29712],
    [73.726733, 47.334492],
    [73.822655, 47.325551],
    [73.881875, 47.328706],
    [73.85815099999999, 47.266718],
    [74.018496, 47.232836999999996],
    [74.073808, 47.256465999999996],
    [74.09787399999999, 47.291388999999995],
    [74.160591, 47.340261],
    [74.170951, 47.363248999999996],
    [74.349136, 47.363299],
    [74.429383, 47.347133],
    [74.600304, 47.350716],
    [74.607601, 47.523416999999995],
    [74.576455, 47.549465],
    [74.600684, 47.580624],
    [74.59505399999999, 47.590336],
    [74.572865, 47.595591],
    [74.534025, 47.683971],
    [74.466978, 47.747149],
    [74.457408, 47.787661],
    [74.413084, 47.814167999999995],
    [74.410411, 47.898939],
    [74.38718999999999, 47.932663],
    [74.38271499999999, 47.983194999999995],
    [74.387239, 47.998352],
    [74.410674, 48.018634],
    [74.40542599999999, 48.059706999999996],
    [74.427852, 48.067614],
    [74.378599, 48.116192999999996],
    [74.442258, 48.186039],
    [74.422962, 48.202832],
    [74.471969, 48.290735],
    [74.456245, 48.362139],
    [74.450451, 48.371119],
    [74.348345, 48.419503999999996],
    [74.3384, 48.431554],
    [74.333507, 48.47772],
    [74.22542299999999, 48.483883],
    [74.18953599999999, 48.501238],
    [74.167621, 48.583040999999994],
    [74.15633, 48.592934],
    [74.11940799999999, 48.598625],
    [74.10292799999999, 48.64008],
    [73.995496, 48.645272999999996],
    [73.985186, 48.696717],
    [74.212543, 48.701854999999995],
    [74.321111, 48.721962],
    [74.310628, 48.754466],
    [74.315536, 48.786398999999996],
    [74.386833, 48.858182],
    [74.435313, 48.83144],
    [74.48089499999999, 48.788812],
    [74.535158, 48.77429],
    [74.6197, 48.766304999999996],
    [74.66806199999999, 48.730737],
    [74.712177, 48.768285999999996],
    [74.6999, 48.914713],
    [74.80378499999999, 48.918228],
    [74.82383899999999, 48.88169],
    [74.88365, 48.867807],
    [74.964406, 48.900090999999996],
    [75.005845, 48.85122],
    [75.04244899999999, 48.856882],
    [75.061336, 48.849849999999996],
    [75.073495, 48.861739],
    [75.089983, 48.862052999999996],
    [75.22505199999999, 48.811546],
    [75.244525, 48.765127],
    [75.262377, 48.763801],
    [75.35122, 48.827529999999996],
    [75.38537199999999, 48.780409],
    [75.493025, 48.784673999999995],
    [75.54670899999999, 48.760382],
    [75.55794, 48.762949],
    [75.65386, 48.711214999999996],
    [75.709925, 48.7095],
    [75.736503, 48.695767],
    [75.780309, 48.689448999999996],
    [75.91805099999999, 48.705262],
    [75.93808299999999, 48.642649999999996],
    [75.94762, 48.630775],
    [75.96748099999999, 48.627165],
    [76.139791, 48.652308],
    [76.19404999999999, 48.655626],
    [76.224627, 48.650361],
    [76.312586, 48.66733],
    [76.374522, 48.575948],
    [76.404511, 48.566527],
    [76.50824899999999, 48.561249],
    [76.526741, 48.504827999999996],
    [76.591241, 48.498059],
    [76.667834, 48.518507],
    [76.703497, 48.493669],
    [76.777619, 48.471743],
    [76.85752699999999, 48.493421],
    [76.90085599999999, 48.478544],
    [76.895192, 48.427676999999996],
    [76.874119, 48.41741],
    [76.82024, 48.4098],
    [76.823944, 48.382141],
    [76.917574, 48.336566],
    [76.97484899999999, 48.291104999999995],
    [77.011504, 48.283679],
    [77.14917299999999, 48.280176999999995],
    [77.147494, 48.248742],
    [77.055324, 48.248669],
    [77.04184699999999, 48.204836],
    [77.056067, 48.179874],
    [77.152546, 48.107624],
    [77.21006, 48.110341999999996],
    [77.280817, 48.102505],
    [77.332803, 48.076046999999996],
    [77.178888, 48.029844999999995],
    [77.19673999999999, 47.972361],
    [77.142206, 47.952811999999994],
    [77.132644, 47.874081],
    [77.11174, 47.863862],
    [77.04853299999999, 47.870596],
    [77.04527399999999, 47.836701],
    [77.005833, 47.723327],
    [76.985321, 47.708267],
    [76.971841, 47.63907],
    [77.021816, 47.58251],
    [77.049606, 47.450465],
    [77.06810399999999, 47.40992],
    [76.98137899999999, 47.43167],
    [76.874676, 47.431042999999995],
    [76.858419, 47.325528999999996],
    [76.84329, 47.285244],
    [76.78669099999999, 47.255804],
    [76.823031, 47.242391],
    [76.902808, 47.186754],
    [77.14576699999999, 47.09084],
    [77.180996, 47.092977999999995],
    [77.240995, 47.187511],
    [77.292141, 47.191232],
    [77.39472599999999, 47.161179999999995],
    [77.46621999999999, 47.159929],
    [77.554949, 46.665496],
    [77.55796099999999, 46.635242],
    [77.54986699999999, 46.625402],
    [77.533884, 46.628854999999994],
    [77.526963, 46.621235999999996],
    [77.518547, 46.634423],
    [77.47884599999999, 46.641366999999995],
    [77.443642, 46.625308999999994],
    [77.37687199999999, 46.613997999999995],
    [77.306293, 46.618552],
    [77.24509499999999, 46.582321],
    [77.2421, 46.549453],
    [77.16924999999999, 46.54662],
    [77.15689499999999, 46.534698999999996],
    [77.109102, 46.548874999999995],
    [77.11117, 46.554631],
    [77.129555, 46.547931],
    [77.141852, 46.558440999999995],
    [77.106462, 46.586514],
    [77.09029, 46.619172],
    [77.056623, 46.640273],
    [76.992243, 46.653141999999995],
    [76.96753799999999, 46.639862],
    [76.966714, 46.622777],
    [76.953361, 46.622299999999996],
    [76.95089399999999, 46.631217],
    [76.937941, 46.624502],
    [76.94201, 46.618829],
    [76.927196, 46.625454],
    [76.93721, 46.632501],
    [76.91375699999999, 46.630793999999995],
    [76.884028, 46.641587],
    [76.88147099999999, 46.65585],
    [76.858248, 46.661063999999996],
    [76.862197, 46.672349999999994],
    [76.838285, 46.690531],
    [76.82278199999999, 46.689727999999995],
    [76.812007, 46.678059999999995],
    [76.79335999999999, 46.692854999999994],
    [76.788637, 46.682421],
    [76.763857, 46.687359],
    [76.748989, 46.680932],
    [76.807127, 46.675194999999995],
    [76.81106799999999, 46.649981],
    [76.82027599999999, 46.651516],
    [76.815518, 46.658317],
    [76.828592, 46.653766],
    [76.839016, 46.625085999999996],
    [76.861487, 46.625001999999995],
    [76.845486, 46.609007999999996],
    [76.88516, 46.609761999999996],
    [76.882288, 46.603153999999996],
    [76.933454, 46.558443],
    [76.933576, 46.550455],
    [76.901459, 46.558419],
    [76.890497, 46.573243],
    [76.862798, 46.57727],
    [76.83599099999999, 46.59578],
    [76.701118, 46.631423],
    [76.69301899999999, 46.645694999999996],
    [76.70409, 46.645109999999995],
    [76.703903, 46.652119],
    [76.730389, 46.649969],
    [76.712589, 46.659766],
    [76.72071799999999, 46.669031],
    [76.702793, 46.658860999999995],
    [76.66881699999999, 46.662515],
    [76.670489, 46.654013],
    [76.683554, 46.655363],
    [76.678388, 46.649553999999995],
    [76.612152, 46.673275],
    [76.581812, 46.676431],
    [76.55121799999999, 46.652834999999996],
    [76.532364, 46.649055],
    [76.528717, 46.654751999999995],
    [76.516497, 46.64725],
    [76.518973, 46.620593],
    [76.533637, 46.600832],
    [76.507408, 46.6153],
    [76.506765, 46.623779],
    [76.43581, 46.65293],
    [76.400227, 46.658817],
    [76.404201, 46.642633],
    [76.418776, 46.640032999999995],
    [76.40190199999999, 46.632979999999996],
    [76.372626, 46.691019999999995],
    [76.384123, 46.705],
    [76.307321, 46.644991999999995],
    [76.217767, 46.660906],
    [76.214418, 46.684425999999995],
    [76.115949, 46.714205],
    [76.028964, 46.731441],
    [75.67894199999999, 46.696677],
    [75.595264, 46.693703],
    [75.512625, 46.703486999999996],
    [75.420007, 46.691196999999995],
    [75.351732, 46.676884],
    [74.999329, 46.543709],
    [74.747922, 46.524066],
    [74.428857, 46.320772],
    [74.164975, 46.122428],
    [74.083376, 46.038984],
    [74.058269, 45.995236],
  ],
  [
    [74.924678, 46.830760999999995],
    [74.919614, 46.819567],
    [74.9403, 46.814558999999996],
    [74.953947, 46.826961],
    [74.978923, 46.8335],
    [75.026302, 46.829375999999996],
    [75.018491, 46.865474999999996],
    [74.999179, 46.871126],
    [74.913746, 46.868947999999996],
  ],
  [
    [74.976319, 46.965576],
    [74.99061499999999, 46.957234],
    [75.003528, 46.966789999999996],
    [74.987233, 46.970115],
  ],
];

export const BukharPolygon = [
  [
    [72.004479, 49.92605],
    [72.212321, 49.939116999999996],
    [72.271442, 49.983968],
    [72.299947, 49.964804],
    [72.311908, 49.978801],
    [72.360491, 50.001554],
    [72.358261, 50.014005],
    [72.30793899999999, 50.062804],
    [72.294337, 50.086787],
    [72.385572, 50.121826],
    [72.474424, 50.140788],
    [72.484245, 50.233259999999994],
    [72.490296, 50.240431],
    [72.711499, 50.262169],
    [72.73625899999999, 50.277649],
    [72.798165, 50.295528],
    [72.807195, 50.294219],
    [72.822521, 50.264295],
    [72.92360699999999, 50.261818999999996],
    [72.971301, 50.288351999999996],
    [73.14550899999999, 50.289645],
    [73.157733, 50.259032],
    [73.16381, 50.256313],
    [73.185605, 50.267764],
    [73.254083, 50.254205999999996],
    [73.26772799999999, 50.262583],
    [73.277405, 50.279463],
    [73.305964, 50.272822],
    [73.31977499999999, 50.283376999999994],
    [73.323915, 50.252609],
    [73.333109, 50.245779999999996],
    [73.41008599999999, 50.273119],
    [73.4346, 50.273862],
    [73.464398, 50.268969999999996],
    [73.477266, 50.242317],
    [73.478303, 50.225136],
    [73.374044, 50.164331999999995],
    [73.36699899999999, 50.136438],
    [73.365504, 50.091235],
    [73.37705199999999, 50.086158999999995],
    [73.41227599999999, 50.086594999999996],
    [73.42062899999999, 50.092555],
    [73.469732, 50.097677],
    [73.479067, 50.101414],
    [73.49291, 50.128367999999995],
    [73.49115499999999, 50.143381999999995],
    [73.49698599999999, 50.153425],
    [73.532859, 50.162929],
    [73.549584, 50.228469],
    [73.539952, 50.28313],
    [73.577745, 50.317009999999996],
    [73.634306, 50.35723],
    [73.639003, 50.366571],
    [73.62971499999999, 50.432176],
    [73.67593, 50.528524],
    [73.718289, 50.536308],
    [73.72778699999999, 50.555346],
    [73.84897099999999, 50.553611],
    [73.87088399999999, 50.541577],
    [73.904901, 50.551759],
    [74.016724, 50.554539999999996],
    [74.035988, 50.558986],
    [74.03711, 50.606201999999996],
    [74.26582599999999, 50.617768999999996],
    [74.285427, 50.611115],
    [74.37377599999999, 50.63406],
    [74.387857, 50.643428],
    [74.398804, 50.660826],
    [74.45045999999999, 50.664311],
    [74.536154, 50.633309],
    [74.586389, 50.630357],
    [74.63178599999999, 50.609764],
    [74.63198799999999, 50.587987],
    [74.59727199999999, 50.550273999999995],
    [74.61200199999999, 50.502196],
    [74.653218, 50.463947],
    [74.70207599999999, 50.480295],
    [74.728166, 50.477756],
    [74.733496, 50.523553],
    [74.771457, 50.530912],
    [74.841917, 50.461214999999996],
    [74.872945, 50.446563999999995],
    [74.883256, 50.456522],
    [74.906947, 50.460969999999996],
    [74.961602, 50.383449],
    [74.977201, 50.340232],
    [74.95926, 50.331101999999994],
    [75.02944699999999, 50.293457],
    [75.090764, 50.27496],
    [75.135041, 50.256192],
    [75.141132, 50.241079],
    [75.105238, 50.208023],
    [75.073629, 50.200499],
    [75.022818, 50.200724],
    [74.98069699999999, 50.180761999999994],
    [74.948605, 50.183119999999995],
    [74.808268, 50.103899],
    [74.853684, 50.035714999999996],
    [74.917684, 50.03105],
    [74.942739, 50.007923999999996],
    [75.029969, 50.021266999999995],
    [75.037995, 49.990387999999996],
    [75.031604, 49.982901999999996],
    [75.009517, 49.978679],
    [74.97664, 49.963010999999995],
    [74.806178, 49.949472],
    [74.81877399999999, 49.939118],
    [74.804786, 49.927277],
    [74.754758, 49.907078],
    [74.68265199999999, 49.881538],
    [74.627098, 49.886344],
    [74.563249, 49.862471],
    [74.58627899999999, 49.835176],
    [74.57360899999999, 49.827912],
    [74.628157, 49.745549999999994],
    [74.542473, 49.742196],
    [74.485832, 49.733523999999996],
    [74.446088, 49.717453],
    [74.420648, 49.736014],
    [74.412962, 49.757003999999995],
    [74.35454399999999, 49.773171999999995],
    [74.33814, 49.804314],
    [74.30427999999999, 49.837132999999994],
    [74.28216499999999, 49.849331],
    [74.302655, 49.864101],
    [74.29404, 49.875284],
    [74.251237, 49.870643],
    [74.216551, 49.876559],
    [74.207775, 49.867562],
    [74.17353899999999, 49.850680999999994],
    [74.169252, 49.837897999999996],
    [74.154074, 49.828607],
    [74.12716999999999, 49.822317999999996],
    [74.108004, 49.822477],
    [74.09366, 49.727253999999995],
    [74.080501, 49.549195999999995],
    [73.92562699999999, 49.459817],
    [73.94041299999999, 49.426201999999996],
    [73.88476899999999, 49.4161],
    [73.77280499999999, 49.384011],
    [73.77496599999999, 49.397808],
    [73.759976, 49.421282],
    [73.712356, 49.464771],
    [73.70139999999999, 49.497962],
    [73.685025, 49.616713],
    [73.601241, 49.591226999999996],
    [73.560451, 49.585543],
    [73.516863, 49.571901],
    [73.463105, 49.564749],
    [73.447368, 49.55609],
    [73.399568, 49.504577999999995],
    [73.38525299999999, 49.499885],
    [73.344539, 49.502151],
    [73.358957, 49.516698999999996],
    [73.277236, 49.568279999999994],
    [73.267051, 49.634978],
    [73.159953, 49.628065],
    [73.16967799999999, 49.56066],
    [73.14009899999999, 49.553793999999996],
    [73.013425, 49.553472],
    [73.009654, 49.565245999999995],
    [72.937754, 49.562692],
    [72.932199, 49.573048],
    [72.931116, 49.602267999999995],
    [72.91478699999999, 49.601216],
    [72.902407, 49.590939999999996],
    [72.865861, 49.619339],
    [72.868135, 49.625598],
    [72.878757, 49.622991],
    [72.885044, 49.624427],
    [72.882592, 49.629382],
    [72.89302099999999, 49.629842],
    [72.893428, 49.638135],
    [72.864868, 49.643885999999995],
    [72.863873, 49.648649999999996],
    [72.844174, 49.651348],
    [72.831155, 49.659459999999996],
    [72.817251, 49.691542],
    [72.814185, 49.736498],
    [72.785088, 49.749234],
    [72.777106, 49.761806],
    [72.748545, 49.760977],
    [72.740749, 49.777941],
    [72.74632299999999, 49.792231],
    [72.74055, 49.796502],
    [72.723642, 49.796433],
    [72.723209, 49.802875],
    [72.676945, 49.800667999999995],
    [72.665058, 49.836431999999995],
    [72.64944, 49.843581],
    [72.647144, 49.838349],
    [72.606672, 49.84634],
    [72.586652, 49.842316],
    [72.60842099999999, 49.806638],
    [72.626938, 49.793333999999994],
    [72.565896, 49.790099999999995],
    [72.561298, 49.782334999999996],
    [72.572319, 49.762813],
    [72.36806, 49.759111],
    [72.362731, 49.800816999999995],
    [72.339131, 49.802018999999994],
    [72.340521, 49.819506],
    [72.332509, 49.84083],
    [72.26481799999999, 49.827555],
    [72.252695, 49.833625999999995],
    [72.232388, 49.833282999999994],
    [72.19134, 49.822742999999996],
    [72.173565, 49.815774],
    [72.139575, 49.785379],
    [72.062861, 49.754999999999995],
    [72.043054, 49.738558999999995],
    [71.99812399999999, 49.740424],
    [71.975855, 49.760503],
    [71.968529, 49.774491999999995],
    [72.006289, 49.859243],
    [71.993453, 49.904708],
    [72.00377, 49.908546],
  ],
  [
    [72.788221, 49.82664],
    [72.78809799999999, 49.821349999999995],
    [72.81942, 49.809363999999995],
    [72.80171299999999, 49.794824],
    [72.81677599999999, 49.779382999999996],
    [72.80075, 49.767835],
    [72.81565499999999, 49.745129999999996],
    [72.826723, 49.738516999999995],
    [72.90955799999999, 49.752739999999996],
    [72.920324, 49.762513999999996],
    [72.915347, 49.77251],
    [72.880736, 49.799265],
    [72.870955, 49.801154],
    [72.859732, 49.814802],
    [72.829179, 49.827836999999995],
    [72.843402, 49.845462],
    [72.819191, 49.852247999999996],
    [72.805088, 49.841055],
    [72.790712, 49.840668],
    [72.784179, 49.833321],
  ],
  [
    [72.879161, 49.802254999999995],
    [72.89018999999999, 49.804713],
    [72.898172, 49.813351],
    [72.881819, 49.806945999999996],
  ],
  [
    [72.886333, 50.102346],
    [72.87430599999999, 50.081694999999996],
    [72.90026999999999, 50.070702999999995],
    [72.89786699999999, 50.063548],
    [72.891429, 50.064349],
    [72.885893, 50.059211],
    [72.88811, 50.054809],
    [72.901654, 50.050028],
    [72.915043, 50.034965],
    [72.930106, 50.041045999999994],
    [72.95685499999999, 50.040465],
    [72.979376, 50.018195],
    [73.007963, 50.031116],
    [73.021272, 50.025099],
    [73.051593, 50.040727],
    [73.062226, 50.039272],
    [73.08766899999999, 50.048105],
    [73.097571, 50.062163999999996],
    [73.090479, 50.070308999999995],
    [73.069942, 50.066046],
    [73.06097, 50.078016],
    [73.00715199999999, 50.070661],
    [72.974052, 50.072603],
    [72.94976, 50.066154],
    [72.93100199999999, 50.070896999999995],
    [72.926586, 50.076491],
    [72.932491, 50.084807],
    [72.917653, 50.102199],
    [72.921206, 50.115555],
    [72.944228, 50.132143],
    [72.94723499999999, 50.189952],
    [72.84567799999999, 50.130174],
    [72.858081, 50.124691999999996],
    [72.855136, 50.119394],
    [72.86655999999999, 50.114821],
    [72.86931899999999, 50.105784],
  ],
  [
    [73.020738, 49.707412999999995],
    [73.056049, 49.699791],
    [73.215598, 49.698437],
    [73.259799, 49.769065],
    [73.222459, 49.814454999999995],
    [73.21879899999999, 49.830836],
    [73.228797, 49.85492],
    [73.219235, 49.858979999999995],
    [73.22575499999999, 49.874801999999995],
    [73.243578, 49.879703],
    [73.254037, 49.889328],
    [73.267574, 49.955154],
    [73.278402, 49.962914999999995],
    [73.242875, 49.983216999999996],
    [73.24569699999999, 49.991926],
    [73.219613, 49.989171999999996],
    [73.193512, 49.971253999999995],
    [73.110084, 49.951986],
    [73.10587799999999, 49.953758],
    [73.092832, 49.943844],
    [73.073349, 49.943512],
    [73.067984, 49.935894999999995],
    [73.064379, 49.937833999999995],
    [73.05948699999999, 49.934371999999996],
    [73.054509, 49.939246999999995],
    [73.02635599999999, 49.925396],
    [73.028086, 49.899646],
    [72.87145799999999, 49.908801],
    [72.858166, 49.893800999999996],
    [72.838563, 49.862930999999996],
    [72.847236, 49.849270999999995],
    [72.86997, 49.84037],
    [72.897587, 49.816289999999995],
    [72.929161, 49.759225],
    [72.928375, 49.736588],
    [72.940643, 49.725088],
    [72.937825, 49.710986],
    [73.006115, 49.710569],
  ],
  [
    [73.08358299999999, 50.222592],
    [73.067317, 50.249261],
    [73.029037, 50.247609],
    [73.02311399999999, 50.240784],
    [73.023973, 50.23038],
    [73.033157, 50.215236],
    [73.039851, 50.216668],
    [73.036676, 50.193751999999996],
    [73.047748, 50.187194999999996],
    [73.076844, 50.188738],
    [73.097959, 50.202953],
  ],
];

export const ZhanarkaPolygon = [
  [
    [68.996352, 46.003068],
    [68.810327, 46.330276],
    [68.529605, 46.767295],
    [68.535063, 46.781738],
    [68.902464, 47.177198999999995],
    [68.97457899999999, 47.220569],
    [69.10743, 47.263974],
    [69.11509699999999, 47.277058],
    [69.13916499999999, 47.331579999999995],
    [68.968791, 47.650600999999995],
    [68.969527, 47.678442],
    [69.042923, 47.794944],
    [69.15903399999999, 47.928796999999996],
    [69.33803499999999, 47.917505],
    [69.47787699999999, 47.939854],
    [69.80340199999999, 47.972482],
    [69.809602, 48.064955999999995],
    [69.86026799999999, 48.107247],
    [69.781041, 48.145679],
    [69.27967799999999, 48.588608],
    [69.159098, 48.678396],
    [69.145113, 48.792001],
    [69.150837, 48.797945],
    [69.31392, 48.694981],
    [69.42564399999999, 48.644929],
    [69.781848, 48.715888],
    [69.828351, 48.769394],
    [69.831592, 48.801711999999995],
    [69.881843, 48.795514999999995],
    [70.432001, 48.85874],
    [70.55780399999999, 49.143190999999995],
    [70.550061, 49.395391],
    [70.65926, 49.394861],
    [70.714045, 49.382891],
    [70.73332599999999, 49.386815],
    [70.76473, 49.374061],
    [70.77344599999999, 49.312537],
    [70.81863299999999, 49.263785999999996],
    [70.94673999999999, 49.226515],
    [71.000609, 49.179868],
    [71.218442, 49.196259],
    [71.647278, 49.244099999999996],
    [71.631126, 49.181813],
    [71.66383499999999, 49.166357],
    [71.934013, 49.171122],
    [71.95584099999999, 49.043763999999996],
    [72.01203199999999, 49.005596999999995],
    [71.979922, 48.977674],
    [71.985092, 48.968098],
    [72.0324, 48.959996],
    [72.055816, 48.936234999999996],
    [72.09779, 48.934037],
    [72.105102, 48.841941],
    [72.073955, 48.836653],
    [72.08695399999999, 48.802848],
    [72.11775399999999, 48.800111],
    [72.11073, 48.736005],
    [72.057587, 48.730374],
    [72.058083, 48.715036],
    [72.022643, 48.710539],
    [72.019386, 48.66388],
    [72.056117, 48.469088],
    [71.92778899999999, 48.378543],
    [71.836328, 48.329625],
    [71.86116299999999, 48.320479],
    [71.875281, 48.28668],
    [71.915628, 48.253917],
    [71.912346, 48.223310999999995],
    [71.96030499999999, 48.191871],
    [71.977837, 48.164570999999995],
    [71.980456, 48.147008],
    [71.923309, 48.012172],
    [71.929268, 47.966086],
    [71.967691, 47.888076999999996],
    [71.962299, 47.829037],
    [71.935174, 47.797360999999995],
    [71.88751599999999, 47.765276],
    [71.787427, 47.714985999999996],
    [71.76033, 47.678886999999996],
    [71.707926, 47.55627],
    [71.592991, 47.568802],
    [71.485087, 47.604493999999995],
    [71.417307, 47.653493999999995],
    [71.37589799999999, 47.65726],
    [71.379761, 47.575810999999995],
    [71.344961, 47.427994999999996],
    [71.318934, 47.372063],
    [71.33316099999999, 47.24633],
    [71.299966, 46.485133999999995],
    [71.33295799999999, 46.071476],
    [71.36706099999999, 45.994350999999995],
    [69.07985099999999, 45.998376],
  ],
];

export const KarkaraPolygon = [
  [
    [73.94041299999999, 49.426201999999996],
    [73.92562699999999, 49.459817],
    [74.080501, 49.549195999999995],
    [74.108004, 49.822477],
    [74.154074, 49.828607],
    [74.169252, 49.837897999999996],
    [74.17353899999999, 49.850680999999994],
    [74.216551, 49.876559],
    [74.251237, 49.870643],
    [74.29404, 49.875284],
    [74.302655, 49.864101],
    [74.28216499999999, 49.849331],
    [74.30427999999999, 49.837132999999994],
    [74.33814, 49.804314],
    [74.35454399999999, 49.773171999999995],
    [74.412962, 49.757003999999995],
    [74.420648, 49.736014],
    [74.446088, 49.717453],
    [74.485832, 49.733523999999996],
    [74.542473, 49.742196],
    [74.628157, 49.745549999999994],
    [74.57360899999999, 49.827912],
    [74.58627899999999, 49.835176],
    [74.563249, 49.862471],
    [74.627098, 49.886344],
    [74.68265199999999, 49.881538],
    [74.754758, 49.907078],
    [74.804786, 49.927277],
    [74.81877399999999, 49.939118],
    [74.806178, 49.949472],
    [74.97664, 49.963010999999995],
    [75.009517, 49.978679],
    [75.031604, 49.982901999999996],
    [75.037995, 49.990387999999996],
    [75.029969, 50.021266999999995],
    [75.100651, 50.077599],
    [75.134925, 50.082029],
    [75.227358, 50.112548],
    [75.278939, 50.136759],
    [75.33225399999999, 50.171122999999994],
    [75.46052499999999, 50.188922999999996],
    [75.496729, 50.182269],
    [75.590266, 50.178799],
    [75.65478399999999, 50.190525],
    [75.678885, 50.224979],
    [75.70164, 50.221886],
    [75.707877, 50.235434999999995],
    [75.735467, 50.234077],
    [75.74313099999999, 50.220833],
    [75.786603, 50.197893],
    [75.849745, 50.197700999999995],
    [75.93369, 50.216209],
    [75.994168, 50.243787],
    [76.040381, 50.243373],
    [76.054131, 50.254535],
    [76.110576, 50.2496],
    [76.225202, 50.205901],
    [76.233246, 50.232195],
    [76.276924, 50.259536999999995],
    [76.275959, 50.299181999999995],
    [76.32133, 50.30365],
    [76.322402, 50.323387999999994],
    [76.520555, 50.32629],
    [76.647356, 50.271584],
    [76.661547, 50.27374],
    [76.68804899999999, 50.303149999999995],
    [76.694463, 50.403752999999995],
    [76.70295999999999, 50.420092],
    [76.73248, 50.442637],
    [76.730098, 50.464059999999996],
    [76.750295, 50.491596],
    [76.754997, 50.540821],
    [76.778201, 50.583523],
    [76.82004599999999, 50.598895],
    [76.839874, 50.628419],
    [76.856582, 50.636344],
    [77.043072, 50.655881],
    [77.132499, 50.624682],
    [77.252397, 50.554879],
    [77.330086, 50.540096],
    [77.45015, 50.538391],
    [77.641841, 50.451651999999996],
    [77.647722, 50.353727],
    [77.643487, 50.332223],
    [77.60597899999999, 50.266863],
    [77.622958, 50.252379],
    [77.702101, 50.210479],
    [77.708528, 50.196132999999996],
    [77.683768, 50.167834],
    [77.534875, 50.078781],
    [77.474713, 50.049609],
    [77.45002099999999, 50.023674],
    [77.403696, 50.015315],
    [77.304523, 49.983892],
    [77.22658, 49.944489999999995],
    [77.209147, 49.953686999999995],
    [77.18339, 49.946982],
    [77.164035, 49.928366],
    [77.14580099999999, 49.892877999999996],
    [77.153398, 49.833932999999995],
    [77.139639, 49.787698],
    [77.140665, 49.772034],
    [77.17733799999999, 49.752482],
    [77.17402, 49.717389],
    [77.090024, 49.636686999999995],
    [77.028187, 49.589127],
    [76.95235199999999, 49.558039],
    [76.82295599999999, 49.525338999999995],
    [76.80455599999999, 49.523590999999996],
    [76.77046299999999, 49.53344],
    [76.59788999999999, 49.485915],
    [76.588724, 49.460276],
    [76.557779, 49.429122],
    [76.58534399999999, 49.382911],
    [76.633754, 49.328893],
    [76.69861, 49.300708],
    [76.763283, 49.248337],
    [76.820668, 49.166743999999994],
    [76.82569699999999, 49.147577],
    [76.918666, 49.133368999999995],
    [77.01821199999999, 49.074602999999996],
    [76.985061, 49.048328999999995],
    [76.95455299999999, 49.037814],
    [76.898083, 49.031375],
    [76.847877, 49.014227],
    [76.870088, 48.905018],
    [76.84966299999999, 48.856134999999995],
    [76.854145, 48.844193],
    [76.906938, 48.793808999999996],
    [76.92817, 48.804072999999995],
    [76.992955, 48.819179999999996],
    [77.287069, 48.852368999999996],
    [77.326246, 48.747121],
    [77.317458, 48.710685],
    [77.29462699999999, 48.665442],
    [77.302238, 48.629417],
    [77.159436, 48.495342],
    [77.00131999999999, 48.497082],
    [76.955204, 48.507861],
    [76.90021, 48.488184],
    [76.90085599999999, 48.478544],
    [76.85752699999999, 48.493421],
    [76.777619, 48.471743],
    [76.703497, 48.493669],
    [76.667834, 48.518507],
    [76.591241, 48.498059],
    [76.526741, 48.504827999999996],
    [76.50824899999999, 48.561249],
    [76.404511, 48.566527],
    [76.374522, 48.575948],
    [76.312586, 48.66733],
    [76.224627, 48.650361],
    [76.19404999999999, 48.655626],
    [76.139791, 48.652308],
    [75.96748099999999, 48.627165],
    [75.94762, 48.630775],
    [75.93808299999999, 48.642649999999996],
    [75.928784, 48.663309999999996],
    [75.932851, 48.678111],
    [75.91805099999999, 48.705262],
    [75.780309, 48.689448999999996],
    [75.736503, 48.695767],
    [75.709925, 48.7095],
    [75.65386, 48.711214999999996],
    [75.55794, 48.762949],
    [75.54670899999999, 48.760382],
    [75.493025, 48.784673999999995],
    [75.409342, 48.777739999999994],
    [75.38537199999999, 48.780409],
    [75.35122, 48.827529999999996],
    [75.262377, 48.763801],
    [75.244525, 48.765127],
    [75.22505199999999, 48.811546],
    [75.089983, 48.862052999999996],
    [75.073495, 48.861739],
    [75.061336, 48.849849999999996],
    [75.04244899999999, 48.856882],
    [75.005845, 48.85122],
    [74.964406, 48.900090999999996],
    [74.88365, 48.867807],
    [74.82383899999999, 48.88169],
    [74.80378499999999, 48.918228],
    [74.769598, 48.913426],
    [74.6999, 48.914713],
    [74.712177, 48.768285999999996],
    [74.66806199999999, 48.730737],
    [74.6197, 48.766304999999996],
    [74.535158, 48.77429],
    [74.48089499999999, 48.788812],
    [74.435313, 48.83144],
    [74.393427, 48.854245],
    [74.434799, 48.888382],
    [74.44110599999999, 48.942477],
    [74.493629, 49.003592],
    [74.391391, 49.065968999999996],
    [74.390861, 49.096002],
    [74.340768, 49.099433],
    [74.331747, 49.159278],
    [74.31480599999999, 49.191472],
    [74.259765, 49.221441],
    [74.214758, 49.258783],
    [74.160062, 49.237463],
    [74.08025099999999, 49.275470999999996],
    [74.07720499999999, 49.296276],
    [74.029577, 49.313455],
    [74.031987, 49.323722],
    [74.05549599999999, 49.340499],
    [74.03604, 49.393437],
  ],
];

export const NuraPolygon = [
  [
    [67.399056, 49.915451999999995],
    [67.63846199999999, 50.117573],
    [67.607248, 50.12003],
    [67.59406299999999, 50.132357],
    [67.59292599999999, 50.146124],
    [67.5675, 50.156213],
    [67.587244, 50.180209],
    [67.606611, 50.18916],
    [67.58815899999999, 50.217746],
    [67.608605, 50.2371],
    [67.66619399999999, 50.242815],
    [67.683708, 50.251521],
    [67.73625, 50.248374999999996],
    [67.839088, 50.283147],
    [67.860404, 50.301418999999996],
    [67.879344, 50.299704999999996],
    [67.895172, 50.308820999999995],
    [67.953428, 50.316744],
    [67.97635799999999, 50.329249999999995],
    [68.043538, 50.407505],
    [68.099812, 50.435769],
    [68.106292, 50.461484999999996],
    [68.073849, 50.465309],
    [67.99744, 50.514618999999996],
    [68.014111, 50.530659],
    [67.977916, 50.544177999999995],
    [67.94931199999999, 50.544495999999995],
    [67.936433, 50.557925999999995],
    [67.933345, 50.554592],
    [67.93413199999999, 50.611174999999996],
    [67.89538999999999, 50.656470999999996],
    [67.901939, 50.663891],
    [68.05112, 50.664322999999996],
    [68.071591, 50.641306],
    [68.12861699999999, 50.621075999999995],
    [68.249764, 50.661677],
    [68.303393, 50.701173999999995],
    [68.320855, 50.726251],
    [68.391348, 50.747699],
    [68.46902399999999, 50.762631],
    [68.481475, 50.768636],
    [68.479963, 50.779565],
    [68.503635, 50.794475999999996],
    [68.807289, 50.799495],
    [68.81524399999999, 50.582051],
    [68.757849, 50.544211],
    [68.760669, 50.524533],
    [68.747263, 50.512707],
    [68.740599, 50.463724],
    [68.751711, 50.433186],
    [68.740775, 50.415544999999995],
    [68.720477, 50.406558999999994],
    [68.724426, 50.387623999999995],
    [68.711576, 50.357547999999994],
    [68.721846, 50.316053],
    [68.694969, 50.299687999999996],
    [68.676515, 50.268083],
    [68.63693599999999, 50.259584],
    [68.632688, 50.249313],
    [68.706746, 50.24507],
    [68.733117, 50.237338],
    [68.745021, 50.217759],
    [68.77227599999999, 50.218787],
    [68.788339, 50.213119999999996],
    [68.791978, 50.204398999999995],
    [68.82512899999999, 50.196718],
    [68.84299299999999, 50.209312999999995],
    [68.920754, 50.188911999999995],
    [68.996884, 50.188154],
    [69.04099599999999, 50.197299],
    [69.055975, 50.187205999999996],
    [69.075313, 50.185907],
    [69.077157, 50.207091],
    [69.10902899999999, 50.204428],
    [69.14000899999999, 50.188610999999995],
    [69.148836, 50.202548],
    [69.149006, 50.254377],
    [69.11236699999999, 50.274283999999994],
    [69.148909, 50.259485999999995],
    [69.169612, 50.269014],
    [69.169079, 50.276426],
    [69.173723, 50.27135],
    [69.170695, 50.25016],
    [69.17997799999999, 50.240009],
    [69.201402, 50.250383],
    [69.19479799999999, 50.237925999999995],
    [69.21991299999999, 50.232265],
    [69.26061299999999, 50.24641],
    [69.299312, 50.244471],
    [69.31279099999999, 50.255503999999995],
    [69.287572, 50.267754],
    [69.285879, 50.302780999999996],
    [69.274436, 50.306357],
    [69.259789, 50.296771],
    [69.25628999999999, 50.301857999999996],
    [69.253145, 50.354392999999995],
    [69.273414, 50.369854],
    [69.34389, 50.38489],
    [69.36300899999999, 50.403977999999995],
    [69.373328, 50.400386999999995],
    [69.41172999999999, 50.343663],
    [69.424285, 50.34227],
    [69.437839, 50.351828999999995],
    [69.44221399999999, 50.366448999999996],
    [69.42698899999999, 50.397757999999996],
    [69.433787, 50.401441999999996],
    [69.461347, 50.386981999999996],
    [69.462931, 50.350494],
    [69.44032899999999, 50.335052999999995],
    [69.492898, 50.321438],
    [69.511366, 50.299625],
    [69.53976, 50.306317],
    [69.55238899999999, 50.296150999999995],
    [69.57064, 50.292575],
    [69.58788, 50.275124],
    [69.589745, 50.198491],
    [69.690899, 50.192992],
    [69.719184, 50.20986],
    [69.914566, 50.236495999999995],
    [69.977098, 50.238001],
    [70.051007, 50.259178],
    [70.071478, 50.253333],
    [70.08851899999999, 50.23435],
    [70.08053699999999, 50.203699],
    [70.12712599999999, 50.207319],
    [70.141927, 50.221903],
    [70.166881, 50.202169999999995],
    [70.166862, 50.194872],
    [70.150936, 50.184670999999994],
    [70.142928, 50.156943999999996],
    [70.109948, 50.110989],
    [70.135975, 50.082504],
    [70.10989099999999, 50.069385999999994],
    [70.105345, 50.057711],
    [70.12683799999999, 50.040909],
    [70.171005, 50.043783999999995],
    [70.201618, 50.055420999999996],
    [70.226524, 50.052464],
    [70.27526, 50.059670999999994],
    [70.496759, 50.125414],
    [70.546955, 50.152936],
    [70.46711599999999, 50.236464],
    [70.65203699999999, 50.294737999999995],
    [70.724086, 50.238864],
    [70.74821899999999, 50.256232],
    [70.771541, 50.442184999999995],
    [70.805909, 50.449256999999996],
    [70.842732, 50.465790999999996],
    [70.853237, 50.478851999999996],
    [70.942255, 50.473076999999996],
    [71.05736499999999, 50.512982],
    [71.05605299999999, 50.558239],
    [71.134044, 50.614442999999994],
    [71.20847499999999, 50.612251],
    [71.233588, 50.653588],
    [71.33225, 50.654711999999996],
    [71.323796, 50.681076999999995],
    [71.33823, 50.704995999999994],
    [71.334223, 50.726203],
    [71.417692, 50.752235],
    [71.474254, 50.760301],
    [71.525832, 50.791754999999995],
    [71.550584, 50.77465],
    [71.644252, 50.790167999999994],
    [71.662528, 50.753426],
    [71.694537, 50.748593],
    [71.731529, 50.754624],
    [71.795935, 50.694556],
    [71.814705, 50.705214999999995],
    [71.83703799999999, 50.688815999999996],
    [71.83421299999999, 50.674994],
    [71.768332, 50.630756999999996],
    [71.806708, 50.614852],
    [71.814852, 50.587727],
    [71.78755199999999, 50.561873],
    [71.777074, 50.494895],
    [71.737935, 50.455341999999995],
    [71.712514, 50.446956],
    [71.672527, 50.35329],
    [71.640879, 50.340877],
    [71.666556, 50.309089],
    [71.78886299999999, 50.301587999999995],
    [71.77647999999999, 50.267489],
    [71.79669, 50.211469],
    [71.80935099999999, 50.132703],
    [71.860502, 50.134766],
    [71.852445, 50.097749],
    [71.88899099999999, 50.067169],
    [71.89886, 50.035582],
    [71.881979, 49.941562],
    [72.004479, 49.92605],
    [72.00377, 49.908546],
    [71.993453, 49.904708],
    [72.006289, 49.859243],
    [71.968529, 49.774491999999995],
    [71.99812399999999, 49.740424],
    [72.029021, 49.738223],
    [72.032594, 49.717802999999996],
    [72.024315, 49.703658999999995],
    [71.976376, 49.674112],
    [71.955258, 49.668392],
    [71.869536, 49.669779],
    [71.809998, 49.680706],
    [71.77097599999999, 49.697012],
    [71.74237099999999, 49.697078999999995],
    [71.729604, 49.709767],
    [71.695708, 49.71633],
    [71.676908, 49.73017],
    [71.575439, 49.742276999999994],
    [71.590796, 49.513833999999996],
    [71.71659199999999, 49.506009],
    [71.670591, 49.373793],
    [71.646275, 49.331627],
    [71.647278, 49.244099999999996],
    [71.218442, 49.196259],
    [71.000609, 49.179868],
    [70.94673999999999, 49.226515],
    [70.81863299999999, 49.263785999999996],
    [70.77344599999999, 49.312537],
    [70.76473, 49.374061],
    [70.73332599999999, 49.386815],
    [70.714045, 49.382891],
    [70.65926, 49.394861],
    [70.550061, 49.395391],
    [70.55780399999999, 49.143190999999995],
    [70.432001, 48.85874],
    [69.881843, 48.795514999999995],
    [69.831592, 48.801711999999995],
    [69.828351, 48.769394],
    [69.781848, 48.715888],
    [69.42564399999999, 48.644929],
    [69.31392, 48.694981],
    [69.150837, 48.797945],
    [68.90245, 48.795732],
    [68.707454, 48.878006],
    [68.338915, 49.155014],
    [67.99872599999999, 49.180003],
    [67.941301, 49.17897],
    [67.910136, 49.203536],
    [67.918702, 49.322204],
    [67.893225, 49.402533],
    [67.729464, 49.551998],
    [67.664611, 49.694331999999996],
    [67.541114, 49.729442],
    [67.54166, 49.772548],
    [67.482719, 49.839496999999994],
  ],
];

export const OsakarovPolygon = [
  [
    [71.710807, 50.430926],
    [71.799765, 50.428875],
    [71.80913799999999, 50.465948999999995],
    [71.974842, 50.467670999999996],
    [72.003351, 50.46646],
    [72.047435, 50.455487],
    [72.11189999999999, 50.414941999999996],
    [72.12897099999999, 50.413905],
    [72.125725, 50.443884],
    [72.198061, 50.452773],
    [72.185558, 50.479276],
    [72.193781, 50.484231],
    [72.181349, 50.537732],
    [72.23491299999999, 50.55789],
    [72.276664, 50.593576],
    [72.382485, 50.556455],
    [72.452709, 50.564498],
    [72.476018, 50.594657],
    [72.45654499999999, 50.616958],
    [72.460279, 50.622718],
    [72.402575, 50.660396],
    [72.399614, 50.669944],
    [72.43334, 50.701358],
    [72.45336, 50.711158999999995],
    [72.49637299999999, 50.743091],
    [72.508498, 50.733346999999995],
    [72.516291, 50.70691],
    [72.53131499999999, 50.708776],
    [72.52689699999999, 50.733681999999995],
    [72.65982699999999, 50.748262],
    [72.73380999999999, 50.835589999999996],
    [72.772992, 50.855833],
    [72.79661399999999, 50.885191],
    [72.986306, 50.859359],
    [73.023684, 50.885403],
    [73.179509, 50.922878999999995],
    [73.182597, 50.985552],
    [73.203997, 50.994456],
    [73.206602, 50.998763],
    [73.256545, 51.002496],
    [73.29542099999999, 51.013085999999994],
    [73.369765, 51.018273],
    [73.419169, 51.029978],
    [73.430567, 51.04351],
    [73.488188, 51.071729999999995],
    [73.49746999999999, 51.087509],
    [73.53635899999999, 51.096559],
    [73.54912999999999, 51.112227],
    [73.578356, 51.115719],
    [73.443691, 51.174476999999996],
    [73.42732699999999, 51.172767],
    [73.411427, 51.161555],
    [73.408169, 51.164569],
    [73.388127, 51.160041],
    [73.365354, 51.165803999999994],
    [73.35079499999999, 51.124626],
    [73.33093099999999, 51.122273],
    [73.322341, 51.148056],
    [73.30196099999999, 51.171251999999996],
    [73.282228, 51.203913],
    [73.25222699999999, 51.222989999999996],
    [73.21303, 51.210204999999995],
    [73.20501999999999, 51.211883],
    [73.162937, 51.275779],
    [73.204602, 51.289974],
    [73.220647, 51.286615999999995],
    [73.221204, 51.294627],
    [73.23372499999999, 51.307418],
    [73.25788299999999, 51.287048999999996],
    [73.287122, 51.273098999999995],
    [73.342218, 51.279571],
    [73.368375, 51.28758],
    [73.370807, 51.305023999999996],
    [73.4012, 51.307066],
    [73.416246, 51.274522],
    [73.435802, 51.271761999999995],
    [73.449828, 51.27281],
    [73.463276, 51.297225],
    [73.48769, 51.312557],
    [73.51571299999999, 51.298584999999996],
    [73.52300699999999, 51.302746],
    [73.668576, 51.275665],
    [74.033864, 51.198723],
    [74.01474499999999, 51.178945],
    [74.030872, 51.137524],
    [74.052272, 51.117081999999996],
    [74.053963, 51.096588999999994],
    [74.04988399999999, 51.076296],
    [74.012946, 51.050565],
    [74.00779899999999, 51.031037],
    [74.021096, 51.010877],
    [74.02429099999999, 50.980847],
    [74.021951, 50.96706],
    [74.007295, 50.94421],
    [74.05125, 50.918614],
    [74.041882, 50.903611999999995],
    [74.030951, 50.843416999999995],
    [74.018785, 50.835806999999996],
    [73.98692199999999, 50.826679],
    [73.941906, 50.825285],
    [73.91936799999999, 50.817284],
    [73.918761, 50.79614],
    [73.910791, 50.783271],
    [73.94909799999999, 50.746224999999995],
    [73.88140299999999, 50.691565999999995],
    [73.904607, 50.666711],
    [74.004881, 50.604208],
    [74.03711, 50.606201999999996],
    [74.035988, 50.558986],
    [74.016724, 50.554539999999996],
    [73.97792, 50.555867],
    [73.904901, 50.551759],
    [73.87088399999999, 50.541577],
    [73.84897099999999, 50.553611],
    [73.72778699999999, 50.555346],
    [73.718289, 50.536308],
    [73.67593, 50.528524],
    [73.62971499999999, 50.432176],
    [73.639003, 50.366571],
    [73.634306, 50.35723],
    [73.577745, 50.317009999999996],
    [73.539952, 50.28313],
    [73.549584, 50.228469],
    [73.538325, 50.198889],
    [73.532859, 50.162929],
    [73.49698599999999, 50.153425],
    [73.49115499999999, 50.143381999999995],
    [73.49291, 50.128367999999995],
    [73.479067, 50.101414],
    [73.469732, 50.097677],
    [73.42062899999999, 50.092555],
    [73.41227599999999, 50.086594999999996],
    [73.37705199999999, 50.086158999999995],
    [73.365504, 50.091235],
    [73.36699899999999, 50.136438],
    [73.374044, 50.164331999999995],
    [73.478303, 50.225136],
    [73.477266, 50.242317],
    [73.464398, 50.268969999999996],
    [73.4346, 50.273862],
    [73.41008599999999, 50.273119],
    [73.333109, 50.245779999999996],
    [73.323915, 50.252609],
    [73.31977499999999, 50.283376999999994],
    [73.305964, 50.272822],
    [73.277405, 50.279463],
    [73.26772799999999, 50.262583],
    [73.254083, 50.254205999999996],
    [73.185605, 50.267764],
    [73.16381, 50.256313],
    [73.157733, 50.259032],
    [73.14550899999999, 50.289645],
    [72.971301, 50.288351999999996],
    [72.92360699999999, 50.261818999999996],
    [72.822521, 50.264295],
    [72.807195, 50.294219],
    [72.798165, 50.295528],
    [72.73625899999999, 50.277649],
    [72.72310499999999, 50.266279],
    [72.711499, 50.262169],
    [72.490296, 50.240431],
    [72.484245, 50.233259999999994],
    [72.474424, 50.140788],
    [72.385572, 50.121826],
    [72.294337, 50.086787],
    [72.30793899999999, 50.062804],
    [72.358261, 50.014005],
    [72.360491, 50.001554],
    [72.311908, 49.978801],
    [72.299947, 49.964804],
    [72.271442, 49.983968],
    [72.212321, 49.939116999999996],
    [72.04520099999999, 49.92754],
    [72.004479, 49.92605],
    [71.881979, 49.941562],
    [71.89886, 50.035582],
    [71.88899099999999, 50.067169],
    [71.852445, 50.097749],
    [71.860502, 50.134766],
    [71.80935099999999, 50.132703],
    [71.799353, 50.162856],
    [71.79669, 50.211469],
    [71.77647999999999, 50.267489],
    [71.78886299999999, 50.301587999999995],
    [71.666556, 50.309089],
    [71.640879, 50.340877],
    [71.672527, 50.35329],
  ],
];

export const UlutauPolygon = [
  [
    [62.688415, 47.036367],
    [63.220240999999994, 47.351388],
    [63.596636, 47.507135999999996],
    [63.995123, 47.6877],
    [64.281167, 47.897751],
    [64.20525599999999, 47.925329999999995],
    [64.132986, 47.986684],
    [63.997313, 48.193082999999994],
    [64.04952399999999, 48.192992],
    [64.282031, 48.348428999999996],
    [64.229507, 48.461315],
    [64.55488199999999, 48.509851999999995],
    [64.490315, 48.564861],
    [64.52190399999999, 48.611183999999994],
    [64.46664799999999, 48.67431],
    [64.697825, 48.676044999999995],
    [64.743273, 48.938181],
    [65.482092, 48.828724],
    [65.676717, 48.875634999999996],
    [65.735665, 48.873495],
    [65.753108, 48.886821999999995],
    [65.775567, 48.982172],
    [65.99690799999999, 48.990849],
    [66.020502, 48.97737],
    [66.05212, 48.979020999999996],
    [66.39599299999999, 49.252074],
    [66.482036, 49.273195],
    [66.49752699999999, 49.27449],
    [66.497523, 49.260371],
    [66.542103, 49.270368],
    [66.59699499999999, 49.323533999999995],
    [66.650217, 49.456285],
    [66.837531, 49.57917],
    [67.02523099999999, 49.635658],
    [67.373657, 49.937458],
    [67.54166, 49.772548],
    [67.541114, 49.729442],
    [67.664611, 49.694331999999996],
    [67.729464, 49.551998],
    [67.893225, 49.402533],
    [67.918702, 49.322204],
    [67.910136, 49.203536],
    [67.941301, 49.17897],
    [68.338915, 49.155014],
    [68.707454, 48.878006],
    [68.90245, 48.795732],
    [69.150837, 48.797945],
    [69.159098, 48.678396],
    [69.27967799999999, 48.588608],
    [69.781041, 48.145679],
    [69.86026799999999, 48.107247],
    [69.809602, 48.064955999999995],
    [69.80340199999999, 47.972482],
    [69.33803499999999, 47.917505],
    [69.15903399999999, 47.928796999999996],
    [69.042923, 47.794944],
    [68.969527, 47.678442],
    [68.968791, 47.650600999999995],
    [69.13916499999999, 47.331579999999995],
    [69.11509699999999, 47.277058],
    [69.10743, 47.263974],
    [68.97457899999999, 47.220569],
    [68.902464, 47.177198999999995],
    [68.535063, 46.781738],
    [68.529605, 46.767295],
    [68.810327, 46.330276],
    [68.996352, 46.003068],
    [68.153274, 45.996561],
    [67.184016, 46.004538],
    [67.175598, 46.034721999999995],
    [67.187164, 46.067319],
    [67.167625, 46.080559],
    [67.17836799999999, 46.134305],
    [67.157668, 46.148384],
    [66.91148799999999, 46.173927],
    [66.64453999999999, 46.158131],
    [65.906362, 46.180914],
    [65.7242, 46.211349999999996],
    [65.599895, 46.213826999999995],
    [65.579525, 46.231291999999996],
    [65.392118, 46.306303],
    [65.151023, 46.478823999999996],
    [64.950167, 46.501379],
    [64.46673, 46.686164],
    [64.202747, 46.739678],
    [63.861506, 46.835164],
    [63.817851999999995, 46.864393],
    [63.749537999999994, 46.843104],
    [63.528354, 46.927769],
    [63.312166, 46.973132],
    [63.100463999999995, 47.102443],
  ],
  [
    [67.52327199999999, 47.917984999999994],
    [67.50878999999999, 47.92073],
    [67.496645, 47.907458],
    [67.511751, 47.887862],
    [67.56303299999999, 47.861303],
    [67.584105, 47.874327],
    [67.57184699999999, 47.895801],
    [67.553483, 47.916773],
  ],
  [
    [67.654527, 47.784065999999996],
    [67.692162, 47.758393999999996],
    [67.73827399999999, 47.758348],
    [67.773021, 47.78649],
    [67.722741, 47.793791999999996],
    [67.73129999999999, 47.810193999999996],
    [67.693045, 47.806095],
    [67.68117099999999, 47.828475999999995],
    [67.62912899999999, 47.80773],
  ],
  [
    [67.78050999999999, 48.007580999999995],
    [67.778573, 48.003796],
    [67.786115, 48.002058],
    [67.788051, 48.005843999999996],
  ],
  [
    [67.852034, 47.676474],
    [67.852167, 47.666667],
    [67.877133, 47.666821],
    [67.877, 47.676628],
  ],
  [
    [68.055106, 47.907596],
    [68.051158, 47.904869],
    [68.054698, 47.902761999999996],
    [68.058217, 47.905648],
  ],
  [
    [68.519313, 48.064969],
    [68.51400699999999, 48.053563999999994],
    [68.56669099999999, 48.069745999999995],
    [68.54274099999999, 48.077748],
  ],
  [
    [69.09371, 48.189904],
    [69.097304, 48.171279],
    [69.12604999999999, 48.173082],
    [69.124253, 48.192305999999995],
  ],
];

export const ShetskPolygon = [
  [
    [71.36706099999999, 45.994350999999995],
    [71.33295799999999, 46.071476],
    [71.299966, 46.485133999999995],
    [71.33316099999999, 47.24633],
    [71.318934, 47.372063],
    [71.344961, 47.427994999999996],
    [71.379761, 47.575810999999995],
    [71.37589799999999, 47.65726],
    [71.417307, 47.653493999999995],
    [71.485087, 47.604493999999995],
    [71.592991, 47.568802],
    [71.707926, 47.55627],
    [71.76033, 47.678886999999996],
    [71.787427, 47.714985999999996],
    [71.88751599999999, 47.765276],
    [71.935174, 47.797360999999995],
    [71.962299, 47.829037],
    [71.967691, 47.888076999999996],
    [71.929268, 47.966086],
    [71.923309, 48.012172],
    [71.980456, 48.147008],
    [71.977837, 48.164570999999995],
    [71.96030499999999, 48.191871],
    [71.912346, 48.223310999999995],
    [71.915628, 48.253917],
    [71.875281, 48.28668],
    [71.86116299999999, 48.320479],
    [71.836328, 48.329625],
    [71.92778899999999, 48.378543],
    [72.056117, 48.469088],
    [72.019386, 48.66388],
    [72.022643, 48.710539],
    [72.058083, 48.715036],
    [72.057587, 48.730374],
    [72.11073, 48.736005],
    [72.11775399999999, 48.800111],
    [72.08695399999999, 48.802848],
    [72.073955, 48.836653],
    [72.105102, 48.841941],
    [72.09779, 48.934037],
    [72.055816, 48.936234999999996],
    [72.0324, 48.959996],
    [71.985092, 48.968098],
    [71.979922, 48.977674],
    [72.01203199999999, 49.005596999999995],
    [71.95584099999999, 49.043763999999996],
    [71.934013, 49.171122],
    [72.16226999999999, 49.177381],
    [72.165502, 49.279500999999996],
    [72.76407, 49.300528],
    [72.806248, 49.317043],
    [72.855052, 49.312216],
    [72.92357, 49.33972],
    [73.19276099999999, 49.343638999999996],
    [73.238218, 49.356257],
    [73.238328, 49.375229],
    [73.40487399999999, 49.361011999999995],
    [73.367029, 49.279635999999996],
    [73.559524, 49.290718999999996],
    [73.625569, 49.309853],
    [73.629114, 49.327988999999995],
    [73.674399, 49.352117],
    [73.66014, 49.370810999999996],
    [73.66534399999999, 49.380865],
    [73.75077399999999, 49.402967],
    [73.77280499999999, 49.384011],
    [73.78411299999999, 49.385833999999996],
    [73.88476899999999, 49.4161],
    [73.94041299999999, 49.426201999999996],
    [74.0007, 49.407073999999994],
    [74.03604, 49.393437],
    [74.05549599999999, 49.340499],
    [74.031987, 49.323722],
    [74.029577, 49.313455],
    [74.07720499999999, 49.296276],
    [74.08025099999999, 49.275470999999996],
    [74.160062, 49.237463],
    [74.214758, 49.258783],
    [74.259765, 49.221441],
    [74.31480599999999, 49.191472],
    [74.331747, 49.159278],
    [74.340768, 49.099433],
    [74.390861, 49.096002],
    [74.391391, 49.065968999999996],
    [74.493629, 49.003592],
    [74.44110599999999, 48.942477],
    [74.434799, 48.888382],
    [74.393427, 48.854245],
    [74.386833, 48.858182],
    [74.315536, 48.786398999999996],
    [74.310628, 48.754466],
    [74.321111, 48.721962],
    [74.212543, 48.701854999999995],
    [73.985186, 48.696717],
    [73.983182, 48.685108],
    [73.995496, 48.645272999999996],
    [74.10292799999999, 48.64008],
    [74.11940799999999, 48.598625],
    [74.15633, 48.592934],
    [74.167621, 48.583040999999994],
    [74.18953599999999, 48.501238],
    [74.22542299999999, 48.483883],
    [74.333507, 48.47772],
    [74.3384, 48.431554],
    [74.348345, 48.419503999999996],
    [74.450451, 48.371119],
    [74.456245, 48.362139],
    [74.471969, 48.290735],
    [74.422962, 48.202832],
    [74.442258, 48.186039],
    [74.378599, 48.116192999999996],
    [74.427852, 48.067614],
    [74.40542599999999, 48.059706999999996],
    [74.410674, 48.018634],
    [74.387239, 47.998352],
    [74.38271499999999, 47.983194999999995],
    [74.38718999999999, 47.932663],
    [74.410411, 47.898939],
    [74.413084, 47.814167999999995],
    [74.457408, 47.787661],
    [74.466978, 47.747149],
    [74.534025, 47.683971],
    [74.572865, 47.595591],
    [74.59505399999999, 47.590336],
    [74.600684, 47.580624],
    [74.576455, 47.549465],
    [74.607601, 47.523416999999995],
    [74.600304, 47.350716],
    [74.429383, 47.347133],
    [74.349136, 47.363299],
    [74.170951, 47.363248999999996],
    [74.160591, 47.340261],
    [74.09787399999999, 47.291388999999995],
    [74.073808, 47.256465999999996],
    [74.018496, 47.232836999999996],
    [73.85815099999999, 47.266718],
    [73.881875, 47.328706],
    [73.822655, 47.325551],
    [73.726733, 47.334492],
    [73.519401, 47.29712],
    [73.42659499999999, 47.287465],
    [73.29832499999999, 47.293337],
    [73.343841, 47.248979],
    [73.416478, 47.143226999999996],
    [73.458142, 47.041267999999995],
    [73.621326, 46.422416999999996],
    [73.603645, 46.409822],
    [73.59423, 46.377990999999994],
    [73.608687, 46.096736],
    [73.5837, 46.062217],
    [73.572741, 45.993922999999995],
  ],
];

export const KaragandaPolygon = [
  [
    [72.984331, 49.845251999999995],
    [72.982027, 49.848323],
    [72.989085, 49.858380999999994],
    [73.00379699999999, 49.866820999999995],
    [73.026145, 49.876172],
    [73.026513, 49.882881999999995],
    [73.031505, 49.888526999999996],
    [73.029164, 49.891678],
    [73.02566999999999, 49.918026],
    [73.02704299999999, 49.923457],
    [73.02635599999999, 49.925396],
    [73.054509, 49.939246999999995],
    [73.05948699999999, 49.934371999999996],
    [73.064379, 49.937833999999995],
    [73.067984, 49.935894999999995],
    [73.073349, 49.943512],
    [73.092832, 49.943844],
    [73.092832, 49.945671999999995],
    [73.09858299999999, 49.950435],
    [73.10193, 49.952041],
    [73.10579299999999, 49.952152],
    [73.10587799999999, 49.953758],
    [73.110084, 49.951986],
    [73.193512, 49.971253999999995],
    [73.219613, 49.989171999999996],
    [73.227604, 49.989799999999995],
    [73.228494, 49.990339999999996],
    [73.22971799999999, 49.989973],
    [73.236727, 49.992756],
    [73.24569699999999, 49.991926],
    [73.24625499999999, 49.9914],
    [73.242875, 49.983216999999996],
    [73.247665, 49.981229],
    [73.245049, 49.955659999999995],
    [73.246265, 49.946433999999996],
    [73.248778, 49.935431],
    [73.24902, 49.931442],
    [73.2541, 49.929552],
    [73.25436599999999, 49.928737999999996],
    [73.24850599999999, 49.923618],
    [73.248635, 49.921057],
    [73.255783, 49.908483],
    [73.252978, 49.890431],
    [73.24197699999999, 49.881277999999995],
    [73.21521, 49.875473],
    [73.211472, 49.869707],
    [73.21310299999999, 49.860954],
    [73.219208, 49.855246],
    [73.22638599999999, 49.854458],
    [73.22023899999999, 49.837556],
    [73.218299, 49.834906],
    [73.215841, 49.833633],
    [73.212075, 49.832845],
    [73.20425999999999, 49.834624999999996],
    [73.194159, 49.824931],
    [73.189876, 49.826073],
    [73.190032, 49.828286],
    [73.186143, 49.830597999999995],
    [73.179965, 49.829947999999995],
    [73.17532, 49.832155],
    [73.15412599999999, 49.817873999999996],
    [73.17258, 49.806497],
    [73.166887, 49.802372999999996],
    [73.175659, 49.796292],
    [73.16992499999999, 49.789412999999996],
    [73.17621, 49.785587],
    [73.178428, 49.782297],
    [73.188531, 49.783217],
    [73.187308, 49.776233999999995],
    [73.192696, 49.773945999999995],
    [73.192467, 49.771260999999996],
    [73.190901, 49.770042],
    [73.192619, 49.764931],
    [73.189216, 49.761309999999995],
    [73.19319, 49.759868999999995],
    [73.191441, 49.756961999999994],
    [73.180876, 49.753737],
    [73.18468399999999, 49.742495],
    [73.172056, 49.739964],
    [73.164185, 49.754636],
    [73.160268, 49.754242],
    [73.157035, 49.754821],
    [73.156199, 49.757901],
    [73.14387099999999, 49.756871],
    [73.133327, 49.760605999999996],
    [73.11800099999999, 49.767486999999996],
    [73.112967, 49.772704999999995],
    [73.110007, 49.773637],
    [73.106188, 49.773374],
    [73.097569, 49.769262999999995],
    [73.08554099999999, 49.765874],
    [73.078788, 49.759592],
    [73.07559599999999, 49.759049999999995],
    [73.073004, 49.759358999999996],
    [73.070031, 49.755905],
    [73.066223, 49.753225],
    [73.066182, 49.751374],
    [73.068955, 49.747802],
    [73.060203, 49.741445],
    [73.046239, 49.735476],
    [73.02238799999999, 49.740147],
    [73.02139199999999, 49.744572],
    [73.02411099999999, 49.757244],
    [73.00904899999999, 49.772209],
    [73.011661, 49.775034999999995],
    [73.013993, 49.776039],
    [73.01097299999999, 49.778095],
    [73.006142, 49.779562999999996],
    [73.009125, 49.786733999999996],
    [73.010631, 49.788274],
    [73.00737, 49.788539],
    [73.006839, 49.78899],
    [73.002428, 49.787338],
    [72.999224, 49.794216999999996],
    [72.991937, 49.795291],
    [72.989707, 49.797376],
    [73.005901, 49.800605],
    [73.006785, 49.803652],
    [72.996566, 49.813496],
    [72.995104, 49.818042],
    [72.98783399999999, 49.824127],
    [72.98448599999999, 49.824737999999996],
    [72.988091, 49.828347],
    [72.98955, 49.832234],
    [72.995977, 49.834239],
  ],
];

export const BalkhashPolygon = [
  [
    [74.924678, 46.830760999999995],
    [74.9219, 46.857586],
    [74.912802, 46.861883999999996],
    [74.913746, 46.868947999999996],
    [74.94502, 46.872597999999996],
    [74.999179, 46.871126],
    [75.018491, 46.865474999999996],
    [75.02003599999999, 46.84204],
    [75.028748, 46.830967],
    [75.026302, 46.829375999999996],
    [74.99214099999999, 46.828669],
    [74.981713, 46.833293999999995],
    [74.978923, 46.8335],
    [74.97467499999999, 46.83188],
    [74.971499, 46.827904],
    [74.960255, 46.828257],
    [74.953947, 46.826961],
    [74.94759499999999, 46.823308],
    [74.947037, 46.821864999999995],
    [74.947381, 46.820628],
    [74.9403, 46.814558999999996],
    [74.919614, 46.819567],
  ],
  [
    [74.976319, 46.965576],
    [74.977364, 46.967337],
    [74.978655, 46.968179],
    [74.982389, 46.968956999999996],
    [74.984274, 46.969242],
    [74.987233, 46.970115],
    [74.992429, 46.967631999999995],
    [74.999623, 46.968368],
    [75.003528, 46.966789999999996],
    [74.99061499999999, 46.957234],
    [74.98277499999999, 46.958622999999996],
    [74.982157, 46.958818],
    [74.975642, 46.964075],
  ],
];

export const ZhezPolygon = [
  [
    [67.654527, 47.784065999999996],
    [67.634078, 47.794433],
    [67.62912899999999, 47.80773],
    [67.655185, 47.823715],
    [67.68117099999999, 47.828475999999995],
    [67.689278, 47.825950999999996],
    [67.69483, 47.810279],
    [67.692887, 47.808336999999995],
    [67.691974, 47.806712999999995],
    [67.693045, 47.806095],
    [67.696461, 47.806844],
    [67.705212, 47.811769],
    [67.71293299999999, 47.812704],
    [67.715501, 47.810902],
    [67.722438, 47.810159999999996],
    [67.73129999999999, 47.810193999999996],
    [67.731673, 47.809275],
    [67.725954, 47.806411999999995],
    [67.721165, 47.800228],
    [67.721586, 47.79906],
    [67.723958, 47.798027999999995],
    [67.72405599999999, 47.796330999999995],
    [67.722741, 47.793791999999996],
    [67.727975, 47.791925],
    [67.738488, 47.791244],
    [67.742597, 47.791619999999995],
    [67.751317, 47.792929],
    [67.77275999999999, 47.786721],
    [67.773021, 47.78649],
    [67.762485, 47.773340999999995],
    [67.73827399999999, 47.758348],
    [67.692162, 47.758393999999996],
    [67.687344, 47.761426],
    [67.676756, 47.775819],
    [67.666724, 47.782981],
    [67.658607, 47.780567999999995],
  ],
];

export const KarazhalPolygon = [
  [
    [70.84163099999999, 48.044917999999996],
    [70.868687, 48.046268999999995],
    [70.890637, 48.035882],
    [70.88710999999999, 48.019270999999996],
    [70.877183, 48.007808],
    [70.838414, 48.007028999999996],
    [70.809601, 47.986432],
    [70.79660799999999, 47.98614],
    [70.75995499999999, 48.002508999999996],
    [70.761386, 48.029695],
    [70.775296, 48.043437],
    [70.795835, 48.045334999999994],
  ],
];

export const PriozerskPolygon = [
  [
    [73.662234, 46.027287],
    [73.653481, 46.036497],
    [73.652331, 46.048353],
    [73.65971499999999, 46.0509],
    [73.661121, 46.047573],
    [73.663009, 46.044346],
    [73.664086, 46.04335],
    [73.66783699999999, 46.043403999999995],
    [73.670722, 46.041990999999996],
    [73.672352, 46.041779999999996],
    [73.67349399999999, 46.042608],
    [73.675169, 46.042553999999996],
    [73.67684, 46.04118],
    [73.680142, 46.041340999999996],
    [73.68222999999999, 46.042058999999995],
    [73.687934, 46.04203],
    [73.69093, 46.039457],
    [73.698865, 46.03861],
    [73.701779, 46.036511999999995],
    [73.70566699999999, 46.035798],
    [73.71403099999999, 46.034673],
    [73.72172499999999, 46.023070999999995],
    [73.725982, 46.023064],
    [73.727042, 46.023772],
    [73.728574, 46.023298999999994],
    [73.72858, 46.02151],
    [73.727038, 46.020416],
    [73.726846, 46.017981],
    [73.727036, 46.015454],
    [73.716267, 46.014917],
    [73.71052499999999, 46.014756999999996],
    [73.704251, 46.016725],
    [73.69633999999999, 46.017455999999996],
    [73.68268599999999, 46.023376999999996],
    [73.66570999999999, 46.02621],
  ],
];

export const SaranPolygon = [
  [
    [72.977482, 49.77071],
    [72.96539, 49.768538],
    [72.964322, 49.770402],
    [72.950938, 49.766791],
    [72.9477, 49.767509],
    [72.94545, 49.775759],
    [72.947169, 49.778546],
    [72.949574, 49.780207],
    [72.955613, 49.783455],
    [72.961472, 49.786356999999995],
    [72.967676, 49.786716999999996],
    [72.97014, 49.783989],
    [72.975084, 49.77968],
    [72.97522599999999, 49.777156],
    [72.97227699999999, 49.775628],
  ],
  [
    [72.788221, 49.82664],
    [72.784179, 49.833321],
    [72.790712, 49.840668],
    [72.805088, 49.841055],
    [72.819191, 49.852247999999996],
    [72.83341999999999, 49.849705],
    [72.843402, 49.845462],
    [72.829179, 49.827836999999995],
    [72.854584, 49.817266],
    [72.859732, 49.814802],
    [72.870955, 49.801154],
    [72.880736, 49.799265],
    [72.897638, 49.783274],
    [72.915347, 49.77251],
    [72.920324, 49.762513999999996],
    [72.90955799999999, 49.752739999999996],
    [72.885814, 49.747308],
    [72.841431, 49.743551],
    [72.826723, 49.738516999999995],
    [72.81565499999999, 49.745129999999996],
    [72.811132, 49.753136],
    [72.80075, 49.767835],
    [72.81677599999999, 49.779382999999996],
    [72.80171299999999, 49.794824],
    [72.81942, 49.809363999999995],
    [72.78809799999999, 49.821349999999995],
  ],
  [
    [72.879161, 49.802254999999995],
    [72.881819, 49.806945999999996],
    [72.885491, 49.808032],
    [72.89647699999999, 49.81342],
    [72.898172, 49.813351],
    [72.899911, 49.811712],
    [72.899028, 49.809881999999995],
    [72.89018999999999, 49.804713],
    [72.884289, 49.804033],
    [72.882423, 49.801144],
  ],
];

export const SatpayevPolygon = [
  [
    [67.52327199999999, 47.917984999999994],
    [67.52464499999999, 47.92158],
    [67.538271, 47.919185999999996],
    [67.53676399999999, 47.915414999999996],
    [67.542496, 47.914348999999994],
    [67.547947, 47.917781999999995],
    [67.553483, 47.916773],
    [67.564426, 47.906299],
    [67.57184699999999, 47.895801],
    [67.580109, 47.882503],
    [67.584105, 47.874327],
    [67.56303299999999, 47.861303],
    [67.53578399999999, 47.883185999999995],
    [67.511751, 47.887862],
    [67.504413, 47.899868999999995],
    [67.503597, 47.905121],
    [67.496645, 47.907458],
    [67.49819, 47.911209],
    [67.50878999999999, 47.92073],
  ],
];

export const TemirtauPolygon = [
  [
    [72.886333, 50.102346],
    [72.88941799999999, 50.111824999999996],
    [72.892336, 50.112680999999995],
    [72.893237, 50.113853],
    [72.898656, 50.114371],
    [72.91502799999999, 50.110521],
    [72.913708, 50.106435999999995],
    [72.91879899999999, 50.106507],
    [72.917653, 50.102199],
    [72.923053, 50.092918999999995],
    [72.932491, 50.084807],
    [72.930559, 50.081188999999995],
    [72.926586, 50.076491],
    [72.93100199999999, 50.070896999999995],
    [72.94976, 50.066154],
    [72.974052, 50.072603],
    [73.00715199999999, 50.070661],
    [73.06097, 50.078016],
    [73.069942, 50.066046],
    [73.090479, 50.070308999999995],
    [73.097571, 50.062163999999996],
    [73.08766899999999, 50.048105],
    [73.062226, 50.039272],
    [73.051593, 50.040727],
    [73.021272, 50.025099],
    [73.007963, 50.031116],
    [72.979376, 50.018195],
    [72.95685499999999, 50.040465],
    [72.951295, 50.039179],
    [72.930106, 50.041045999999994],
    [72.924656, 50.037397999999996],
    [72.915043, 50.034965],
    [72.90749, 50.04428],
    [72.901654, 50.050028],
    [72.89762, 50.049088],
    [72.88811, 50.054809],
    [72.885893, 50.059211],
    [72.891429, 50.064349],
    [72.89786699999999, 50.063548],
    [72.90026999999999, 50.070702999999995],
    [72.895292, 50.070979],
    [72.885807, 50.078519],
    [72.87430599999999, 50.081694999999996],
    [72.877096, 50.088929],
    [72.884269, 50.096585],
  ],
];

export const ShahtinskPolygon = [
  [
    [72.551673, 49.696892999999996],
    [72.542433, 49.703571],
    [72.575774, 49.729799],
    [72.581605, 49.734901],
    [72.590763, 49.742345],
    [72.598086, 49.738355],
    [72.60619299999999, 49.725877999999994],
    [72.626995, 49.704043999999996],
    [72.625023, 49.702676999999994],
    [72.611593, 49.694451],
    [72.58524299999999, 49.690608999999995],
    [72.55579399999999, 49.698555999999996],
  ],
];

export const Abay = [
  [
    [72.844174, 49.651348],
    [72.863873, 49.648649999999996],
    [72.864868, 49.643885999999995],
    [72.882586, 49.639508],
    [72.890982, 49.638887],
    [72.893428, 49.638135],
    [72.89433, 49.631668],
    [72.89302099999999, 49.629842],
    [72.890102, 49.629214999999995],
    [72.888472, 49.630595],
    [72.882592, 49.629382],
    [72.883048, 49.62819],
    [72.885044, 49.624427],
    [72.878757, 49.622991],
    [72.874766, 49.623103],
    [72.868135, 49.625598],
    [72.863951, 49.623925],
    [72.867577, 49.62005],
    [72.865861, 49.619339],
    [72.868972, 49.616132],
    [72.875173, 49.615547],
    [72.876521, 49.614239],
    [72.877657, 49.609866],
    [72.869908, 49.606575],
    [72.84343, 49.633063],
    [72.841584, 49.632504999999995],
    [72.834975, 49.635404],
    [72.83694899999999, 49.643096],
  ],
];

export const KaragandaObl = [
  [
    [62.688415, 47.036367],
    [63.220240999999994, 47.351388],
    [63.995123, 47.6877],
    [64.281167, 47.897751],
    [64.20525599999999, 47.925329999999995],
    [64.132986, 47.986684],
    [63.997313, 48.193082999999994],
    [64.04952399999999, 48.192992],
    [64.282031, 48.348428999999996],
    [64.229507, 48.461315],
    [64.55488199999999, 48.509851999999995],
    [64.490315, 48.564861],
    [64.52190399999999, 48.611183999999994],
    [64.46664799999999, 48.67431],
    [64.697825, 48.676044999999995],
    [64.743273, 48.938181],
    [65.482092, 48.828724],
    [65.676717, 48.875634999999996],
    [65.735665, 48.873495],
    [65.775567, 48.982172],
    [66.05212, 48.979020999999996],
    [66.39599299999999, 49.252074],
    [66.542103, 49.270368],
    [66.59699499999999, 49.323533999999995],
    [66.650217, 49.456285],
    [66.837531, 49.57917],
    [67.02523099999999, 49.635658],
    [67.373657, 49.937458],
    [67.399056, 49.915451999999995],
    [67.63846199999999, 50.117573],
    [67.607248, 50.12003],
    [67.5675, 50.156213],
    [67.606611, 50.18916],
    [67.58815899999999, 50.217746],
    [67.608605, 50.2371],
    [67.73625, 50.248374999999996],
    [67.860404, 50.301418999999996],
    [67.953428, 50.316744],
    [68.099812, 50.435769],
    [68.106292, 50.461484999999996],
    [67.99744, 50.514618999999996],
    [68.014111, 50.530659],
    [67.933345, 50.554592],
    [67.901939, 50.663891],
    [68.05112, 50.664322999999996],
    [68.12861699999999, 50.621075999999995],
    [68.249764, 50.661677],
    [68.320855, 50.726251],
    [68.46902399999999, 50.762631],
    [68.503635, 50.794475999999996],
    [68.807289, 50.799495],
    [68.81524399999999, 50.582051],
    [68.757849, 50.544211],
    [68.721846, 50.316053],
    [68.632688, 50.249313],
    [68.706746, 50.24507],
    [68.82512899999999, 50.196718],
    [68.84299299999999, 50.209312999999995],
    [68.920754, 50.188911999999995],
    [69.075313, 50.185907],
    [69.077157, 50.207091],
    [69.14000899999999, 50.188610999999995],
    [69.149006, 50.254377],
    [69.11236699999999, 50.274283999999994],
    [69.148909, 50.259485999999995],
    [69.169079, 50.276426],
    [69.17997799999999, 50.240009],
    [69.201402, 50.250383],
    [69.21991299999999, 50.232265],
    [69.299312, 50.244471],
    [69.285879, 50.302780999999996],
    [69.25628999999999, 50.301857999999996],
    [69.253145, 50.354392999999995],
    [69.36300899999999, 50.403977999999995],
    [69.424285, 50.34227],
    [69.433787, 50.401441999999996],
    [69.461347, 50.386981999999996],
    [69.44032899999999, 50.335052999999995],
    [69.58788, 50.275124],
    [69.589745, 50.198491],
    [69.690899, 50.192992],
    [69.719184, 50.20986],
    [70.051007, 50.259178],
    [70.08851899999999, 50.23435],
    [70.08053699999999, 50.203699],
    [70.141927, 50.221903],
    [70.166862, 50.194872],
    [70.11336399999999, 50.120475],
    [70.135975, 50.082504],
    [70.105345, 50.057711],
    [70.12683799999999, 50.040909],
    [70.171005, 50.043783999999995],
    [70.338785, 50.074847999999996],
    [70.546955, 50.152936],
    [70.46711599999999, 50.236464],
    [70.65203699999999, 50.294737999999995],
    [70.724086, 50.238864],
    [70.74821899999999, 50.256232],
    [70.771541, 50.442184999999995],
    [70.853237, 50.478851999999996],
    [70.942255, 50.473076999999996],
    [71.05736499999999, 50.512982],
    [71.05605299999999, 50.558239],
    [71.134044, 50.614442999999994],
    [71.20847499999999, 50.612251],
    [71.233588, 50.653588],
    [71.33225, 50.654711999999996],
    [71.334223, 50.726203],
    [71.474254, 50.760301],
    [71.525832, 50.791754999999995],
    [71.550584, 50.77465],
    [71.644252, 50.790167999999994],
    [71.662528, 50.753426],
    [71.731529, 50.754624],
    [71.795935, 50.694556],
    [71.814705, 50.705214999999995],
    [71.83703799999999, 50.688815999999996],
    [71.768332, 50.630756999999996],
    [71.806708, 50.614852],
    [71.814852, 50.587727],
    [71.777074, 50.494895],
    [71.710807, 50.430926],
    [71.799765, 50.428875],
    [71.80913799999999, 50.465948999999995],
    [71.974842, 50.467670999999996],
    [72.047435, 50.455487],
    [72.12897099999999, 50.413905],
    [72.125725, 50.443884],
    [72.198061, 50.452773],
    [72.181349, 50.537732],
    [72.276664, 50.593576],
    [72.382485, 50.556455],
    [72.452709, 50.564498],
    [72.476018, 50.594657],
    [72.399614, 50.669944],
    [72.49637299999999, 50.743091],
    [72.516291, 50.70691],
    [72.52689699999999, 50.733681999999995],
    [72.65982699999999, 50.748262],
    [72.79661399999999, 50.885191],
    [72.986306, 50.859359],
    [73.023684, 50.885403],
    [73.179509, 50.922878999999995],
    [73.182597, 50.985552],
    [73.206602, 50.998763],
    [73.419169, 51.029978],
    [73.49746999999999, 51.087509],
    [73.578356, 51.115719],
    [73.443691, 51.174476999999996],
    [73.365354, 51.165803999999994],
    [73.33093099999999, 51.122273],
    [73.282228, 51.203913],
    [73.25222699999999, 51.222989999999996],
    [73.20501999999999, 51.211883],
    [73.162937, 51.275779],
    [73.220647, 51.286615999999995],
    [73.23372499999999, 51.307418],
    [73.287122, 51.273098999999995],
    [73.4012, 51.307066],
    [73.416246, 51.274522],
    [73.449828, 51.27281],
    [73.48769, 51.312557],
    [74.033864, 51.198723],
    [74.01474499999999, 51.178945],
    [74.053963, 51.096588999999994],
    [74.012946, 51.050565],
    [74.007295, 50.94421],
    [74.05125, 50.918614],
    [74.030951, 50.843416999999995],
    [73.91936799999999, 50.817284],
    [73.910791, 50.783271],
    [73.94909799999999, 50.746224999999995],
    [73.88140299999999, 50.691565999999995],
    [73.904607, 50.666711],
    [74.004881, 50.604208],
    [74.285427, 50.611115],
    [74.37377599999999, 50.63406],
    [74.398804, 50.660826],
    [74.45045999999999, 50.664311],
    [74.586389, 50.630357],
    [74.63178599999999, 50.609764],
    [74.59727199999999, 50.550273999999995],
    [74.653218, 50.463947],
    [74.728166, 50.477756],
    [74.733496, 50.523553],
    [74.771457, 50.530912],
    [74.841917, 50.461214999999996],
    [74.872945, 50.446563999999995],
    [74.906947, 50.460969999999996],
    [74.961602, 50.383449],
    [74.977201, 50.340232],
    [74.95926, 50.331101999999994],
    [75.135041, 50.256192],
    [75.105238, 50.208023],
    [74.948605, 50.183119999999995],
    [74.808268, 50.103899],
    [74.853684, 50.035714999999996],
    [74.917684, 50.03105],
    [74.942739, 50.007923999999996],
    [75.029969, 50.021266999999995],
    [75.100651, 50.077599],
    [75.227358, 50.112548],
    [75.33225399999999, 50.171122999999994],
    [75.46052499999999, 50.188922999999996],
    [75.590266, 50.178799],
    [75.65478399999999, 50.190525],
    [75.707877, 50.235434999999995],
    [75.786603, 50.197893],
    [75.849745, 50.197700999999995],
    [76.054131, 50.254535],
    [76.225202, 50.205901],
    [76.276924, 50.259536999999995],
    [76.275959, 50.299181999999995],
    [76.32133, 50.30365],
    [76.322402, 50.323387999999994],
    [76.520555, 50.32629],
    [76.661547, 50.27374],
    [76.754997, 50.540821],
    [76.778201, 50.583523],
    [76.856582, 50.636344],
    [77.043072, 50.655881],
    [77.252397, 50.554879],
    [77.45015, 50.538391],
    [77.641841, 50.451651999999996],
    [77.647722, 50.353727],
    [77.60597899999999, 50.266863],
    [77.702101, 50.210479],
    [77.683768, 50.167834],
    [77.45002099999999, 50.023674],
    [77.22658, 49.944489999999995],
    [77.18339, 49.946982],
    [77.14580099999999, 49.892877999999996],
    [77.139639, 49.787698],
    [77.17733799999999, 49.752482],
    [77.17402, 49.717389],
    [77.028187, 49.589127],
    [76.82295599999999, 49.525338999999995],
    [76.77046299999999, 49.53344],
    [76.59788999999999, 49.485915],
    [76.557779, 49.429122],
    [76.633754, 49.328893],
    [76.763283, 49.248337],
    [76.82569699999999, 49.147577],
    [76.918666, 49.133368999999995],
    [77.01821199999999, 49.074602999999996],
    [76.985061, 49.048328999999995],
    [76.847877, 49.014227],
    [76.870088, 48.905018],
    [76.84966299999999, 48.856134999999995],
    [76.88755499999999, 48.810153],
    [76.906938, 48.793808999999996],
    [76.992955, 48.819179999999996],
    [77.287069, 48.852368999999996],
    [77.32138499999999, 48.767523],
    [77.302238, 48.629417],
    [77.159436, 48.495342],
    [76.955204, 48.507861],
    [76.90021, 48.488184],
    [76.895192, 48.427676999999996],
    [76.818844, 48.403721999999995],
    [76.823944, 48.382141],
    [76.97484899999999, 48.291104999999995],
    [77.14917299999999, 48.280176999999995],
    [77.147494, 48.248742],
    [77.055324, 48.248669],
    [77.056067, 48.179874],
    [77.152546, 48.107624],
    [77.280817, 48.102505],
    [77.332803, 48.076046999999996],
    [77.178888, 48.029844999999995],
    [77.19673999999999, 47.972361],
    [77.142206, 47.952811999999994],
    [77.132644, 47.874081],
    [77.04853299999999, 47.870596],
    [76.971841, 47.63907],
    [77.021816, 47.58251],
    [77.06810399999999, 47.40992],
    [76.98137899999999, 47.43167],
    [76.874676, 47.431042999999995],
    [76.84329, 47.285244],
    [76.78669099999999, 47.255804],
    [76.902808, 47.186754],
    [77.14576699999999, 47.09084],
    [77.180996, 47.092977999999995],
    [77.240995, 47.187511],
    [77.46621999999999, 47.159929],
    [77.55796099999999, 46.635242],
    [77.531274, 46.621282],
    [77.47884599999999, 46.641366999999995],
    [77.37687199999999, 46.613997999999995],
    [77.302351, 46.617501],
    [77.24509499999999, 46.582321],
    [77.240428, 46.548638],
    [77.136073, 46.536502999999996],
    [77.108931, 46.549726],
    [77.14029599999999, 46.56059],
    [77.056623, 46.640273],
    [76.992243, 46.653141999999995],
    [76.94201, 46.618829],
    [76.884028, 46.641587],
    [76.838285, 46.690531],
    [76.748989, 46.680932],
    [76.807127, 46.675194999999995],
    [76.81106799999999, 46.649981],
    [76.861487, 46.625001999999995],
    [76.845486, 46.609007999999996],
    [76.88516, 46.609761999999996],
    [76.933576, 46.550455],
    [76.701118, 46.631423],
    [76.69301899999999, 46.645694999999996],
    [76.730389, 46.649969],
    [76.72071799999999, 46.669031],
    [76.66881699999999, 46.662515],
    [76.678388, 46.649553999999995],
    [76.581812, 46.676431],
    [76.516497, 46.64725],
    [76.533637, 46.600832],
    [76.43581, 46.65293],
    [76.400227, 46.658817],
    [76.418776, 46.640032999999995],
    [76.40190199999999, 46.632979999999996],
    [76.384123, 46.705],
    [76.307321, 46.644991999999995],
    [76.028964, 46.731441],
    [75.420007, 46.691196999999995],
    [74.999329, 46.543709],
    [74.747922, 46.524066],
    [74.164975, 46.122428],
    [74.058269, 45.995236],
    [67.184016, 46.004538],
    [67.17836799999999, 46.134305],
    [67.157668, 46.148384],
    [66.91148799999999, 46.173927],
    [66.64453999999999, 46.158131],
    [65.906362, 46.180914],
    [65.599895, 46.213826999999995],
    [65.392118, 46.306303],
    [65.151023, 46.478823999999996],
    [64.950167, 46.501379],
    [64.46673, 46.686164],
    [64.202747, 46.739678],
    [63.861506, 46.835164],
    [63.817851999999995, 46.864393],
    [63.749537999999994, 46.843104],
    [63.528354, 46.927769],
    [63.312166, 46.973132],
    [63.100463999999995, 47.102443],
  ],
];

export const AkmolaCoords = [
  [51.792558, 65.351348],
  [51.795417, 65.517836],
  [51.872330999999996, 65.759708],
  [51.920218, 65.767253],
  [52.000043, 65.820245],
  [52.079865999999996, 65.848944],
  [52.082547999999996, 65.86684699999999],
  [52.093114, 65.853019],
  [52.140632, 65.869506],
  [52.202087999999996, 65.99719999999999],
  [52.435855, 66.00778799999999],
  [52.453198, 66.07101999999999],
  [52.334773, 66.232965],
  [52.363267, 66.35876999999999],
  [52.376283, 66.476698],
  [52.365679, 66.533176],
  [52.312439, 66.552815],
  [52.28391, 66.545799],
  [52.278231, 66.779089],
  [52.247184, 66.924662],
  [52.253403999999996, 66.982783],
  [52.242670999999994, 67.04360299999999],
  [52.261798999999996, 67.091634],
  [52.208189, 67.298368],
  [52.428990999999996, 67.29553899999999],
  [52.430479999999996, 67.536766],
  [52.372420999999996, 67.604924],
  [52.363125, 67.707368],
  [52.277187999999995, 67.855223],
  [52.328047, 67.902104],
  [52.339974999999995, 67.883009],
  [52.377376999999996, 67.91672299999999],
  [52.353719999999996, 67.967261],
  [52.359562999999994, 67.97779799999999],
  [52.402336, 67.995903],
  [52.462696, 68.050386],
  [52.484179, 68.037032],
  [52.485366, 67.995167],
  [52.544796, 67.993968],
  [52.548061999999994, 68.13648599999999],
  [52.596419999999995, 68.151478],
  [52.597865999999996, 68.184018],
  [52.588578, 68.193721],
  [52.607904999999995, 68.223969],
  [52.61143, 68.337186],
  [52.658947999999995, 68.361778],
  [52.657381, 68.385155],
  [52.731685999999996, 68.438417],
  [52.759716, 68.490372],
  [52.740398, 68.529988],
  [52.785270999999995, 68.573657],
  [52.805876, 68.64159699999999],
  [52.795747999999996, 68.66375],
  [52.802558999999995, 68.682251],
  [52.859652, 68.641447],
  [52.881448999999996, 68.650126],
  [52.984868999999996, 68.618652],
  [53.05254, 68.768601],
  [53.082910999999996, 68.786553],
  [53.118958, 68.714826],
  [53.125127, 68.763345],
  [53.19968, 68.76588199999999],
  [53.209306999999995, 68.69475899999999],
  [53.263147, 68.700974],
  [53.262888, 68.593796],
  [53.328357, 68.545829],
  [53.330013, 68.526827],
  [53.366789999999995, 68.52554099999999],
  [53.395651, 68.494486],
  [53.405257, 68.508378],
  [53.458591999999996, 68.473231],
  [53.462319, 68.545945],
  [53.480630999999995, 68.578582],
  [53.525301, 68.607202],
  [53.521, 68.643819],
  [53.558808, 68.650531],
  [53.558057, 68.756897],
  [53.64566, 68.744745],
  [53.641662, 68.816429],
  [53.659594999999996, 68.81751],
  [53.647051, 68.887847],
  [53.619833, 68.902879],
  [53.619915999999996, 69.02367199999999],
  [53.683049999999994, 69.015822],
  [53.690462999999994, 69.12231299999999],
  [53.607425, 69.117712],
  [53.602565999999996, 69.23696799999999],
  [53.57333, 69.237507],
  [53.569787999999996, 69.275671],
  [53.546219, 69.277669],
  [53.547309, 69.30303599999999],
  [53.501104, 69.305398],
  [53.495011, 69.402163],
  [53.517765999999995, 69.427202],
  [53.550760999999994, 69.423569],
  [53.561823999999994, 69.371039],
  [53.604281, 69.37356799999999],
  [53.608306999999996, 69.42594],
  [53.632873, 69.435135],
  [53.651916, 69.476226],
  [53.652975999999995, 69.504842],
  [53.672782, 69.50460199999999],
  [53.67172, 69.811714],
  [53.63588, 69.810298],
  [53.636869999999995, 69.848449],
  [53.530291, 69.844115],
  [53.527494, 69.875841],
  [53.53787, 69.877393],
  [53.528472, 69.929754],
  [53.534141999999996, 69.974149],
  [53.510555, 70.02648099999999],
  [53.516189999999995, 70.070869],
  [53.446396, 70.072366],
  [53.412472, 70.01697399999999],
  [53.37569, 70.01381599999999],
  [53.358717, 69.990127],
  [53.353829999999995, 70.18595599999999],
  [53.367020999999994, 70.193906],
  [53.366032999999995, 70.217597],
  [53.301711999999995, 70.29932],
  [53.230886, 70.331929],
  [53.226971, 70.377561],
  [53.239895999999995, 70.454844],
  [53.318185, 70.45248099999999],
  [53.345883, 70.585388],
  [53.425115, 70.583276],
  [53.422081, 71.02452699999999],
  [53.411736999999995, 71.02112],
  [53.413228999999994, 71.065434],
  [53.330647, 71.01921899999999],
  [53.329530999999996, 71.14071899999999],
  [53.319052, 71.151489],
  [53.308541999999996, 71.256902],
  [53.327999999999996, 71.289029],
  [53.356908, 71.400435],
  [53.386792, 71.42510899999999],
  [53.402639, 71.515746],
  [53.329206, 71.577882],
  [53.213701, 71.74368199999999],
  [53.129597, 71.69004699999999],
  [53.068977, 71.70809799999999],
  [53.051759, 71.658816],
  [53.007366999999995, 71.663403],
  [52.946186999999995, 71.720531],
  [52.91892, 72.013363],
  [52.91652, 72.250863],
  [52.989594999999994, 72.325085],
  [52.987373, 72.525392],
  [53.018263999999995, 72.66507299999999],
  [53.003867, 72.71745299999999],
  [53.021038, 72.749865],
  [53.024432, 72.845681],
  [52.933834, 72.847599],
  [52.911113, 72.889889],
  [52.717099999999995, 72.91606],
  [52.748121999999995, 73.069172],
  [52.724719, 73.204514],
  [52.705344, 73.25756],
  [52.712306, 73.312538],
  [52.701408, 73.397284],
  [52.65784, 73.435892],
  [52.66598, 73.480051],
  [52.607668, 73.537556],
  [52.595586999999995, 73.919952],
  [52.532213, 73.920448],
  [52.530010999999995, 74.001048],
  [51.924405, 74.00643199999999],
  [51.923527, 74.02539399999999],
  [51.891189, 74.019181],
  [51.883306, 73.965542],
  [51.798113, 73.81947],
  [51.730886, 73.78843599999999],
  [51.676511999999995, 73.803623],
  [51.639233, 73.79355199999999],
  [51.617650999999995, 73.81093],
  [51.623324999999994, 73.946975],
  [51.638346, 73.99705],
  [51.633021, 74.01928199999999],
  [51.599371, 74.046972],
  [51.551548, 74.23539199999999],
  [51.522873999999995, 74.251983],
  [51.52559, 74.161926],
  [51.501490999999994, 74.103719],
  [51.495259, 73.99426799999999],
  [51.427713999999995, 73.805557],
  [51.380964999999996, 73.79864599999999],
  [51.343705, 73.698764],
  [51.275665, 73.668576],
  [51.298584999999996, 73.51571299999999],
  [51.312557, 73.48769],
  [51.27281, 73.449828],
  [51.274522, 73.416246],
  [51.307066, 73.4012],
  [51.305023999999996, 73.370807],
  [51.28758, 73.368375],
  [51.279571, 73.342218],
  [51.273098999999995, 73.287122],
  [51.307418, 73.23372499999999],
  [51.286615999999995, 73.220647],
  [51.275779, 73.162937],
  [51.211883, 73.20501999999999],
  [51.222989999999996, 73.25222699999999],
  [51.203913, 73.282228],
  [51.122273, 73.33093099999999],
  [51.124626, 73.35079499999999],
  [51.165803999999994, 73.365354],
  [51.161555, 73.411427],
  [51.174476999999996, 73.443691],
  [51.115719, 73.578356],
  [51.087509, 73.49746999999999],
  [51.029978, 73.419169],
  [50.998763, 73.206602],
  [50.985552, 73.182597],
  [50.922878999999995, 73.179509],
  [50.885403, 73.023684],
  [50.859359, 72.986306],
  [50.885191, 72.79661399999999],
  [50.835589999999996, 72.73380999999999],
  [50.748262, 72.65982699999999],
  [50.733681999999995, 72.52689699999999],
  [50.708776, 72.53131499999999],
  [50.70691, 72.516291],
  [50.743091, 72.49637299999999],
  [50.669944, 72.399614],
  [50.622718, 72.460279],
  [50.594657, 72.476018],
  [50.564498, 72.452709],
  [50.556455, 72.382485],
  [50.593576, 72.276664],
  [50.55789, 72.23491299999999],
  [50.537732, 72.181349],
  [50.452773, 72.198061],
  [50.443884, 72.125725],
  [50.413905, 72.12897099999999],
  [50.455487, 72.047435],
  [50.467670999999996, 71.974842],
  [50.465948999999995, 71.80913799999999],
  [50.428875, 71.799765],
  [50.430926, 71.710807],
  [50.446956, 71.712514],
  [50.455341999999995, 71.737935],
  [50.494895, 71.777074],
  [50.561873, 71.78755199999999],
  [50.587727, 71.814852],
  [50.614852, 71.806708],
  [50.630756999999996, 71.768332],
  [50.674994, 71.83421299999999],
  [50.688815999999996, 71.83703799999999],
  [50.705214999999995, 71.814705],
  [50.694556, 71.795935],
  [50.754624, 71.731529],
  [50.753426, 71.662528],
  [50.790167999999994, 71.644252],
  [50.77465, 71.550584],
  [50.791754999999995, 71.525832],
  [50.760301, 71.474254],
  [50.726203, 71.334223],
  [50.654711999999996, 71.33225],
  [50.653588, 71.233588],
  [50.612251, 71.20847499999999],
  [50.614442999999994, 71.134044],
  [50.558239, 71.05605299999999],
  [50.512982, 71.05736499999999],
  [50.473076999999996, 70.942255],
  [50.478851999999996, 70.853237],
  [50.449256999999996, 70.805909],
  [50.442184999999995, 70.771541],
  [50.256232, 70.74821899999999],
  [50.238864, 70.724086],
  [50.294737999999995, 70.65203699999999],
  [50.236464, 70.46711599999999],
  [50.152936, 70.546955],
  [50.141343, 70.527545],
  [50.074847999999996, 70.338785],
  [50.040909, 70.12683799999999],
  [50.057711, 70.105345],
  [50.082504, 70.135975],
  [50.110989, 70.109948],
  [50.194872, 70.166862],
  [50.221903, 70.141927],
  [50.207319, 70.12712599999999],
  [50.203699, 70.08053699999999],
  [50.23435, 70.08851899999999],
  [50.259178, 70.051007],
  [50.238001, 69.977098],
  [50.20986, 69.719184],
  [50.192992, 69.690899],
  [50.198491, 69.589745],
  [50.275124, 69.58788],
  [50.306317, 69.53976],
  [50.299625, 69.511366],
  [50.321438, 69.492898],
  [50.335052999999995, 69.44032899999999],
  [50.350494, 69.462931],
  [50.386981999999996, 69.461347],
  [50.401441999999996, 69.433787],
  [50.366448999999996, 69.44221399999999],
  [50.34227, 69.424285],
  [50.403977999999995, 69.36300899999999],
  [50.38489, 69.34389],
  [50.369854, 69.273414],
  [50.354392999999995, 69.253145],
  [50.301857999999996, 69.25628999999999],
  [50.302780999999996, 69.285879],
  [50.267754, 69.287572],
  [50.255503999999995, 69.31279099999999],
  [50.244471, 69.299312],
  [50.24641, 69.26061299999999],
  [50.232265, 69.21991299999999],
  [50.237925999999995, 69.19479799999999],
  [50.250383, 69.201402],
  [50.240009, 69.17997799999999],
  [50.276426, 69.169079],
  [50.259485999999995, 69.148909],
  [50.274283999999994, 69.11236699999999],
  [50.254377, 69.149006],
  [50.188610999999995, 69.14000899999999],
  [50.207091, 69.077157],
  [50.185907, 69.075313],
  [50.197299, 69.04099599999999],
  [50.188911999999995, 68.920754],
  [50.209312999999995, 68.84299299999999],
  [50.196718, 68.82512899999999],
  [50.218787, 68.77227599999999],
  [50.217759, 68.745021],
  [50.237338, 68.733117],
  [50.24507, 68.706746],
  [50.249313, 68.632688],
  [50.259584, 68.63693599999999],
  [50.268083, 68.676515],
  [50.316053, 68.721846],
  [50.357547999999994, 68.711576],
  [50.406558999999994, 68.720477],
  [50.433186, 68.751711],
  [50.463724, 68.740599],
  [50.544211, 68.757849],
  [50.582051, 68.81524399999999],
  [50.799495, 68.807289],
  [50.794475999999996, 68.503635],
  [50.762631, 68.46902399999999],
  [50.726251, 68.320855],
  [50.661677, 68.249764],
  [50.621075999999995, 68.12861699999999],
  [50.641306, 68.071591],
  [50.664322999999996, 68.05112],
  [50.663891, 67.901939],
  [50.611174999999996, 67.93413199999999],
  [50.554592, 67.933345],
  [50.497389, 67.83145],
  [50.485552999999996, 67.78434],
  [50.481964999999995, 67.64926299999999],
  [50.492675999999996, 67.56371],
  [50.469496, 67.478655],
  [50.451083, 67.49344099999999],
  [50.383561, 67.131256],
  [50.530155, 67.063951],
  [50.545437, 67.09387],
  [50.620669, 67.108676],
  [50.658733, 66.991674],
  [50.649789999999996, 66.86434799999999],
  [50.613378999999995, 66.72519199999999],
  [50.637498, 66.666265],
  [50.6284, 66.64091499999999],
  [50.669613999999996, 66.50298099999999],
  [50.710059, 66.43285999999999],
  [50.685688999999996, 66.314517],
  [50.82723, 66.239627],
  [50.826428, 66.200138],
  [50.804254, 66.180607],
  [50.81218, 66.16036799999999],
  [50.789099, 66.13532099999999],
  [50.799672, 66.109977],
  [50.814997, 66.091244],
  [50.823065, 66.102383],
  [50.83379, 66.075026],
  [50.855019999999996, 66.070071],
  [50.871683999999995, 66.088264],
  [50.885914, 66.056586],
  [50.892131, 66.07423],
  [50.928103, 66.00879499999999],
  [65.99381699999999],
  [50.949926999999995, 65.949666, 50.960169],
  [50.943202, 65.94165699999999],
  [50.965865, 65.863585],
  [50.866035, 65.813144],
  [50.849706, 65.788033],
  [50.847328, 65.661789],
  [50.826744999999995, 65.61911599999999],
  [50.895018, 65.578576],
  [50.999809, 65.611414],
  [51.054437, 65.60933899999999],
  [51.083551, 65.63089099999999],
  [51.135435, 65.559943],
  [51.204677, 65.424628],
  [51.242756, 65.479826],
  [51.302434999999996, 65.401636],
  [51.327799999999996, 65.386883],
  [51.403330999999994, 65.545705],
  [51.440568, 65.459729],
  [51.513796, 65.53672],
  [51.62811, 65.578323],
  [51.682840999999996, 65.523124],
  [51.68466, 65.384006],
];
