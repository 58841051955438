import cubejs from "@cubejs-client/core";
import jwt from "jsonwebtoken";

// export default cubejs(process.env.REACT_APP_CUBEJS_TOKEN, {
//   apiUrl: process.env.REACT_APP_API_URL
// });

export default (options) => {
  const cubejsToken = jwt.sign(options, process.env.REACT_APP_CUBEJS_TOKEN, {
    expiresIn: "30d",
  });

  return cubejs(cubejsToken, {
    apiUrl: process.env.REACT_APP_API_URL,
  });
};
