import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import operational from "../modules/operational/reducers";
import * as monitoring from "../modules/monitoring/reducers";
import { getTargetPath } from "../utils/login";

const REDIRECT = "scvko/redirect/REDIRECT";
const LOGIN = "scvko/auth/LOGIN";
const LOGOUT = "scvko/auth/LOGOUT";

const redirectTo = handleActions(
  {
    [REDIRECT]: { next: (state) => null },
    [LOGIN]: {
      // next: (state, { payload }) => getTargetPath("index") || "/",
      next: (state, { payload }) => "/",
    },
    [LOGOUT]: { next: (state, { payload }) => "/login" },
  },
  null
);

export default combineReducers({
  redirect: combineReducers({
    redirectTo,
  }),
  operational,
  monitoring: combineReducers(monitoring),
});
