import moment from "moment";
import _ from "lodash";
import "moment/locale/ru";
moment.locale("ru");
const CalculateDtp = (r) => {
  let mapObjects = [];
  mapObjects = r.districts.map((item) => {
    return [mapObjects, ...item.data];
  });
  mapObjects = mapObjects.flat();
  mapObjects = mapObjects.filter((i) => i.date);
  let rayons = r.districts.map((item) => {
    return {
      category: item.name,
      value: item.data.length,
    };
  });
  rayons = rayons.filter((i) => i.value);

  let violationCategories = r.districts.map((item) => {
    return item.data.map((d) => d.narushenie);
  });
  violationCategories = violationCategories.flat();
  violationCategories = [...new Set(violationCategories)];
  let catViolation = violationCategories.map((element) => {
    let count = 0;
    mapObjects.forEach((obj) => {
      if (obj.narushenie === element) count++;
    });
    return {
      category: element,
      value: count,
    };
  });

  // let pieMonth = r.districts.map(item => {
  //   return item.data.map(d => moment(d.date, "YYYY-MM-DD").format("YYYY/MM"));
  // });
  // pieMonth = pieMonth.flat();
  // pieMonth = [...new Set(pieMonth)];
  // let catPieChart = pieMonth.map(element => {
  //   let count = 0;
  //   mapObjects.forEach(obj => {
  //     if (moment(obj.date, "YYYY-MM-DD").format("YYYY/MM") === element) count++;
  //   });
  //   return {
  //     category: moment(element, "YYYY/MM").format("MMMM"),
  //     value: count
  //   };
  // });

  let dayCat = r.districts.map((item) => {
    return item.data.map((d) => d.vremyaSutok);
  });
  dayCat = dayCat.flat();
  dayCat = [...new Set(dayCat)];
  let timeDay = dayCat.map((element) => {
    let count = 0;
    mapObjects.forEach((obj) => {
      if (obj.vremyaSutok === element) count++;
    });
    return {
      category: element,
      value: count,
    };
  });

  let groud_data = _.groupBy(mapObjects, "vremyaSutok");
  let lights = Object.keys(groud_data).map((key) => {
    return {
      category: key,
      group: _.groupBy(groud_data[key], "lights"),
    };
  });
  lights.forEach((el) => {
    Object.keys(el.group).forEach((key) => {
      el[key] = el.group[key].length;
    });
  });
  lights.forEach((el) => {
    delete el.group;
  });

  let weatherCat = r.districts.map((item) => {
    return item.data.map((d) => d.usloviya);
  });
  weatherCat = weatherCat.flat();
  weatherCat = [...new Set(weatherCat)];
  let weatherCondition = weatherCat.map((element) => {
    let count = 0;
    mapObjects.forEach((obj) => {
      if (obj.usloviya === element) count++;
    });
    return {
      category: element,
      value: count,
    };
  });
  return {
    mapObjects,
    rayons,
    catViolation,
    // catPieChart: [
    //   {
    //     category: "123",
    //     value: 123
    //   },
    //   {
    //     category: "qwe",
    //     value: 345345
    //   }
    // ],
    timeDay,
    lights,
    weatherCondition,
  };
};
export default CalculateDtp;
