import React, { Component } from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import * as PropTypes from "prop-types";

export default class LoadingSpinner extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.any,
    size: PropTypes.number,
    borderWidth: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object
  };

  static defaultProps = {
    size: 32,
    borderWidth: 4
  };

  render() {
    const {
      loading,
      children,
      size,
      borderWidth,
      className,
      style
    } = this.props;
    const classes = className || "flex-full flex layout-centered";
    return loading ? (
      <div
        className={classNames(styles.LoadingSpinnerWrapper, classes)}
        style={style}
      >
        <div
          className={styles.LoadingSpinner}
          style={{ width: size, height: size, borderWidth }}
        />
      </div>
    ) : (
      <React.Fragment>{children}</React.Fragment>
    );
  }
}
