//import store from "../store";
import { isJson } from "./helpers";
//import { logoutUser } from "../redux/auth";
import { notification } from "antd";
import { isLoginUrl } from "./login";

const codeMessage = {
  401: "Пользователь не авторизован",
  403: "Пользователь авторизован, но доступ запрещен.",
  404: "Запрос делается для записи, которая не существует",
  500: "На сервере произошла ошибка.",
};

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const errortext = codeMessage[response.status] || response.statusText;

  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
};

function parseText(text) {
  return text.length ? (isJson(text) ? JSON.parse(text) : text) : {};
}

const defaultOptions = { credentials: "include" };

export default function performRequest(url, options) {
  const token = localStorage.getItem("token");
  if (token !== null) {
    options = {
      ...options,
      headers: { Authorization: "Bearer " + token },
    };
  }

  const newOptions = { ...defaultOptions, ...options };

  if (
    newOptions.method === "POST" ||
    newOptions.method === "PATCH" ||
    newOptions.method === "PUT" ||
    newOptions.method === "DELETE"
  ) {
    if (
      !(newOptions.body instanceof FormData) &&
      !(newOptions.body instanceof URLSearchParams)
    ) {
      newOptions.headers = {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      newOptions.headers = {
        Accept: "application/json",
        ...newOptions.headers,
      };
    }
  }

  console.log("options", options);

  return fetch(url, newOptions)
    .then(checkStatus)
    .then((response) => response.text())
    .then((text) => parseText(text))
    .catch(async (err) => {
      console.error("request error", err);

      notification.error({
        key: "requestFailedNotification",
        message: "Внимание!",
        description:
          "Не удалось загрузить данные. Попробуйте обновить страницу.",
      });

      const { name: status, response } = err;

      if (!response) {
        return Promise.reject(err);
      }

      const isLogin = isLoginUrl(response.url);
      const responseText = await response.text();
      const responseJSON = parseText(responseText);

      if (status === 401 && !isLogin) {
        // store.dispatch(logoutUser());
      }

      return Promise.reject({ status, ...responseJSON });
    });
}
