import { createSelector } from "reselect";
import _ from "lodash";
import moment from "moment";
//import { COLORS } from "components//common/Constants";

// const EXECUTOR_TYPE_CITY = 1;

export const getLoadingCrime = (state) => state.operational.crime.loading;
export const getCrimeStartDate = (state) => state.operational.crime.startDate;
export const getCrimeEndDate = (state) => state.operational.crime.endDate;
export const getCrime = (state) => state.operational.crime.crime;
export const getCrimeFilters = (state) => state.operational.crime.filters;
export const getSelectedCrime = (state) => state.operational.crime.selected;
export const getFeaturedCategories = (state) =>
  state.operational.crime.featuredCategories;
export const getSelectedRegion = (state) =>
  state.operational.crime.selectedRegion;

export const getAllCrime = createSelector(
  [getCrime, getCrimeFilters],
  (crime, filters) => {
    const crimeAll = _.find(crime, { sphere: "Безопасность" });
    if (crimeAll) {
      const branch = _.find(crimeAll.branches, {
        branch: "Общественная безопасность",
      });
      let crimes = branch.crimeCategories.map((cat) =>
        cat.crimes.map((crime, i) => ({
          ...crime,
          x: parseFloat(crime.x),
          y: parseFloat(crime.y),
          date: moment(crime.date_sover).format("YYYY/MM/DD"),
        }))
      );

      let arr = [];
      const items = crimes.reduce((a, b) => [...a, ...b], []);
      items.forEach((element) => {
        let count = 0;
        Object.keys(filters).forEach((key) => {
          if (filters[key] === element[key]) count++;
        });
        if (count === Object.keys(filters).length) arr.push(element);
      });
      return arr;
    }
    return [];
  }
);

export const getAllCrimeByDistrict = createSelector([getAllCrime], (crime) =>
  _.groupBy(crime, "district")
);

export const getAllCrimeByDistrictValues = createSelector(
  [getAllCrimeByDistrict],
  (crimeByDistrict) => {
    return Object.keys(crimeByDistrict).map((district) => ({
      category: district,
      value: crimeByDistrict[district].length,
    }));
  }
);

export const getAllCrimeByDistrictLabels = createSelector(
  [getAllCrimeByDistrict],
  (crimeByDistrict) => {
    return Object.keys(crimeByDistrict).map((key) => ({
      label: key,
      value: key,
    }));
  }
);

export const getAllCrimeByIncision = createSelector([getAllCrime], (crime) =>
  _.groupBy(crime, "incision")
);

export const getAllCrimeByIncisionLabels = createSelector(
  [getAllCrimeByIncision],
  (crimeAllIncision) => {
    return Object.keys(crimeAllIncision).map((key) => ({
      label: key,
      value: key,
    }));
  }
);

export const getAllCrimeByIncisionValues = createSelector(
  [getAllCrimeByDistrict, getSelectedRegion],
  (crimeByRegion, selectedRegion) => {
    if (!selectedRegion) return [];
    let data = crimeByRegion[selectedRegion];
    data = _.groupBy(data, "incision");
    data = Object.keys(data).map((key) => {
      return {
        category: key,
        value: data[key].length,
      };
    });
    return data.sort((a, b) => a.value - b.value);
  }
);

export const getAllCrimeByOrgan = createSelector([getAllCrime], (crime) =>
  _.groupBy(crime, "organ")
);

export const getAllCrimeByOrganValues = createSelector(
  [getAllCrimeByOrgan],
  (crimeByOrgan) => {
    return Object.keys(crimeByOrgan).map((organ) => ({
      category: organ,
      value: crimeByOrgan[organ].length,
    }));
  }
);

export const getAllCrimeByCategory = createSelector([getAllCrime], (crime) =>
  _.groupBy(crime, "crName")
);

export const getAllCrimeByCategoryLabels = createSelector(
  [getAllCrimeByCategory],
  (crimeByCategory) => {
    return Object.keys(crimeByCategory).map((key) => ({
      label: key,
      value: key,
    }));
  }
);

export const getAllCrimeByCategoryValues = createSelector(
  [getAllCrimeByCategory],
  (crimeByCategory) => {
    return Object.keys(crimeByCategory).map((cat) => ({
      category: cat,
      value: crimeByCategory[cat].length,
    }));
  }
);

const getGraphCrimes = (index) => {
  return (crimeByCategory, featuredCategories) => {
    let crimes =
      crimeByCategory && crimeByCategory[featuredCategories[index]]
        ? crimeByCategory[featuredCategories[index]]
        : [];
    const crimeByDate = _.groupBy(crimes, "date");
    const data = Object.keys(crimeByDate)
      .map((date) => ({
        date: Date.parse(date),
        value: crimeByDate[date].length,
      }))
      .sort((a, b) => a.date - b.date);
    if (data.length === 1) {
      const first = data[0];
      const firstDate = first.date;
      first.date = moment(firstDate).subtract(12, "hours").valueOf();
      data.push({
        date: moment(firstDate).add(12, "hours").valueOf(),
        value: first.value,
      });
    }
    return data;
  };
};

export const getFirstGraphCrimes = createSelector(
  [getAllCrimeByCategory, getFeaturedCategories],
  getGraphCrimes(0)
);

export const getSecondGraphCrimes = createSelector(
  [getAllCrimeByCategory, getFeaturedCategories],
  getGraphCrimes(1)
);

export const getThirdGraphCrimes = createSelector(
  [getAllCrimeByCategory, getFeaturedCategories],
  getGraphCrimes(2)
);

export const getFourthGraphCrimes = createSelector(
  [getAllCrimeByCategory, getFeaturedCategories],
  getGraphCrimes(3)
);

export const getLoadingAppeals = (state) => state.operational.appeals.loading;
export const getAppealsStartDate = (state) =>
  state.operational.appeals.startDate;
export const getAppealsEndDate = (state) => state.operational.appeals.endDate;
export const getAppeals = (state) => state.operational.appeals.appeals;
export const getAppealFilters = (state) => state.operational.appeals.filters;
export const getSelectedAppeal = (state) => state.operational.appeals.selected;

export const getAppealsBySource = createSelector([getAppeals], (appeals) =>
  _.groupBy(appeals, "formlabel")
);

export const getAppealsBySourceLabels = createSelector(
  [getAppealsBySource],
  (appealsBySource) => {
    return Object.keys(appealsBySource).map((key) => ({
      label: key,
      value: key,
    }));
  }
);

export const getAppealsBySourceValues = createSelector(
  [getAppealsBySource],
  (appealsBySource) => {
    return Object.keys(appealsBySource).map((source) => ({
      category: source,
      value: appealsBySource[source].length,
    }));
  }
);

export const getAppealsByType = createSelector([getAppeals], (appeals) =>
  _.groupBy(appeals, "typelabel")
);

export const getAppealsByTypeLabels = createSelector(
  [getAppealsByType],
  (appealsByType) => {
    return Object.keys(appealsByType).map((key) => ({
      label: key,
      value: key,
    }));
  }
);

export const getAppealsByTypeValues = createSelector(
  [getAppealsByType],
  (appealsByType) => {
    return Object.keys(appealsByType).map((type) => ({
      category: type,
      value: appealsByType[type].length,
    }));
  }
);

export const getAppealsByRegion = createSelector([getAppeals], (appeals) => {
  const cityAppeals = appeals.map((e) => ({
    ...e,
    executorcity: e.executorcity ? e.executorcity : "Не определён",
  }));
  return _.groupBy(cityAppeals, "executorcity");
});

export const getAppealsByRegionLabels = createSelector(
  [getAppealsByRegion],
  (appealsByRegion) => {
    return Object.keys(appealsByRegion).map((key) => {
      return {
        label: !key.length || key === "null" ? "Не определён" : key,
        value: key,
      };
    });
  }
);

export const getAppealsByRegionValues = createSelector(
  [getAppealsByRegion],
  (appealsByRegion) => {
    return Object.keys(appealsByRegion).map((region) => ({
      category: region.length ? region : "Не определён",
      value: appealsByRegion[region].length,
    }));
  }
);

export const getAppealsByExecutor = createSelector([getAppeals], (appeals) =>
  _.groupBy(appeals, "executor")
);

export const getAppealsByExecutorLabels = createSelector(
  [getAppealsByExecutor],
  (appealsByExecutor) => {
    return Object.keys(appealsByExecutor).map((key) => ({
      label: key,
      value: key,
    }));
  }
);

export const getAppealsByExecutorValues = createSelector(
  [getAppealsByExecutor],
  (appealsByExecutor) => {
    return Object.keys(appealsByExecutor).map((executor) => ({
      category: executor,
      value: appealsByExecutor[executor].length,
    }));
  }
);

export const getAppealsByExecutorStatus = createSelector(
  [getAppeals],
  (appeals) => _.groupBy(appeals, "executorGroupId")
);
export const getAppealsByExecutorStatus1 = createSelector(
  [getAppealsByExecutorStatus],
  (appealsExecutor) => {
    const data = _.groupBy(appealsExecutor[1], "executor");
    let a = _.groupBy(appealsExecutor[0], "executor")[
      "Общественная приёмная акима ВКО"
    ]
      ? _.groupBy(appealsExecutor[0], "executor")[
          "Общественная приёмная акима ВКО"
        ].length
      : 0;
    let q = _.groupBy(appealsExecutor[0], "executor")[""]
      ? _.groupBy(appealsExecutor[0], "executor")[""].length
      : 0;
    return Object.keys(data).map((executor) => ({
      category: executor,
      value: _.includes(
        data[executor].map((el) => el.executor),
        'ГУ "Аппарат акима ВКО"'
      )
        ? data[executor].length + a + q
        : data[executor].length,
    }));
  }
);
export const getAppealsByExecutorStatus2 = createSelector(
  [getAppealsByExecutorStatus],
  (appealsExecutor) => {
    const data = _.groupBy(appealsExecutor[2], "executor");
    return Object.keys(data).map((executor) => ({
      category: executor,
      value: data[executor].length,
    }));
  }
);
export const getAppealsByExecutorStatus3 = createSelector(
  [getAppealsByExecutorStatus],
  (appealsExecutor) => {
    const data = _.groupBy(appealsExecutor[3], "executor");
    return Object.keys(data).map((executor) => ({
      category: executor,
      value: data[executor].length,
    }));
  }
);

export const getAppealsByExecutorExpired = createSelector(
  [getAppeals],
  (appeals) =>
    _.groupBy(
      appeals.filter((appeal) => appeal.expired === 0),
      "executor"
    )
);

export const getAppealsByExecutorExpiredValues = createSelector(
  [getAppealsByExecutorExpired],
  (appealsByExecutorExpired) => {
    return Object.keys(appealsByExecutorExpired).map((executor) => {
      let expiredFinished = [],
        expiredNotFinished = [];
      appealsByExecutorExpired[executor].forEach((appeal) => {
        if (
          appeal.statusid === 552 ||
          appeal.statuslabel.toLowerCase() === "ответ доставлен заявителю"
        ) {
          expiredFinished.push(appeal);
        } else if (appeal.status === "Under_consideration") {
          expiredNotFinished.push(appeal);
        }
      });
      return {
        category: executor,
        value: expiredFinished.length,
        value1: expiredNotFinished.length,
      };
    });
  }
);

export const getAppealsByTheme = createSelector([getAppeals], (appeals) =>
  _.groupBy(appeals, "theme")
);

export const getAppealsByThemeLabels = createSelector(
  [getAppealsByTheme],
  (appealsByTheme) => {
    return Object.keys(appealsByTheme).map((key) => ({
      label: key,
      value: key,
    }));
  }
);

export const getAppealsByThemeValues = createSelector(
  [getAppealsByTheme],
  (appealsByTheme) => {
    return Object.keys(appealsByTheme).map((theme) => ({
      category: theme,
      value: appealsByTheme[theme].length,
    }));
  }
);

export const getAppealsByAssessment = createSelector(
  [getAppeals],
  (appeals) => {
    appeals.forEach((el) => {
      if (el.qualityResultLabel === "Не определена")
        el.qualityResultLabel = "Нет оценки";
    });
    return _.groupBy(appeals, "qualityResultLabel");
  }
);

export const getAppealsByAssessmentValuesCommon = createSelector(
  [getAppealsByAssessment],
  (appealsByAssessment) => {
    const {
      "Нет оценки": withoutAssessment,
      ...withAssessment
    } = appealsByAssessment;

    let withAssessmentValues = [];
    Object.keys(withAssessment).forEach((category) => {
      withAssessmentValues = [
        ...withAssessmentValues,
        ...withAssessment[category],
      ];
    });
    return [
      {
        category: "Нет оценки",
        value: withoutAssessment ? withoutAssessment.length : 0,
        color: "#ababab",
      },
      {
        category: "Есть оценка",
        value: withAssessmentValues.length,
        color: "#0288d1",
      },
    ];
  }
);

export const getAppealsByAssessmentValuesSpecific = createSelector(
  [getAppealsByAssessment],
  (appealsByAssessment) => {
    const {
      "Нет оценки": withoutAssessment,
      ...withAssessment
    } = appealsByAssessment;
    return Object.keys(appealsByAssessment).map((assessment) => ({
      category: assessment,
      value: appealsByAssessment[assessment].length,
    }));
  }
);

export const getAppealsAssessmentLabels = createSelector(
  [getAppealsByAssessment],
  (appealsAssessment) => {
    return Object.keys(appealsAssessment).map((key) => {
      return {
        label: !key.length || key === "null" ? "Не определён" : key,
        value: key,
      };
    });
  }
);

const getStatusLabels = createSelector([getAppeals], (appeals) => {
  const appealsByStatus = _.groupBy(appeals, "statuslabel");
  return Object.keys(appealsByStatus);
});

export const getAppealsByStatus = createSelector(
  [getStatusLabels, getAppealsByExecutor],
  (statuses, appealsByExecutor) => {
    const headers = [{ label: "Исполнитель", key: "executor" }];
    statuses.forEach((status) => {
      headers.push({ label: status, key: status });
    });

    const objects = [];
    for (const executor in appealsByExecutor) {
      const appealsByStatus = _.groupBy(
        appealsByExecutor[executor],
        "statuslabel"
      );
      const counts = {};
      for (const status in appealsByStatus) {
        counts[status] = appealsByStatus[status].length;
      }
      objects.push({ executor, ...counts });
    }
    return { headers, objects };
  }
);
export const getAppealsByStatusValues = createSelector(
  [getStatusLabels, getAppeals],
  (statuses, appeals) => {
    const result = statuses.map((el) => {
      return {
        category: el,
        value: appeals.filter((obj) => obj.statuslabel === el).length,
      };
    });

    return result;
  }
);
