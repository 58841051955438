import React from "react";
import styled from "styled-components";
import { Layout, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import MainPage from "pages/MainPage/MainPage";
import { Link } from "react-router-dom";
import store from "../../store";
import { logoutUser } from "../../redux/auth";
import { useTranslation } from "react-i18next";
import i18n from "./../../i18n";
let { Header } = Layout;

export default function HeaderComponent({
  toggleSideMenu,
  showMenu,
  isMobile,
}) {
  const [color, setColor] = React.useState("");
  const styles = {
    color: color,
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const { t, i18n } = useTranslation();

  return (
    <MyHeader>
      <div
        className="side-menu-toggle"
        onClick={() => toggleSideMenu(!showMenu)}
      >
        <img src="/img/menu.svg" />
        <span>{t("sideMenu.menu")}</span>
      </div>
      {!isMobile && (
        <>
          <LogoWrapper to="/">
            <img src="/img/logo.png" />
            <div
              className="text"
              onMouseEnter={() => setColor("green")}
              onMouseLeave={() => setColor("")}
            >
              {t("mainPage.information")}
            </div>
          </LogoWrapper>

          <Right>
            <div style={{ marginRight: "5px" }}>
              <button
                className="language-button"
                onClick={() => changeLanguage("ru")}
              >
                РУС
              </button>
              <button
                className="language-button"
                onClick={() => changeLanguage("kz")}
              >
                ҚАЗ
              </button>
            </div>
            <Weather>
              <div>
                <div className="city">г. Кокшетау</div>
              </div>
            </Weather>
            <User>
              <Avatar icon={<UserOutlined />} />
              <div className="info">
                <div>Бауржан Рапиков</div>
                <div>{t("mainPage.profile")}</div>
              </div>
            </User>
            <Butt>
              <div className="nav-wrap1">
                <span onClick={() => store.dispatch(logoutUser())}>
                  {t("mainPage.exit")}
                </span>
              </div>
            </Butt>
          </Right>
        </>
      )}
    </MyHeader>
  );
}

let MyHeader = styled(Header)`
  height: 80px;
  background: #24262f;
  display: flex;
  align-items: center;
  padding: 10px 30px;
`;

let LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 20px;
  img {
    margin-right: 10px;
    width: 38px;
  }
  .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    max-width: 280px;
  }
`;

let Right = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
let Butt = styled.div`
  margin-left: 20px;
  line-height: 1;
  transition: 200ms;
  cursor: pointer;
  color: white;
  padding: 10px 15px;
  background: #1a1b21;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

let Weather = styled.div`
  img {
    margin-right: 10px;
  }
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;

  .city {
    font-size: 12px;
    line-height: 14px;
    color: #ffffffb3;
  }
`;

let User = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #fff;
  .info {
    margin-left: 10px;
    line-height: normal;
  }
`;
