import React, { useState, useEffect } from "react";
import { SelectList } from "./components/SelectList/SelectList";
import { TableComponent } from "./components/TableComponent/TableComponent";
import { MapComponent } from "./components/MapComponent/MapComponent";
import request from "utils/request";

export const RouteSchedule = () => {
  const [tableData, setTableData] = useState({
    data: [],
    filteredData: [],
  });
  const [transportersData, setTransportersData] = useState({
    data: [],
    searchData: [],
  });
  const [settlementsData, setSettlementsData] = useState({
    data: [],
    searchData: [],
  });

  const requestTable = async () => {
    const result = await request("/route-schedule");
    setTableData((prev) => ({
      ...prev,
      data: result,
      filteredData: result,
    }));
  };
  const requestTransporters = async () => {
    const result = await request("/transporter");
    setTransportersData((prev) => ({
      ...prev,
      data: result,
      searchData: result,
    }));
  };
  const requestSettlement = async () => {
    const result = await request("/settlement");
    setSettlementsData((prev) => ({
      ...prev,
      data: result,
      searchData: result,
    }));
  };

  useEffect(() => {
    requestTable();
    requestTransporters();
    requestSettlement();
  }, []);
  return (
    <div>
      <SelectList
        transportersData={transportersData}
        setTransportersData={setTransportersData}
        settlementsData={settlementsData}
        setSettlementsData={setSettlementsData}
        tableData={tableData}
        setTableData={setTableData}
      />
      <TableComponent tableData={tableData} />
      {tableData.filteredData.length !== 0 && (
        <MapComponent tableData={tableData} />
      )}
    </div>
  );
};
