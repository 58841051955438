import { createAction } from "redux-actions";
import moment from "moment";

import { searchAppeals } from "../../services/operational";

export const FETCH_DATA = "scvko/monitoring/FETCH_DATA";
export const fetchData = () => async (dispatch, getState) => {
  const [appeals] = await Promise.all([searchAppeals({})]);
  dispatch(saveAppeals({ appeals }));
};

export const SAVE_APPEALS = "scvko/monitoring/SAVE_APPEALS";
export const saveAppeals = createAction(SAVE_APPEALS);
