import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { getAuthority } from "utils/authority";
import { saveTargetPath } from "../utils/login";

export const withAuthorized = (WrappedComponent) => {
  class Authorized extends Component {
    render() {
      const authority = getAuthority();
      if (!authority.includes("AUTHED")) {
        saveTargetPath();
        return <Redirect to="/login" />;
      }
      return <WrappedComponent {...this.props} />;
    }
  }
  return Authorized;
};
