import React, { Fragment } from "react";
import MapWrap from "components/common/MapWrap";
import PieChartWrap from "components/charts/PieChartWrap";
import TableComponent from "components/Table";
import FilterSelect from "components/FilterSelect";
import LoadingSpinner from "components/LoadingSpinner";
import CalculateSchool from "../modules/Social/CalculateSchool";
import request from "../utils/request";
import { Col, Row, Modal, Switch, Button, Table } from "antd";
import LoadingCss from "components/LoadingCss";
import { formatNumber } from "utils/helpers";
import HorizontalBarChart from "components/charts/HorizontalBarChart";
import { Container, OrangeBox } from "components/common/Elements";
import { DdoItem } from "pages/PreSchoolEducation/Ddo";
import { SaveOutlined } from "@ant-design/icons";
class FirstPageSchool extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      filters: {},
      visible: false,
      filteredData: {
        mapCoords: [],
        stat: {},
        pieData: [],
        pieStat: {},
      },
      constantData: {},
      selectFilters: [],
      records: [],
      shiftSwitch: true,
      langType: true,
    };
  }

  componentDidMount() {
    this.fetchSchools();
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      records: [],
    });
  };

  filterSelectOptions = (r) => {
    let selectFilters = {
      district: [...new Set(r.map((el) => el.district))],
      settlement: [...new Set(r.map((el) => el.settlement))],
      languageEducation: ["русский", "казахский", "смешанные"],
      formOfOwnership: [...new Set(r.map((el) => el.formOfOwnership))],
      responsible: [...new Set(r.map((el) => el.responsible))],
      microdistrict: [...new Set(r.map((el) => el.microdistrict))],
      locality: [...new Set(r.map((el) => el.locality))],
      typeOfSchool: [...new Set(r.map((el) => el.typeOfSchool))],
      status: [...new Set(r.map((el) => el.status))],
      shifts: [...new Set(r.map((el) => el.shifts))],
      nameOfOrganizationOfEducation: [
        ...new Set(r.map((el) => el.nameOfOrganizationOfEducation)),
      ],
    };
    selectFilters.microdistrict = selectFilters.microdistrict.filter(
      (el) => el
    );
    Object.keys(selectFilters).forEach((key) => {
      selectFilters[key] = selectFilters[key].sort().map((el) => {
        return {
          label: el,
          value: el,
        };
      });
    });
    this.setState({ selectFilters });
  };

  fetchSchools = () => {
    request(`/school/list`)
      .then((r) => {
        let payload = CalculateSchool(r);
        this.filterSelectOptions(r);
        this.setState({
          constantData: r,
          filteredData: payload,
          loading: false,
        });
      })
      .catch((err) => {});
  };

  handleFilter = (filter) => {
    this.setState(
      {
        filters: { ...this.state.filters, ...filter },
        loading: true,
      },
      () => {
        this.applyFilters();
      }
    );
  };

  shiftSwitchChanger = () => {
    this.setState({
      shiftSwitch: !this.state.shiftSwitch,
    });
  };

  langTypeChanger = () => {
    this.setState({
      langType: !this.state.langType,
    });
  };

  handleClearFilter = (type) => {
    let { filters } = this.state;
    delete filters[type];
    this.setState(
      {
        filters,
        loading: true,
      },
      () => {
        this.applyFilters();
      }
    );
  };

  applyFilters = () => {
    let { filters, constantData } = this.state;
    let arr = [];
    constantData.forEach((element) => {
      let count = 0;
      Object.keys(filters).forEach((key) => {
        if (filters[key] === element[key]) count++;
      });
      if (count === Object.keys(filters).length) arr.push(element);
    });
    const filteredData = CalculateSchool(arr);
    this.filterSelectOptions(arr);
    setTimeout(() => {
      this.setState({
        filteredData,
        loading: false,
      });
    }, 1000);
  };

  dataSetModifier = (payload) => {
    let mapCoords = JSON.stringify(payload);
    mapCoords = JSON.parse(mapCoords);
    mapCoords.forEach((element) => {
      element.x = element.coordinatesX;
      element.y = element.coordinatesY;
    });
    let pieStat = {
      surplus: 0,
      shortage: 0,
      normal: 0,
    };
    payload.forEach((item, index) => {
      let coof = 1.5;
      if (item.schoolHasTwoShifts) payload[index].shifts = 2;
      else payload[index].shifts = 1;
      if (item.year > 1991) coof = 2;
      let sum = coof * item.place;
      let x = sum - item.actualOccupancy;
      if (x < 0) {
        payload[index].status = "Дефицит";
        pieStat.shortage++;
        mapCoords[index].color = "#fd625e";
        payload[index].statusValue = x;
        payload[index].coof = sum;
      } else {
        let percent = (item.actualOccupancy / item.place) * coof * 100;
        if (percent > 75) {
          payload[index].coof = sum;
          pieStat.normal++;
          payload[index].status = "Норма";
          mapCoords[index].color = "#fbc02d";
          payload[index].statusValue = "-";
        } else {
          payload[index].coof = sum;
          payload[index].status = "Профицит";
          payload[index].statusValue = "-";
          pieStat.surplus++;
          mapCoords[index].color = "#01b8aa";
        }
      }
    });
    return payload;
  };
  handlMapSelect(obj) {
    let arrObj = [obj];
    this.setState({
      visible: true,
      records: arrObj,
    });
  }
  handleMultipleMap = (obj) => {
    this.setState({
      visible: true,
      records: obj,
    });
  };

  handleMultipleSelect = (obj) => {
    let { filteredData } = this.state;

    obj = this.dataSetModifier(obj);

    filteredData.dataTable.dataset = obj;
    this.setState({ filteredData });
  };

  showModal = (key, value, bool) => {
    if (value.includes("одной")) value = false;
    else if (value.includes("двумя")) value = true;
    let records = [];
    const { constantData, filters } = this.state;
    let arr = [];
    constantData.forEach((element) => {
      let count = 0;
      Object.keys(filters).forEach((key) => {
        if (filters[key] === element[key]) count++;
      });
      if (count === Object.keys(filters).length) arr.push(element);
    });
    if (!bool) {
      arr.forEach((el) => {
        if (el[key] === value) records.push(el);
      });
    } else {
      records = arr;
    }
    this.setState({
      visible: true,
      records,
    });
  };

  showFilters = () => {
    // const reverse = !this.state.filtersVisible;
    this.setState((prev) => ({
      filtersVisible: !prev.filtersVisible,
    }));
    // {
    //   filtersVisible: reverse,
    // }
  };
  handleLangPieData(value) {
    let {
      t,
      i18n: { language },
    } = this.props;
    return value?.map((data) => {
      if (language === "ru") return data;
      else if (language === "kz") {
        let word = "";
        switch (data.category) {
          case "Профицит":
            word = t("education.pupilsBySchool.surplus");
            break;
          case "Норма":
            word = t("education.pupilsBySchool.norm");
            break;
          case "Дефицит":
            word = t("education.pupilsBySchool.deficit");
            break;
          default:
            break;
        }

        return {
          ...data,
          category: word,
        };
      }
    });
  }
  handleLangPieLang(value) {
    let {
      t,
      i18n: { language },
    } = this.props;

    return value?.map((data) => {
      if (language === "ru") return data;
      else if (language === "kz") {
        let word = "";
        switch (data.category) {
          case "Смешанный":
            word = t("education.pupilsBySchool.surplus");
            break;
          case "Казахский":
            word = t("education.pupilsBySchool.norm");
            break;
          case "Русский":
            word = t("education.pupilsBySchool.deficit");
            break;
          default:
            break;
        }

        return {
          ...data,
          category: word,
        };
      }
    });
  }
  handleLangTypeOfSchool(value) {
    let {
      t,
      i18n: { language },
    } = this.props;
    return value?.map((data) => {
      if (language === "ru") return data;
      else if (language === "kz") {
        let word = "";
        switch (data.category) {
          case "Общеобразовательные школы":
            word = t("education.pupilsBySchool.secondarySchools");
            break;
          case "Специализированные школы для одаренных детей":
            word = t(
              "education.pupilsBySchool.specializedSchoolsForGiftedChildren"
            );
            break;
          case "Лицеи":
            word = t("education.pupilsBySchool.lyceum");
            break;
          case "Гимназии":
            word = t("education.pupilsBySchool.gymnasiums");
            break;
          default:
            break;
        }

        return {
          ...data,
          category: word,
        };
      }
    });
  }
  handleLangPieSchool(value) {
    let {
      t,
      i18n: { language },
    } = this.props;
    return value?.map((data) => {
      if (language === "ru") return data;
      else if (language === "kz") {
        let word = "";
        switch (data.category) {
          case "Школы с одной сменой":
            word = t("education.pupilsBySchool.schoolsWithOneShift");
            break;
          case "Школы с двумя сменами":
            word = t("education.pupilsBySchool.schoolsWithTwoShifts");
            break;
          default:
            break;
        }

        return {
          ...data,
          category: word,
        };
      }
    });
  }
  handleLangPieShifts(value) {
    let {
      t,
      i18n: { language },
    } = this.props;
    return value?.map((data) => {
      if (language === "ru") return data;
      else if (language === "kz") {
        let word = "";
        switch (data.category) {
          case "Первая смена":
            word = t("education.pupilsBySchool.firstShift");
            break;
          case "Вторая смена":
            word = t("education.pupilsBySchool.secondShift");
            break;
          default:
            break;
        }

        return {
          ...data,
          category: word,
        };
      }
    });
  }
  handleLangMappedDataTableHeader(value) {
    let {
      t,
      i18n: { language },
    } = this.props;
    return value?.map((category) => {
      if (language === "ru") return category;
      else if (language === "kz") {
        let word = "";
        switch (category.key) {
          case "nameOfOrganizationOfEducation":
            word = t("education.pupilsBySchool.name");
            break;
          case "district":
            word = t("education.pupilsBySchool.area");
            break;
          case "locality":
            word = t("education.pupilsBySchool.terrain");
            break;
          case "settlement":
            word = t("education.pupilsBySchool.locality");
            break;
          case "address":
            word = t("education.pupilsBySchool.address");
            break;
          case "languageEducation":
            word = t("education.pupilsBySchool.languageOfInstruction");
            break;
          case "typeOfSchool":
            word = t("education.pupilsBySchool.view");
            break;
          case "formOfOwnership":
            word = t("education.pupilsBySchool.typeOfOwnership");
            break;
          case "year":
            word = t("education.pupilsBySchool.yearBuilt");
            break;
          case "place":
            word = t("education.pupilsBySchool.projectCapacity");
            break;
          case "coof":
            word = t("education.pupilsBySchool.projectCapacityAll");
            break;
          case "actualOccupancy":
            word = t("education.pupilsBySchool.actualFullness");
            break;
          case "status":
            word = t("education.pupilsBySchool.fullness");
            break;
          case "statusValue":
            word = t("education.pupilsBySchool.magnitudeOfTheDeficit");
            break;
          default:
            break;
        }

        return {
          ...category,
          label: word,
        };
      }
    });
  }
  render() {
    const {
      filteredData,
      loading,
      selectFilters,
      visible,
      records,
    } = this.state;
    let { t, isMobile } = this.props;
    const tableHeadersMap = [
      {
        title: t("education.pupilsBySchool.name"),
        dataIndex: "nameOfOrganizationOfEducation",
      },
      {
        title: t("education.pupilsBySchool.area"),
        dataIndex: "district",
      },
      {
        title: t("education.pupilsBySchool.terrain"),
        dataIndex: "locality",
      },
      {
        title: t("education.pupilsBySchool.locality"),
        dataIndex: "settlement",
      },
      {
        title: t("education.pupilsBySchool.address"),
        dataIndex: "address",
      },
      {
        title: t("education.pupilsBySchool.languageOfInstruction"),
        dataIndex: "languageEducation",
      },
      {
        title: t("education.pupilsBySchool.view"),
        dataIndex: "typeOfSchool",
      },
      {
        title: t("education.pupilsBySchool.typeOfOwnership"),
        dataIndex: "formOfOwnership",
      },
      {
        title: t("education.pupilsBySchool.yearBuilt"),
        dataIndex: "year",
      },
      {
        title: t("education.pupilsBySchool.projectCapacity"),
        dataIndex: "place",
      },
      {
        title: t("education.pupilsBySchool.projectCapacityAll"),
        dataIndex: "coof",
      },
      {
        title: t("education.pupilsBySchool.actualFullness"),
        dataIndex: "actualOccupancy",
      },
      {
        title: t("education.pupilsBySchool.fullness"),
        dataIndex: "status",
      },
      {
        title: t("education.pupilsBySchool.magnitudeOfTheDeficit"),
        dataIndex: "statusValue",
      },
    ];

    let schoolItems = [
      {
        title: t("education.pupilsBySchool.numberOfSchools"),
        value: formatNumber(filteredData.stat.totalSchool) || 0,
      },
      {
        title: t("education.pupilsBySchool.projectCapacity"),
        value: formatNumber(filteredData.stat.projectCapacity),
      },
      {
        title: t("education.pupilsBySchool.generalShortageOfPlaces"),
        value: formatNumber(filteredData.stat.shortageDisplay) || 0,
      },
      {
        title: t("education.pupilsBySchool.totalArea"),
        value: formatNumber(filteredData.stat.totalArea),
      },
    ];

    let mappedPieData = this.handleLangPieData(filteredData.pieData);
    let mappedPieLang = this.handleLangPieLang(filteredData.pieLang);
    let mappedTypeOfSchool = this.handleLangTypeOfSchool(
      filteredData.typeOfSchool
    );
    let mappedPieSchool = this.handleLangPieSchool(filteredData.pieSchool);
    let mappedPieShifts = this.handleLangPieShifts(filteredData.pieShifts);
    let mappedDataTableHeader =
      filteredData.dataTable !== undefined
        ? this.handleLangMappedDataTableHeader(filteredData.dataTable.header)
        : [];

    return (
      <Fragment>
        {/* <Icon
          type="filter"
          title="Фильтры"
          className="filterButton"
          
        /> */}
        <Row>
          <Button
            type="primary"
            onClick={this.showFilters}
            icon={<SaveOutlined />}
          >
            {t("education.pupilsBySchool.openFilter")}
          </Button>
        </Row>
        <Modal
          title="Фильтры"
          style={{ top: 20 }}
          visible={this.state.filtersVisible}
          onCancel={this.showFilters}
          footer={false}
        >
          <Row gutter={16}>
            <Col span={isMobile ? 24 : 6}>
              <FilterSelect
                options={selectFilters.district || []}
                filterField="district"
                handleChange={this.handleFilter}
                handleClear={this.handleClearFilter}
                placeholder="Район"
                allowClear
              />
            </Col>
            <Col span={isMobile ? 24 : 6}>
              <FilterSelect
                options={selectFilters.settlement || []}
                filterField="settlement"
                handleChange={this.handleFilter}
                handleClear={this.handleClearFilter}
                placeholder="Населённый пункт"
                allowClear
              />
            </Col>
            <Col span={isMobile ? 24 : 6}>
              <FilterSelect
                options={selectFilters.languageEducation || []}
                filterField="languageEducation"
                handleChange={this.handleFilter}
                handleClear={this.handleClearFilter}
                placeholder="Язык обучения"
                allowClear
              />
            </Col>
            <Col span={isMobile ? 24 : 6}>
              <FilterSelect
                options={selectFilters.formOfOwnership || []}
                filterField="formOfOwnership"
                handleChange={this.handleFilter}
                handleClear={this.handleClearFilter}
                placeholder="Форма собственности"
                allowClear
              />
            </Col>
            <Col span={isMobile ? 24 : 6}>
              <FilterSelect
                options={selectFilters.responsible || []}
                filterField="responsible"
                handleChange={this.handleFilter}
                handleClear={this.handleClearFilter}
                placeholder="Ведомственная принадлежность"
                allowClear
              />
            </Col>
            <Col span={isMobile ? 24 : 6}>
              <FilterSelect
                options={selectFilters.locality || []}
                filterField="locality"
                handleChange={this.handleFilter}
                handleClear={this.handleClearFilter}
                placeholder="Местность "
                allowClear
              />
            </Col>
            <Col span={isMobile ? 24 : 6}>
              <FilterSelect
                options={selectFilters.microdistrict || []}
                filterField="microdistrict"
                handleChange={this.handleFilter}
                handleClear={this.handleClearFilter}
                placeholder="Микрорайон"
                allowClear
              />
            </Col>
            <Col span={isMobile ? 24 : 6}>
              <FilterSelect
                options={selectFilters.shifts || []}
                filterField="shifts"
                handleChange={this.handleFilter}
                handleClear={this.handleClearFilter}
                placeholder="Количество смен обучения"
                allowClear
              />
            </Col>
            <Col span={isMobile ? 24 : 6}>
              <FilterSelect
                options={selectFilters.typeOfSchool || []}
                filterField="typeOfSchool"
                handleChange={this.handleFilter}
                handleClear={this.handleClearFilter}
                placeholder="Вид"
                allowClear
              />
            </Col>
            <Col span={isMobile ? 24 : 6}>
              <FilterSelect
                options={selectFilters.status || []}
                filterField="status"
                handleChange={this.handleFilter}
                handleClear={this.handleClearFilter}
                placeholder="Наполненность"
                allowClear
              />
            </Col>
            <Col span={isMobile ? 24 : 6}>
              <FilterSelect
                options={selectFilters.nameOfOrganizationOfEducation || []}
                filterField="nameOfOrganizationOfEducation"
                handleChange={this.handleFilter}
                handleClear={this.handleClearFilter}
                placeholder="Наименование школы"
                allowClear
              />
            </Col>
          </Row>
        </Modal>
        {/* <Modal visible={this.filtersVisible}>
          
        </Modal>*/}
        <Container>
          <Row>
            <Col span={isMobile ? 24 : 8}>
              {schoolItems.map((item) => (
                <DdoItem>
                  <div>{item.title}</div>
                  <OrangeBox>{item.value}</OrangeBox>
                </DdoItem>
              ))}
            </Col>
            <Col span={isMobile ? 24 : 16}>
              {loading && <LoadingCss />}
              <MapWrap
                height="46vh"
                zoom={9}
                objects={filteredData.mapCoords}
                handleSelect={(obj) => this.handlMapSelect(obj)}
                handleMultipleSelect={(obj) => this.handleMultipleMap(obj)}
              />
            </Col>
          </Row>
        </Container>

        <Row>
          <Col span={24}>
            <LoadingSpinner
              loading={loading}
              className="flex-full flex layout-centered"
            >
              <h3 align="center">{t("education.pupilsBySchool.fullness")}</h3>
              <HorizontalBarChart
                responsive
                id="zapolnennost"
                height="46vh"
                advancedScroll
                advancedScrollStart={0.99}
                advancedScrollEnd={1}
                twoSeries
                noCellSize
                name={t("education.pupilsBySchool.projectCapacity")}
                name1={t("education.pupilsBySchool.actualFullness")}
                showLegend
                colorsArr={["#00b3e6", "#828282"]}
                data={filteredData.uponcy || []}
              />
            </LoadingSpinner>
          </Col>
        </Row>

        <Container>
          <Row>
            <Col span={isMobile ? 24 : 8}>
              <LoadingSpinner
                loading={loading}
                className="flex-full flex layout-centered"
              >
                <h3 align="center">
                  {t("education.pupilsBySchool.schoolPopulationStructure")}
                </h3>
                <PieChartWrap
                  responsive
                  key={mappedPieData}
                  height="43vh"
                  hideLabels
                  colorsArr={["#01b8aa", "#fbc02d", "#fd625e"]}
                  showLegend
                  handleClick={(category) => {
                    this.showModal("status", category);
                  }}
                  data={mappedPieData}
                  id="schoolPie1"
                  disabledLegend
                />
              </LoadingSpinner>
            </Col>
            <Col span={isMobile ? 24 : 8} style={{ textAlign: "center" }}>
              <h3 style={{ display: "inline-block" }}>
                {t("education.pupilsBySchool.schoolType")}
              </h3>
              <Switch
                defaultChecked
                style={{ margin: "0 auto", textAlign: "center" }}
                onChange={this.langTypeChanger}
              />
              <h3 style={{ display: "inline-block" }}>Языки обучения</h3>
              {this.state.langType ? (
                <LoadingSpinner
                  loading={loading}
                  className="flex-full flex layout-centered"
                >
                  <PieChartWrap
                    key={mappedPieLang || []}
                    height="45vh"
                    hideLabels
                    handleClick={(category) => {
                      this.showModal("languageEducation", category);
                    }}
                    showLegend
                    data={mappedPieLang || []}
                    id="schoolLang"
                    colorsArr={["#01b8aa", "#fbc02d", "#00b3e6"]}
                    disabledLegend
                  />
                </LoadingSpinner>
              ) : (
                <LoadingSpinner
                  loading={loading}
                  className="flex-full flex layout-centered"
                >
                  <PieChartWrap
                    responsive
                    key={mappedTypeOfSchool || []}
                    height="45vh"
                    hideLabels
                    colorsArr={["#fbc02d", "#00b3e6", "#fd625e", "#828282"]}
                    showLegend
                    data={mappedTypeOfSchool || []}
                    id="schoolLang"
                    disabledLegend
                  />
                </LoadingSpinner>
              )}
            </Col>

            <Col span={isMobile ? 24 : 8} style={{ textAlign: "center" }}>
              <h3 style={{ display: "inline-block", marginLeft: "9px" }}>
                {t("education.pupilsBySchool.structureOfSchoolsByShifts")}
              </h3>
              <Switch
                defaultChecked
                style={{ margin: "0 auto", textAlign: "center" }}
                onChange={this.shiftSwitchChanger}
              />
              <h3 style={{ display: "inline-block" }}>
                {t("education.pupilsBySchool.studentsByShifts")}
              </h3>
              {this.state.shiftSwitch ? (
                <LoadingSpinner
                  loading={loading}
                  className="flex-full flex layout-centered"
                >
                  <PieChartWrap
                    responsive
                    key={mappedPieSchool || []}
                    height="45vh"
                    hideLabels={true}
                    showLegend={true}
                    colorsArr={["#01b8aa", "#fbc02d", "#00b3e6"]}
                    handleClick={(category) => {
                      this.showModal("schoolHasTwoShifts", category);
                    }}
                    data={mappedPieSchool || []}
                    id="schoolMultiShifts"
                    disabledLegend={true}
                  />
                </LoadingSpinner>
              ) : (
                <LoadingSpinner
                  loading={loading}
                  className="flex-full flex layout-centered"
                >
                  <PieChartWrap
                    responsive
                    key={mappedPieShifts || []}
                    height="45vh"
                    hideLabels={true}
                    showLegend={true}
                    colorsArr={["#01b8aa", "#fbc02d", "#00b3e6"]}
                    handleClick={(category) => {
                      this.showModal("countFirstShift", category, true);
                    }}
                    data={mappedPieShifts || []}
                    id="schoolShifts"
                    disabledLegend={true}
                  />
                </LoadingSpinner>
              )}
            </Col>
          </Row>
        </Container>
        <LoadingSpinner
          loading={loading}
          className="flex-full flex layout-centered"
        >
          <h3 align="center"> {t("education.pupilsBySchool.pivotTable")}</h3>
          {filteredData.dataTable && (
            <div style={{ marginTop: "10px" }}>
              <TableComponent
                height="38vh"
                headers={mappedDataTableHeader || []}
                objects={filteredData.dataTable.dataset}
              />
              <small>* Проектная мощность (коэффициент)</small>
            </div>
          )}
        </LoadingSpinner>
        <Modal
          className="ddo_modal"
          title="Школы"
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width={3000}
        >
          {records.length > 0 && (
            <Table
              pagination={false}
              columns={tableHeadersMap || []}
              dataSource={records}
              scroll={{ x: 200, y: 600 }}
            />
          )}
        </Modal>
      </Fragment>
    );
  }
}
export default FirstPageSchool;
