import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import moment from "moment";
import _ from "lodash";

import {
  SET_LOADING_CRIME,
  SET_DATE_CRIME,
  SAVE_CRIME,
  SET_CRIME_FILTER,
  CLEAR_CRIME_FILTER,
  SELECT_CRIME,
  SET_FEATURED_CATEGORY,
  SET_LOADING_APPEALS,
  SET_DATE_APPEALS,
  SAVE_APPEALS,
  SET_APPEALS_FILTER,
  CLEAR_APPEALS_FILTER,
  SELECT_APPEAL,
  SET_SELECTED_CATEGORY,
  SELECT_MULTIPLE_APPEALS,
} from "./actions";

const crime = handleActions(
  {
    [SET_LOADING_CRIME]: {
      next: (state, { payload }) => ({ ...state, loading: payload }),
    },
    [SET_DATE_CRIME]: {
      next: (state, { payload }) => ({
        ...state,
        [payload.dateType]: payload.value,
      }),
    },
    [SAVE_CRIME]: {
      next: (state, { payload }) => ({ ...state, crime: payload }),
    },
    [SET_CRIME_FILTER]: {
      next: (state, { payload }) => ({
        ...state,
        filters: { ...state.filters, ...payload },
      }),
    },
    [SET_SELECTED_CATEGORY]: {
      next: (state, { payload }) => ({
        ...state,
        selectedRegion: payload,
      }),
    },
    [CLEAR_CRIME_FILTER]: {
      next: (state, { payload }) => ({
        ...state,
        filters: _.omit(state.filters, [payload]),
      }),
    },
    [SELECT_CRIME]: {
      next: (state, { payload }) => ({ ...state, selected: payload }),
    },
    [SET_FEATURED_CATEGORY]: {
      next: (state, { payload }) => ({
        ...state,
        featuredCategories: [
          ...state.featuredCategories.slice(0, payload.index),
          payload.category,
          ...state.featuredCategories.slice(payload.index + 1),
        ],
      }),
    },
  },
  {
    loading: false,
    startDate: moment().subtract(2, "month").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    crime: [],
    selectedRegion: "",
    filters: {},
    selected: null,
    featuredCategories: ["Мошенничество", "Хулиганство", "Кража", "Убийство"],
  }
);

const appeals = handleActions(
  {
    [SET_LOADING_APPEALS]: {
      next: (state, { payload }) => ({ ...state, loading: payload }),
    },
    [SET_DATE_APPEALS]: {
      next: (state, { payload }) => ({
        ...state,
        [payload.dateType]: payload.value,
      }),
    },
    [SAVE_APPEALS]: {
      next: (state, { payload }) => ({ ...state, appeals: payload }),
    },
    [SET_APPEALS_FILTER]: {
      next: (state, { payload }) => ({
        ...state,
        filters: { ...state.filters, ...payload },
      }),
    },
    [CLEAR_APPEALS_FILTER]: {
      next: (state, { payload }) => ({
        ...state,
        filters: _.omit(state.filters, [payload]),
      }),
    },
    [SELECT_APPEAL]: {
      next: (state, { payload }) => ({ ...state, selected: payload }),
    },
  },
  {
    loading: false,
    startDate: moment()
      .set({ hour: 0, minute: 0, second: 0 })
      .subtract(7, "d")
      .format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment()
      .set({ hour: 23, minute: 59, second: 59 })
      .format("YYYY-MM-DD HH:mm:ss"),
    appeals: [],
    filters: {},
    selected: null,
  }
);

export default combineReducers({
  crime,
  appeals,
});
