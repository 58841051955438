import React, { useCallback, useEffect, useState } from "react";
import "./MapComponent.scss";
import moment from "moment";
import { transformTime } from "utils/transformTime";
import { generateHex } from "utils/generateHex";
import { AkmolaCoords } from "../../../../constants";
import { getViaIndexed } from "utils/getViaIndexed";

const getBallon = (data) => {
  return `
    <dl>
    <div class=wrap>
      ${
        data.transporters.length
          ? `<dt>Перевозчик:</dt>
        <dd>${data.transporters
          .map((item) => `<div key=${item}><span>${item}</span></div>`)
          .join(" ")}</dd>`
          : null
      }
    </div>
    <div class=wrap>
      ${
        data.destinationLocality
          ? `<dt>Откуда:</dt>
        <dd>${data.destinationLocality}</dd>`
          : null
      }
    </div>
    <div class=wrap>
      ${
        data.arrivalLocality
          ? `<dt>Куда:</dt>
        <dd>${data.arrivalLocality}</dd>`
          : null
      }
    </div>
    <div class=wrap>
      ${
        data.departureTimeFromStart
          ? `<dt>Время отправления:</dt>
        <dd>${moment(data.departureTimeFromStart).format("HH:mm")}</dd>`
          : null
      }
    </div>
    <div class=wrap>
      ${
        data.departureTimeFromEnd
          ? `<dt>Время прибытия:</dt>
        <dd>${moment(data.departureTimeFromEnd).format("HH:mm")}</dd>`
          : null
      }
    </div>
    <div class=wrap>
      ${
        data.movementTime
          ? `<dt>Время движения:</dt>
        <dd>${transformTime(data.movementTime)}</dd>`
          : null
      }
    </div>
    <div class=wrap>
      ${
        data.distance
          ? `<dt>Длина пути:</dt>
        <dd>${data.distance} км</dd>`
          : null
      }
    </div>
    </dl>
  `;
};

const generateBalloon = (balloonClass, closeClass, data) => {
  const balloonLayout = window.ymaps.templateLayoutFactory.createClass(
    `<div class='balloon ${balloonClass}'>
      <div class='box'>
        <span class='close ${closeClass}'>&times;</span>
      </div>
      ${getBallon(data)}
    </div>`,
    {
      build: function () {
        balloonLayout.superclass.build.call(this);
        document
          .querySelector(`.${closeClass}`)
          .addEventListener("click", this.onCloseClick.bind(this));
      },

      onCloseClick: function (e) {
        e.preventDefault();
        this.events.fire("userclose");
      },
    }
  );
  return balloonLayout;
};

const applyAkmolaPolygon = () => {
  return new window.ymaps.GeoObject(
    {
      geometry: {
        type: "LineString",
        coordinates: AkmolaCoords,
      },
    },
    {
      strokeColor: "#000000",
      strokeWidth: 3,
    }
  );
};

export const MapComponent = ({ tableData }) => {
  const [map, setMap] = useState(null);

  const initMap = useCallback(() => {
    const arrMultiRoute = tableData.filteredData.map((item, index) => {
      const hex = generateHex();
      return new window.ymaps.multiRouter.MultiRoute(
        {
          referencePoints: item.coordinates,
          params: {
            results: 1,
            viaIndexes: getViaIndexed(item.coordinates.length),
          },
        },
        {
          routeActiveStrokeColor: hex,
          pinActiveIconFillColor: hex,
          balloonLayout: generateBalloon(
            `balloon${index}`,
            `close${index}`,
            item
          ),
          balloonPanelMaxMapArea: 0,
          wayPointStartIconColor: hex,
          wayPointStartIconFillColor: hex,
          wayPointFinishIconColor: hex,
          wayPointFinishIconFillColor: hex,
          viaPointIconFillColor: hex,
          viaPointActiveIconFillColor: hex,
        }
      );
    });
    if (map) {
      map.geoObjects.splice(1, map.geoObjects.getLength());

      arrMultiRoute.forEach((item) => {
        map.geoObjects.add(item);
      });
    } else {
      const mapNode = document.getElementById("YMapsId");

      if (mapNode && mapNode.children.length) return;

      const ymaps = new window.ymaps.Map(
        "YMapsId",
        {
          center: [51.960768, 69.914122],
          zoom: 7,
        },
        { buttonMaxWidth: 300 }
      );
      if (ymaps) {
        ymaps.geoObjects.add(applyAkmolaPolygon(ymaps));
        arrMultiRoute.forEach((item) => {
          ymaps.geoObjects.add(item);
        });
      }

      setMap(ymaps);
    }
  }, [map, tableData.filteredData]);

  useEffect(() => {
    window.ymaps.ready(initMap);
  }, [initMap]);

  return (
    <div>
      <div id="YMapsId" style={{ height: 600 }} />
    </div>
  );
};
