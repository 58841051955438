import React from "react";
import Table from "../../components/Table";

const TableView = ({ indicator }) => {
  const uniqYear = Array.from(
    new Set(
      [...indicator.fact.data, ...indicator.plan.data].map((e) => e.label)
    )
  );
  const data = uniqYear.map((label) => {
    const plan = indicator.plan.data.find((e) => e.label === label);
    const fact = indicator.fact.data.find((e) => e.label === label);
    return {
      year: label + "",
      plan: plan ? plan.value : null,
      fact: fact ? fact.value : null,
    };
  });

  return (
    <div className="table_item">
      <h4 align="center">{indicator.title}</h4>
      <Table
        headers={[
          { label: "Год", key: "year" },
          { label: "План", key: "plan" },
          { label: "Факт", key: "fact" },
        ]}
        objects={data}
      />
    </div>
  );
};

export default TableView;
