import moment from "moment";
import _ from "lodash";

export function isJson(string) {
  try {
    JSON.parse(string);
  } catch (err) {
    return false;
  }
  return true;
}

export const formatPercent = (value) => {
  const num = Number(value);
  return num.toFixed(2) + "%";
};

export const formatNumber = (
  value,
  maximumFractionDigits = 2,
  placeholder = undefined
) => {
  const num = Number(value);
  if (num === 0 && typeof placeholder !== "undefined") {
    return placeholder;
  }
  return Number(value).toLocaleString("ru-KZ", { maximumFractionDigits });
};

export const formatLongNumber = (value, digitsAfterPoint = 0) => {
  value = parseFloat(value);

  if (!value) {
    return value.toFixed(digitsAfterPoint);
  }

  const formatLong = (fromIncl, toExcl, suffix) => {
    if (value >= fromIncl && value < toExcl) {
      const val = (value / fromIncl)
        .toFixed(digitsAfterPoint)
        .replace(".", ",");
      return val + " " + suffix;
    }
  };

  return (
    formatLong(1, 1e3, "") ||
    formatLong(1e3, 1e6, "тыс") ||
    formatLong(1e6, 1e9, "млн") ||
    formatLong(1e9, 1e12, "млрд") ||
    formatLong(1e12, 1e15, "трлн") ||
    formatLong(1e15, Number.MAX_VALUE, "")
  );
};

export const numberWithCommas = (x, delimitter = ",") => {
  return (Math.round(x * 10) / 10)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, delimitter);
};

export const range = (min, max, step = 1) => {
  const arr = [];
  const totalSteps = Math.floor((max - min) / step);
  for (let ii = 0; ii <= totalSteps; ii++) {
    arr.push(ii * step + min);
  }
  return arr;
};

export const formatDate = (date, format = "DD.MM.YYYY") => {
  return moment(date).format(format);
};

export const minutesOfDay = function (t) {
  return t.m + t.h * 60;
};

export const secondsOfDay = function (t) {
  return t.s + t.m * 60 + t.h * 3600;
};

export const generateTimePeriods = () => {
  const x = 30;
  const times = [];
  let tt = 0;
  // const ap = ["AM", "PM"];

  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60);
    const mm = tt % 60;
    times[i] = ("0" + (hh % 24)).slice(-2) + ":" + ("0" + mm).slice(-2) + ":00";
    tt = tt + x;
  }
  return times;
};

export const currentDate = {
  currentYear: moment().format("YYYY"),
  currentMonth:
    moment().format("M") < 10 ? 0 + moment().format("M") : moment().format("M"),
  currentDay:
    moment().format("D") < 10 ? 0 + moment().format("D") : moment().format("D"),
};

export function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const generateBarData = (resultSet) => {
  if (!resultSet) return;
  const query = resultSet.query();
  const data = resultSet.rawData();
  const seriesNames = resultSet.seriesNames();
  const { measures, dimensions, timeDimensions } = query;

  if (
    measures.length &&
    dimensions.length === 1 &&
    timeDimensions.length === 0
  ) {
    return {
      data,
      category: dimensions[0],
      values: seriesNames,
    };
  }
  if (
    measures.length &&
    dimensions.length === 0 &&
    timeDimensions.length === 1
  ) {
    return {
      data,
      category: timeDimensions[0].dimension,
      values: seriesNames,
    };
  }
  if (measures.length === 1 && dimensions.length === 2) {
    const categories = _.uniq(data.map((d) => d[dimensions[1]]));
    const grouped = _.groupBy(data, dimensions[0]);
    const series = Object.keys(grouped);
    const seriesNames = series.map((s) => {
      const divide = s ? s.split(":") : [""];
      return {
        title: divide.length === 2 ? divide[1].trim() : divide[0],
        key: s,
      };
    });

    const normalized = categories.map((c, i) => {
      let obj = { [dimensions[1]]: c };
      series.forEach((s) => {
        const item = grouped[s].find((el) => el[dimensions[1]] === c);
        obj = {
          ...obj,

          [s]: item ? item[measures[0]] : 0,
        };
      });
      return obj;
    });

    return {
      data: normalized,
      category: dimensions[1],
      values: seriesNames,
    };
  }

  if (
    measures.length === 1 &&
    dimensions.length === 1 &&
    timeDimensions.length === 1
  ) {
    const category = timeDimensions[0].dimension;
    const categories = _.uniq(data.map((d) => d[category]));
    const grouped = _.groupBy(data, dimensions[0]);
    const series = Object.keys(grouped);
    const seriesNames = series.map((s) => {
      const divide = s ? s.split(":") : [""];
      return {
        title: divide.length === 2 ? divide[1].trim() : divide[0],
        key: s,
      };
    });

    const normalized = categories.map((c, i) => {
      let obj = { [category]: c };
      series.forEach((s) => {
        const item = grouped[s].find((el) => el[category] === c);
        obj = {
          ...obj,

          [s]: item ? item[measures[0]] : 0,
        };
      });
      return obj;
    });

    return {
      data: normalized,
      category,
      values: seriesNames,
    };
  }
};

export function propChanged(old, current, name) {
  if (old === current) {
    return false;
  }
  if (!name) {
    return JSON.stringify(old) !== JSON.stringify(current);
  }
  if (old[name] === current[name]) {
    return false;
  }
  return JSON.stringify(old[name]) !== JSON.stringify(current[name]);
}
