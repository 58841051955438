import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { CHART_LOCALE } from "../common/Constants";
import * as PropTypes from "prop-types";
import CustomPropTypes from "../../utils/propTypes";

export default class VerticalBarChart extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    height: CustomPropTypes.Height,
    formatNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    colorsArr: PropTypes.arrayOf(PropTypes.string),
    twoSeries: PropTypes.bool,
    name: PropTypes.string,
    name1: PropTypes.string,
    sortInc: PropTypes.bool,
    yMin: PropTypes.number,
    yMax: PropTypes.number,
    showLegend: PropTypes.bool,
    shortNumbers: PropTypes.bool,
    color: PropTypes.string,
    xAxisLabel: PropTypes.string,
    yAxisLabel: PropTypes.string,
    legendOptions: PropTypes.string,
    showColumnTooltip: PropTypes.bool,
    fontSize: PropTypes.number,
    series: PropTypes.number,
    noCellSize: PropTypes.bool,
    colorBars: PropTypes.bool,
    rotateLabels: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    labelWidth: PropTypes.any,
    threeDim: PropTypes.bool,
    multiSort: PropTypes.bool,
    hideValueLabels: PropTypes.bool,
    wrapLabel: PropTypes.bool,
    scroll: PropTypes.bool,
    advancedScroll: PropTypes.bool,
    advancedScrollItems: PropTypes.number,
    handleClick: PropTypes.func,
    advancedScrollStart: PropTypes.number,
    advancedScrollEnd: PropTypes.number,
    sort: PropTypes.oneOf(["value", "reverse"]),
    legendPosition: PropTypes.string,
    totals: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.number,
        color: PropTypes.string,
      })
    ),
    ignoreAdjustWidth: PropTypes.bool,
    panel: PropTypes.bool,
    units: PropTypes.string,
    region: PropTypes.string,
    responsive: PropTypes.bool,
  };

  componentDidMount() {
    const { labelWidth = 50, cellSize = 40 } = this.props;
    // const W_WIDTH = window.innerWidth;
    const chart = am4core.create(
      this.props.id,
      this.props.threeDim ? am4charts.XYChart3D : am4charts.XYChart
    );
    let data = this.props.data;
    if (data) {
      data.forEach((i) => {
        if (i.category == null) i.category = "Не определён";
      });
    }
    if (this.props.twoSeries && this.props.sortInc && data !== undefined) {
      data.sort(
        (x, y) => Number(y.value1 + y.value) - Number(x.value1 + x.value)
      );
    }
    if (data) {
      switch (this.props.sort) {
        case "value":
          data.sort((x, y) => Number(y.value) - Number(x.value));
          break;

        case "reverse":
          data.sort((x, y) => Number(x.value) - Number(y.value));
          break;
        default:
          break;
      }
    }

    function orderByProperty(prop) {
      var args = Array.prototype.slice.call(arguments, 1);
      return function (a, b) {
        var equality = b[prop] - a[prop];
        if (equality === 0 && arguments.length > 1) {
          return orderByProperty.apply(null, args)(a, b);
        }
        return equality;
      };
    }

    if (this.props.multiSort && data !== undefined) {
      data = data.sort(orderByProperty("value", "value1"));
    }

    chart.language.locale = CHART_LOCALE;
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 1;
    categoryAxis.renderer.grid.template.paddingTop = 20;
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.minGridDistance = 10;

    if (this.props.xAxisLabel) {
      categoryAxis.title.text = this.props.xAxisLabel;
    }

    if (this.props.responsive) {
      chart.responsive.enabled = true;
    }

    const label = categoryAxis.renderer.labels.template;
    label.fontSize = 12;
    if (this.props.rotateLabels) {
      if (typeof this.props.rotateLabels === "number") {
        label.rotation = this.props.rotateLabels;
      } else {
        label.rotation = 300;
        label.dx = -40;
      }
      label.position = "absolute";
    }
    label.events.on("down", (ev) => {
      if (this.props.handleClick) {
        const category = ev.target.dataItem.dataContext.category;
        const object = data.find((e) => e.category === category);
        this.props.handleClick(category, 0, object);
      }
    });
    label.maxWidth = labelWidth;
    if (this.props.wrapLabel) {
      label.wrap = true;
    } else {
      label.truncate = true;
    }
    label.tooltipText = "{category}";
    let { noCellSize } = this.props;
    if (this.props.ignoreAdjustWidth !== true) {
      chart.events.on("datavalidated", function (ev) {
        let chart = ev.target;
        let categoryAxis = chart.yAxes.getIndex(0);
        let adjustWidth =
          chart.data.length * cellSize - categoryAxis.pixelWidth;
        if (noCellSize) adjustWidth = 5;
        let targetWidth = chart.pixelWidth + adjustWidth;
        chart.svgContainer.htmlElement.style.width = targetWidth + "px";
      });
    }
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    if (this.props.yAxisLabel) {
      valueAxis.title.text = this.props.yAxisLabel;
    }

    const seriesType = this.props.threeDim
      ? am4charts.ColumnSeries3D
      : am4charts.ColumnSeries;

    const series = chart.series.push(new seriesType());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "category";
    series.name = this.props.name;
    series.minBulletDistance = 30;

    const tooltipName = this.props.name ? "name" : "categoryX";
    const tooltipField = this.props.showColumnTooltip ? "tooltip" : "valueY";
    const tooltipUnits = this.props.units ? this.props.units : "";
    const tooltipRegion = this.props.Region ? this.props.Region : "";
    series.columns.template.tooltipText = `{${tooltipName}}: [bold]{${tooltipField}}[/] ${tooltipUnits} ${tooltipRegion}`;

    if (this.props.handleClick) {
      series.columns.template.events.on("down", (ev) => {
        const category = ev.target.dataItem.dataContext.category;
        const object = data.find((e) => e.category === category);
        this.props.handleClick(category, 0, object);
      });
    }

    const seriesCount = this.props.series || (this.props.twoSeries ? 2 : 1);

    for (let i = 1; i < seriesCount; ++i) {
      let series1 = chart.series.push(new seriesType());
      series1.dataFields.valueY = "value" + i;
      series1.name = this.props["name" + i];
      series1.dataFields.categoryX = "category";

      let columnTemplate1 = series1.columns.template;
      columnTemplate1.tooltipText = `{name}: [bold]{valueY}[/]`;
      series1.tooltip.label.maxWidth = 200;
      series1.tooltip.label.wrap = true;

      if (!this.props.hideValueLabels) {
        let labelBullet = series1.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.dy = -7;
        labelBullet.label.fill = am4core.color("#fff");
        labelBullet.fontSize = this.props.fontSize ? this.props.fontSize : 12;
        labelBullet.label.truncate = false;
        labelBullet.label.hideOversized = false;
      }

      if (this.props.colorsArr) {
        series1.fill = am4core.color(this.props.colorsArr[i]);
        series1.stroke = am4core.color(this.props.colorsArr[i]);
      }

      if (this.props.handleClick) {
        series1.columns.template.events.on("down", (ev) => {
          const category = ev.target.dataItem.dataContext.category;
          const object = data.find((e) => e.category === category);
          this.props.handleClick(category, object, i);
        });
      }
    }

    if (seriesCount === 1) {
      series.fill = am4core.color(this.props.color);
      series.stroke = am4core.color(this.props.color);
    } else {
      series.fill = am4core.color(this.props.colorsArr[0]);
      series.stroke = am4core.color(this.props.colorsArr[0]);
    }

    if (!this.props.hideValueLabels) {
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.dy = -7;
      labelBullet.label.fill = am4core.color("#fff");
      labelBullet.fontSize = this.props.fontSize ? this.props.fontSize : 12;
      labelBullet.label.truncate = false;
      labelBullet.label.hideOversized = false;
    }

    if (this.props.scroll) {
      chart.scrollbarX = new am4core.Scrollbar();
    }
    if (this.props.advancedScroll) {
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      chart.scrollbarX.start = this.props.advancedScrollStart
        ? this.props.advancedScrollStart
        : 0;
      chart.scrollbarX.end = this.props.advancedScrollEnd
        ? this.props.advancedScrollEnd
        : 0.3;
      if (this.props.advancedScrollItems) {
        chart.scrollbarX.end =
          this.props.advancedScrollItems / (this.props.data.length || 1);
      }
    }
    if (this.props.totals) {
      this.props.totals.forEach(({ title, value, color }, index) => {
        const key = "total-" + index;
        for (const point of data) {
          point[key] = value;
        }
        const line = new am4charts.StepLineSeries();
        line.name = title;
        line.dataFields.valueY = key;
        line.dataFields.categoryX = "category";
        if (color) {
          line.stroke = am4core.color(color);
          line.strokeWidth = 2;
        }
        line.tooltipText = "{name}:[bold]{valueY}";
        chart.series.push(line);
      });
    }

    if (this.props.showLegend) {
      chart.legend = new am4charts.Legend();
      chart.legend.position = this.props.legendPosition || "top";
    }
    if (this.props.legendOptions) {
      eval(this.props.legendOptions);
    }

    if (this.props.panel) {
      chart.legend.fontSize = 12;
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;
    }

    if (typeof this.props.yMin === "number") {
      valueAxis.min = this.props.yMin;
    }
    if (typeof this.props.yMax === "number") {
      valueAxis.max = this.props.yMax;
    }

    chart.events.on("ready", (ev) => {
      valueAxis.extraMax = 0.1;
    });

    if (this.props.colorBars) {
      series.columns.template.adapter.add("fill", (fill, target) => {
        const index = target.dataItem.index;
        const data = this.props.data[index];
        if (data && data.color) {
          return data.color;
        }
        return chart.colors.getIndex(index);
      });
    }

    if (this.props.formatNumber) {
      const { formatNumber } = this.props;
      const format = typeof formatNumber === "string" ? formatNumber : "#.#a";
      chart.numberFormatter.numberFormat = format;
      chart.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: " тыс" },
        { number: 1e6, suffix: " млн" },
        { number: 1e9, suffix: " млрд" },
        { number: 1e12, suffix: " трлн" },
      ];
    }
    chart.data = data;
    this.chart = chart;
    this.series = series;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  componentDidUpdate(oldProps) {
    if (JSON.stringify(oldProps.data) !== JSON.stringify(this.props.data)) {
      let data = this.props.data;
      let chart = this.chart;
      if (!this.props.colorsArr) {
        this.series.fill = am4core.color(this.props.color);
        this.series.stroke = am4core.color(this.props.color);
      }
      if (this.props.advancedScroll) {
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.parent = chart.bottomAxesContainer;
        chart.scrollbarX.start = this.props.advancedScrollStart
          ? this.props.advancedScrollStart
          : 0;
        if (this.props.advancedScrollItems) {
          chart.scrollbarX.end =
            this.props.advancedScrollItems / (this.props.data.length || 1);
        } else {
          chart.scrollbarX.end = this.props.advancedScrollEnd
            ? this.props.advancedScrollEnd
            : 0.3;
        }
      }
      if (this.props.twoSeries && this.props.sortInc && data !== undefined) {
        data.sort(
          (x, y) => Number(y.value1 + y.value) - Number(x.value1 + x.value)
        );
      }
      switch (this.props.sort) {
        case "value":
          data.sort((x, y) => Number(y.value) - Number(x.value));
          break;

        case "reverse":
          data.sort((x, y) => Number(x.value) - Number(y.value));
          break;
      }
      this.chart.data = this.props.data;
    }
  }

  render() {
    const { height = "40vh", style = {} } = this.props;
    return (
      <div
        className="vertical_chart_wrap"
        style={
          this.props.scrollTrue
            ? { overflowX: "scroll", overflowY: "hidden" }
            : null
        }
      >
        <div id={this.props.id} style={{ height: height, ...style }} />
      </div>
    );
  }
}
